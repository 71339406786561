<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

<div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
  {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>
<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Search</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

      <mat-form-field>
        <mat-label>Extraction Part Type</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="extraneousPartType" name="extraneousPartType"
          (selectionChange)="getMatchPartTxt()">
          <mat-option [value]=""></mat-option>

        <mat-option *ngFor="let extraneousPartType of extraneousPartTypeLst"
          [value]="extraneousPartType.spcl_part_typ_nm">{{extraneousPartType.spcl_part_typ_nm}}</mat-option>
      </mat-select>
    </mat-form-field>

      <mat-form-field>
        <mat-label>Matching Part Text</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="matchingPartText" name="matchingPartText"
          (selectionChange)="getCompCode()">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let matchingPartText of matchingPartTextLst"
            [value]="matchingPartText.mtchg_part_kywrd_txt">{{matchingPartText.mtchg_part_kywrd_txt}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Component Code</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="componentCode" name="componentCode"
          (selectionChange)="getSrcRouting()">
          <mat-option [value]=""></mat-option>
          <span *ngFor="let componentCode of componentCodeLst">
            <span *ngIf="componentCode.cmpnt_cd != ''">
              <mat-option *ngIf="componentCode.cmpnt_cd != null" [value]="componentCode.cmpnt_cd">
                {{componentCode.cmpnt_cd}}
              </mat-option>
            </span>
          </span>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Source Routing</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="sourceRouting" name="sourceRouting"
          (selectionChange)="fetchDestRouting()">
          <mat-option [value]=""></mat-option>
          <span *ngFor="let sourceRouting of sourceRoutingLst">
            <span *ngIf="sourceRouting.src_rtg_cd != ''">
              <mat-option *ngIf="sourceRouting.src_rtg_cd != null" [value]="sourceRouting.src_rtg_cd">
                {{sourceRouting.src_rtg_cd}}</mat-option>
            </span>
          </span>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Dest Routing</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="destRouting" name="destRouting">
        <mat-option [value]=""></mat-option>
        <span *ngFor="let destRouting of destRoutingLst">
          <span *ngIf="destRouting.dest_rtg_cd != ''">
            <mat-option *ngIf="destRouting.dest_rtg_cd != null" [value]="destRouting.dest_rtg_cd">
              {{destRouting.dest_rtg_cd}}</mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>


    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>

  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="openAddDialog()" color=""> Add </button>
  <button mat-raised-button (click)="openEditDialog()">Edit </button>
  <button mat-raised-button (click)="openViewDialog()">View </button>
  <button mat-raised-button (click)="exportFile()" class="ms-auto"> Export To Excel </button>
</div>

  <!-- <mat-card> -->
  <ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
    [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
    [selectionType]="SelectionType.single" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
    (activate)="onActivate($event)" (select)="onSelect($event)" [selectCheck]="singleSelectCheck"
    [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">

  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>

      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
    </ngx-datatable-column>
    <ngx-datatable-column name="Extraneous Part Type" prop="spcl_part_typ_nm"></ngx-datatable-column>
    <ngx-datatable-column name="Extraneous Part Description" prop="spcl_part_typ_desc"></ngx-datatable-column>
    <ngx-datatable-column name="Matching Part Text" prop="mtchg_part_kywrd_txt"></ngx-datatable-column>
    <ngx-datatable-column name="Matching Part Description Text" prop="mtchg_desc_kywrd_txt"></ngx-datatable-column>
    <ngx-datatable-column name="Component Code" prop="cmpnt_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Source Routing" prop="src_rtg_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Dest Routing" prop="dest_rtg_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Effective In Date" prop="eff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Effective Out Date" prop="expr_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Comments" prop="rmrk_txt" [width]="460"></ngx-datatable-column>
  </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>