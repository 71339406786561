<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Monthly Representative:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="aMnthlyRep"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Comparitive BOM:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="aCompBom"></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Model Number:</label></div>
            <div class=col-8><input type="text" oninput="this.value = this.value.toUpperCase()" [maxLength]="10"
                class="inputtext" [(ngModel)]="aMdlNo"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Option:</label></div>
            <div class=col-8><input type="text" oninput="this.value = this.value.toUpperCase()" [maxLength]="5"
                class="inputtext" [(ngModel)]="aOptnCd"></div>
          </div>
        </div>
      </div>

      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>SAP Material ID:</label></div>
            <div class=col-8><input type="text" oninput="this.value = this.value.toUpperCase()" [maxLength]="20"
                class="inputtext" [(ngModel)]="aSapMatId"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
          </div>
        </div>
      </div>

      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC<span class="text-danger">*</span>:</label></div>
            <div class="col-8">
              <select class="heightadd" aria-label="Default select example " [(ngModel)]="aNamc"
                (change)='getFamCdByNamc()'>
                <option value=""></option>
                <option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select class="heightadd" aria-label="Default select example" [(ngModel)]="aFamily"
                (change)="getKatashikiByFamCd();getHikiateByDest();getpcspecByFamily();getcomppcspecByFamily()">
                <option value=""></option>
                <option *ngFor="let famcd of famcdLst" [value]="famcd.fam_cd">{{famcd.fam_cd}}</option>
              </select></div>
          </div>
        </div>
      </div>

    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Katashiki<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <select class="heightadd" aria-label="Default select example" [(ngModel)]="aKatashiki">
              <option value=""></option>
              <option *ngFor="let katashiki of katashikiLst" [value]="katashiki.katashiki_cd">{{katashiki.katashiki_cd}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Customer<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select class="heightadd" aria-label="Default select example" [(ngModel)]="aCust">
              <option value=""></option>
              <option *ngFor="let cust of customerLst" [value]="cust.buyoff_cust_id">{{cust.buyoff_cust_nm}}</option>
            </select></div>
        </div>
      </div>
    </div>

    <!-- row6 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Dest:</label></div>
          <div class=col-8><select class="heightadd" aria-label="Default select example" [(ngModel)]="aDest">
              <option value=""></option>
              <option *ngFor="let dest of destLst" [value]="dest.dest_ctry_id">{{dest.dest_ctry_id}}</option>
            </select></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>S/P<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select class="heightadd" aria-label="Default select example" (change)="getCompSpec()"
              [(ngModel)]="aSP">
              <option selected></option>
              <option value="S">S</option>
              <option value="P">P</option>

            </select></div>
        </div>
      </div>
    </div>

    <!-- row7 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Hikiate Status:</label></div>
          <div class=col-8><select id="hikiSelect" [(ngModel)]="dummyHikiateLst" class="multiple" multiple
              aria-label="Default select example">
              <option value=""></option>
              <option [value]="hikiate.hikiate" *ngFor="let hikiate of hikiateLstFromDB">
                {{hikiate.hikiate}}</option>
            </select></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <button class="btn" (click)="moveHikiForward()">>></button><br>
            <button class="btn" (click)="moveHikiBackward()">
              << </button>
          </div>
          <div class=col-8><select class="multiple" multiple [(ngModel)]="dummyHikiateLstToDB"
              aria-label="Default select example">
              <option value={{selectedHikiate}} *ngFor="let selectedHikiate of selectedHikiateLst">{{selectedHikiate}}
              </option>
            </select></div>
        </div>
      </div>
    </div>

    <!-- row8 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>PC Spec:</label></div>
          <div class=col-8><select id="pcspec" multiple class="multiple" [(ngModel)]="dummyPcSpecLst"
              aria-label="Default select example">
              <option value=""></option>
              <option value={{pcSpec.pc_spec}} *ngFor="let pcSpec of pcSpecLstFromDB">{{pcSpec.pc_spec}}</option>
            </select></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <button class="btn" (click)="movePcSPecForward();getnetSpec();format()">>></button><br>
            <button class="btn" (click)="movePcSpecBackward();format()">
              << </button>
          </div>
          <div class=col-8><select class="multiple" [(ngModel)]="dummyPcSpecLstToDB" multiple
              aria-label="Default select example">
              <option value={{selectedPcSpec}} *ngFor="let selectedPcSpec of selectedPcSpecLst">{{selectedPcSpec}}
              </option>
            </select></div>
        </div>
      </div>
    </div>

    <!-- row9 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Comp PC Spec:</label></div>
          <div class=col-8><select class="multiple" [(ngModel)]="dummyCompPcSpecLst" multiple
              aria-label="Default select example" [disabled]="hidedata">
              <option value=""></option>
              <option value={{compPcSpec.pc_spec}} *ngFor="let compPcSpec of compPcSpecLstFromDB">{{compPcSpec.pc_spec}}
              </option>
            </select></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <button class="btn" (click)="moveCompPcSpecForward()">>></button><br>
            <button class="btn" (click)="moveCompPcSpecBackward();format()">
              << </button>
          </div>
          <div class="col-8 "><select class="multiple" [(ngModel)]="dummyCompPcSpecLstToDB" multiple
              aria-label="Default select example">
              <option value={{selectedCompPcSpec}} *ngFor="let selectedCompPcSpec of selectedCompPcSpecLst">
                {{selectedCompPcSpec}}</option>
            </select></div>
        </div>
      </div>
    </div>
    <!-- row10 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Net Spec:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8" ><input class="w-100" type="text" [(ngModel)]="aNetSpec" disabled></div>
    </div>

    <!-- row11 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>100% Spec:</label></div>
          <div class=col-2><input type="checkbox" [(ngModel)]="aHundPerSpec"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Comp 100% Spec:</label></div>
          <div class=col-2><input type="checkbox" [(ngModel)]="aCompHundPerSPec"></div>
        </div>
      </div>
    </div>

    <!-- row12 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> 
            <label>Extraction Time In<span class="text-danger">*</span>:</label>
          </div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="inPicker" class="inputdate" [(ngModel)]="aEFfDtIn" 
               >
              <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
              <mat-datepicker #inPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'aEFfDtIn')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4">
             <label>Extraction Time Out</label>
            </div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="outPicker"  class="inputdate" [(ngModel)]="aExprDt" 
               >
              <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
              <mat-datepicker #outPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'aExprDt')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- row13 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8">
        <textarea class="w-100" name="w3review" rows="4" cols="50" [(ngModel)]="aComments" maxlength="100">
          </textarea>
      </div>
    </div>

  </div>

  </mat-dialog-content>

  <mat-dialog-actions align="center" >
    <button mat-raised-button cdkFocusInitial (click)="submitRepVeh()">Save</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
