<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Copy-Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>


<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

  <mat-dialog-content class="mat-typography" (click)="partdescription(copyPartDesc)" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk ' <span class="text-danger">*</span> ' are mandatory</span>

      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"><label>Monthly Representative:</label></div>
            <div class="col-2"><input type="checkbox" [checked]="copyMonthlyRep" [(ngModel)]="copyMonthlyRep"></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>SAP Material ID:</label></div>
            <div class=col-8><input type="text" value="copySapMtrlId" oninput="this.value = this.value.toUpperCase()"
                [maxLength]="20" class="inputtext" [(ngModel)]="copySapMtrlId" maxlength="20"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select [(ngModel)]="copyNamc" aria-label="Default select example" class="picker"
                (change)='getFamCdByNamc()'>
                <option value=""></option>
                <option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</option>
              </select></div>
          </div>
        </div>
      </div>
      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select [(ngModel)]="copyFamily" aria-label="Default select example" class="picker">
                <option value=""></option>
                <option *ngFor="let famcd of famcdLst" [value]="famcd.fam_cd">{{famcd.fam_cd}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Part<span class="text-danger">*</span>:</label></div>
            <div class=col-8><input type="text" class="inputtext" value="copyPart" [(ngModel)]="copyPart"
                oninput="this.value = this.value.toUpperCase()" (keyup.enter)="partdescription(copyPartDesc)"></div>
          </div>
        </div>
      </div>
      <!-- row4 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Part Description:</label></div>
            <div class=col-8><input disabled type="text" value="copyPartDesc" class="inputtext"
                [(ngModel)]="copyPartDesc">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Related Katashiki:</label></div>
            <div class=col-8><select disabled [(ngModel)]="copyRelKatashiki" class="picker"
                aria-label="Default select example">
                <option value=""></option>
                <option *ngFor="let katashiki of katashikiLst" [value]="katashiki.katashiki_cd">
                  {{katashiki.katashiki_cd}}
                </option>
              </select></div>
          </div>
        </div>
      </div>
      <!-- row5 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Product Type<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select [(ngModel)]="copyProdType" class="picker" (change)='getgrpi()'>
                <option value=""></option>
                <option *ngFor="let prodTyp of prodTypLst" [value]="prodTyp.prod_typ_cd">{{prodTyp.prod_typ_desc}}
                </option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="grpitext">
          <div class="row">
            <div class="col-4"> <label>Grpi Code<span class="text-danger">*</span>:</label></div>
            <div class=col-8>
              <input type="text" class="inputtext" [(ngModel)]="copyGrpi">
            </div>

          </div>
        </div>
      </div>
      <!-- row6 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row" style="margin-top: 5px">
            <div class="col-4"> <label>Extraction Time In<span class="text-danger">*</span>:</label></div>
            <div class=col-8>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="inPicker" [(ngModel)]="copyExtTimeIn">
                <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
                <mat-datepicker #inPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear"
                  (click)="clearDate($event,'copyExtTimeIn')">clear</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row" style="margin-top: 5px">
            <div class="col-4"> <label>Extraction Time Out:</label></div>
            <div class=col-8>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="outPicker" [(ngModel)]="copyExtTimeOut">
                <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
                <mat-datepicker #outPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear"
                  (click)="clearDate($event,'copyExtTimeOut')">clear</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- row7 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
            [(ngModel)]="copyComments" maxlength="100">
        </textarea>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="submitRepUnit()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>