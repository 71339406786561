<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Major Part Type<span class="text-danger">*</span>:</label></div>
            <div class=col-8><input type="text" class="width inputtext" maxlength="30"
                [(ngModel)]="majorPartAddPartType"></div>
          </div>
        </div>

      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Product Type<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select class="heightadd inputtext" [(ngModel)]="majorPartAddProdType"
                aria-label="Default select example">
                <option value=""></option>
                <option *ngFor="let prodTyp of prodTypLst" [value]="prodTyp.prod_typ_cd">{{prodTyp.prod_typ_desc}}
                </option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Active:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="majorPartAddActive"></div>
          </div>
        </div>
      </div>

      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
        <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review"
            [(ngModel)]="majorPartComments" maxlength="100">
          </textarea>
        </div>
      </div>

    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="submitMajPart()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
