import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ExchangeRateSearchService } from './exchange-rate-search.service';
import { DialogData } from '../dialog-data/dialog-data'
import { ExchangeRateEditDialogComponent } from '../exchange-rate-edit-dialog/exchange-rate-edit-dialog.component';
import { ExchangeRateAddDialogComponent } from '../exchange-rate-add-dialog/exchange-rate-add-dialog.component';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-exchange-rate-search',
  templateUrl: './exchange-rate-search.component.html',
  styleUrls: ['./exchange-rate-search.component.css']
})
export class ExchangeRateSearchComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';

  //ngModel values for search
  step = 0;
  currencyCodeFrom: any = "";
  currencyCodeTo: any = "";
  costType: any = "";
  comments: any = "";
  effectiveInDate: any = '';
  effectiveOutDate: any = '';
  exchangeRate: any = "";
  status: any = "Active";
  spinnerMessage = "Please Wait...";
  roleAccess: any = []
  Roledata: any = [];

  //list
  currencyCodeFromLst: string[] = [];
  currencyCodeToLst: string[] = [];
  costTypeLst: string[] = [];
  effectiveInDateLst: string[] = [];
  effectiveOutDateLst: string[] = [];
  exchangeRateLst: string[] = [];
  statusLst = [];
  commentsLst: string[] = [];

  //mat-dialog
  data: any = {};

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;

  showdata: boolean = true;
  columns = [

    { name: 'currencyCodeFrom', prop: 'crncy_1_cd' }, { name: 'currencyCodeTo', prop: 'crncy_2_cd' },
    { name: 'costType', prop: 'exchng_rt_typ' }, { name: 'effectiveInDate', prop: 'eff_dt' },
    { name: 'effectiveOutDate', prop: 'expr_dt' }, { name: 'exchangeRatee', prop: 'cur_exchng_rt' },
    { name: 'status', prop: 'inactv_flg' }, { name: 'comments', prop: 'rmrk_txt' }

  ];
  ColumnMode = ColumnMode;
  recordcount: any = 0;
  sameLengthRows: boolean = false;
  accessEnable:any;
  constructor(private exchangeRateService: ExchangeRateSearchService, public dialog: MatDialog,
    private dialogData: DialogData, private readonly SpinnerService: NgxSpinnerService,
    public globalrole: GlobalService) {

  }

  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }
    console.log("effectiveInDate", this.effectiveInDate);
    console.log("effectiveOutDate", this.effectiveOutDate);
    this.onloadfn();

  }

  onloadfn() {
    //to get CurrencyCodeFrom
    this.exchangeRateService.getCurrencyCodeFrom().subscribe(data => {
      console.log(data);
      this.currencyCodeFromLst = data.payload;
    });

    // to get CurrencyCodeTo
    this.exchangeRateService.getCurrencyCodeTo().subscribe(data => {
      console.log(data);
      this.currencyCodeToLst = data.payload;

    });

    // to get CostType
    this.exchangeRateService.getCostType(this.currencyCodeFrom, this.currencyCodeTo).subscribe(data => {
      console.log(data);
      this.costTypeLst = data.payload;
    });

    // to get ExchangeRate
    this.exchangeRateService.getExchangeRate(this.effectiveInDate, this.currencyCodeFrom, this.currencyCodeTo, this.costType, this.effectiveOutDate).subscribe(data => {
      console.log(data);
      this.exchangeRateLst = data.payload;
    });
  }


  getCostTypeByCurFrom() {
    this.costType = "";
    this.exchangeRate = "";
    this.currencyCodeTo = "";

  }
  //onchange
  getCostTypeByCur(): void {
    if (this.currencyCodeFrom == undefined) {
      this.currencyCodeFrom = "";
    }
    if (this.currencyCodeTo == undefined) {
      this.currencyCodeTo = "";
    }
    this.exchangeRateService.getCostType(this.currencyCodeFrom, this.currencyCodeTo).subscribe(data => {
      console.log(data);
      this.costTypeLst = data.payload;
      this.costType = "";
      this.exchangeRate = "";
      // this.effectiveInDateLst= [];
      // this.effectiveOutDateLst = [];
    });
  }
  //onchange
  getExchangeRateByDt(): void {
    console.log("effectiveInDate", this.effectiveInDate);

    if (this.currencyCodeFrom == undefined) {
      this.currencyCodeFrom = "";
    }
    if (this.currencyCodeTo == undefined) {
      this.currencyCodeTo = "";
    }
    if (this.costType == undefined) {
      this.costType = "";
    }
    if (this.effectiveInDate == undefined || this.effectiveInDate == '') {
      this.effectiveInDate = "";
    }
    else {
      this.effectiveInDate = moment(this.effectiveInDate).format('YYYY-MM-DD')
    }

    if (this.effectiveOutDate == undefined) {
      this.effectiveOutDate = "";
    }
    else {
      this.effectiveOutDate = moment(this.effectiveOutDate).format('YYYY-MM-DD')
    }
    this.exchangeRateService.getExchangeRate(this.effectiveInDate, this.currencyCodeFrom, this.currencyCodeTo, this.costType, this.effectiveOutDate).subscribe(data => {
      console.log(data);
      this.exchangeRateLst = data.payload;
      this.exchangeRate = "";
    });

  }
  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length == selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length == 0) {
      this.sameLengthRows = false
    }

    console.log('Select Event', selected, this.selected);

  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }
  openAddDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(ExchangeRateAddDialogComponent, { disableClose: true });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result.data);
        if (result == 'success') {
          this.getGridData("add");
          // this.resetAll("add");
          this.successMessage = "Records Added Successfully"
          this.selected = []
        }
        else if (result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!'
          this.globalrole.goToTop();
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  openEditDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(ExchangeRateEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          // this.selected = [];
          if (result == 'success') {
            // this.resetAll("add");
            this.getGridData("add");
            this.successMessage = "Records Updated Successfully"


            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }

        });

      }
      else {
        if (this.sameLengthRows == true) {
          this.errorMessage = "please select only one row to Edit"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows == false) {
          this.errorMessage = "please select a row to Edit"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }



  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }


  sendToDialog(selected): void {

    selected.forEach(row => {
      this.dialogData.currencyCodeFrom = row.crncy_1_cd;
      this.dialogData.currencyCodeTo = row.crncy_2_cd;
      this.dialogData.costType = row.exchng_rt_typ;
      this.dialogData.monthSelection = row.monthSelection;
      this.dialogData.effectiveInDate = row.eff_dt;
      this.dialogData.effectiveOutDate = row.expr_dt;
      this.dialogData.exchangeRate = row.cur_exchng_rt;
      this.dialogData.comments = row.rmrk_txt;
      this.dialogData.active = row.inactv_flg;
      this.dialogData.exchng_rt_id = row.exchng_rt_id
    });
  }

  resetAll(data): void {
    this.selected = []
    this.errorMessage = '';
    this.successMessage = '';
    this.currencyCodeFrom = '';
    this.currencyCodeTo = '';
    this.costType = '';
    this.effectiveInDate = '';
    this.effectiveOutDate = '';
    this.exchangeRate = '';
    this.status = 'Active';
    // this.getGridData(data);
    this.onloadfn();
  }

  search() {
    console.log(this.effectiveInDate, this.effectiveOutDate)
    if (this.effectiveOutDate == 'Invalid date') {
      this.effectiveOutDate = ''
    }
    if (this.effectiveInDate && this.effectiveOutDate && this.pipe.transform(this.effectiveInDate, 'yyyy-MM-dd') > this.pipe.transform(this.effectiveOutDate, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
      this.globalrole.goToTop();
    }
    else {
      this.errorMessage = '';
      this.successMessage = '';
      this.getGridData("initial");
    }
  }

  getGridData(data) {
    this.selected = []
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      let sts = ""

      if (this.currencyCodeFrom == undefined) {
        this.currencyCodeFrom = ""
      }
      if (this.currencyCodeTo == undefined) {
        this.currencyCodeTo = ""
      }
      if (this.costType == undefined) {
        this.costType = ""
      }
      if (this.exchangeRate == undefined) {
        this.exchangeRate = ""
      }
      if (this.status == undefined) {
        this.status = ""
      }
      if (this.status == "Active") {
        sts = 'N'
      } else if (this.status == "Inactive") {
        sts = 'Y'
      }
      let indateFormat = ''; let outdateFormat = '';
      if (this.effectiveInDate != "") {
        let d1 = moment(this.effectiveInDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.effectiveInDate = date

      }
      if (this.effectiveOutDate != "") {
        let d1 = moment(this.effectiveOutDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.effectiveOutDate = date
        // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
      }

      let gridData
      if (data == "initial") {
        gridData =
        {
          currencyCodeFrom: this.currencyCodeFrom,
          currencyCodeTo: this.currencyCodeTo,
          costType: this.costType,
          effectiveInDate: indateFormat,
          effectiveOutDate: outdateFormat,
          exchangeRate: this.exchangeRate,
          status: sts,
          option: data
        }
      }
      else {
        gridData =
        {
          currencyCodeFrom: "",
          currencyCodeTo: "",
          costType: "",
          effectiveInDate: "",
          effectiveOutDate: "",
          exchangeRate: "",
          status: "",
          option: data
        }
      }
      this.SpinnerService.show();
      this.exchangeRateService.getExchangeRateGrid(gridData).subscribe(data => {
        console.log(data);
        this.showdata = true;
        this.recordcount = data.pagination.totalRecords;
        console.log(this.showdata);
        this.SpinnerService.hide();
        let datarow = data.payload;
        this.rows = datarow.map(row => ({
          crncy_1_cd: row['crncy_1_cd'],
          crncy_2_cd: row['crncy_2_cd'],
          exchng_rt_typ: row['exchng_rt_typ'],
          eff_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
          expr_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],
          cur_exchng_rt: row['cur_exchng_rt'],
          inactv_flg: row['inactv_flg'] == "N" ? "Active" : "In Active",
          rmrk_txt: row['rmrk_txt'],
          exchng_rt_id: row['exchng_rt_id']
        }));

        console.log(this.rows)

      }, err => {
        console.log(err);
        this.showdata = false;
        this.successMessage = "";
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'effectiveInDate') {
      this.effectiveInDate = "";
    } else if (name == 'effectiveOutDate') {
      this.effectiveOutDate = "";
    }
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_3910') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

          if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }
         else {
          console.log(this.roleAccess)
          this.roleAccess = [];
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.log(this.roleAccess);
    })
  }
}
