import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ReferenceModule } from './reference.module';
import { HomeComponent } from './home/home.component';

import { VersionDialogData } from './dialog-data/version-dialog-data';
import { DialogData } from './dialog-data/dialog-data';
import { ProductWithinVersion } from './dialog-data/product-within-version';
import { GLReconPeriod } from './dialog-data/gl-recon-period';

import { RepVehicleModule } from './master/rep-vehicle.module';
import { RepUnitsModule } from './master/rep-units.module';
import { MngDataTrnsfrModule } from './master/mng-data-trnsfr.module';
import { SapDataLoadModule } from './master/sap-data-load.module';
import { VerTypesModule } from './master/ver-types.module';
import { VerQualModule } from './master/ver-qual.module';
import { MajPartLstModule } from './master/maj-part-lst.module';
import { MajPartTypModule } from './master/maj-part-typ.module';
import { PartSrcModule } from './master/part-src.module';
import { PartSrcRtgModule } from './master/part-src-rtg.module';
import { ExcRateModule } from './master/exc-rate.module';
import { SlctyPartRuleModule } from './master/slcty-part-rule.module';
import { HundPerSpecModule } from './master/hund-per-spec.module';
import { FamProjNamcModule } from './master/fam-proj-namc.module';
import { ExtPartLstModule } from './master/ext-part-lst.module';
import { PcspecExcLstModule } from './master/pcspec-exc-lst.module';
import { FamilyModule } from './master/family.module';
import { HikiateModule } from './master/hikiate.module';
import { PlntUsrCdModule } from './master/plnt-usr-cd.module';
import { IsSysParmModule } from './master/is-sys-parm.module';
import { ProcStatusModule } from './master/proc-status.module';

import { VersionModule } from './business/version.module';
import { ProdWithinVersionModule } from './business/prod-within-version.module';
import { ProdProcStatusModule } from './business/prod-proc-status.module';
import { ManAdjRuleModule } from './business/man-adj-rule.module';
import { PartSrcAdjModule } from './business/part-src-adj.module';
import { QualAndExcepModule } from './qual-and-excep.module';
import { QualityAndExceptionComponent } from './quality-and-exception/quality-and-exception.component';

import { EbomIsDashboardComponent } from './ebom-is-dashboard/ebom-is-dashboard.component';
import { GlobalService } from './global.service'
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AuthHttpInterceptor } from './app.interceptor'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ManualJobTriggerComponent } from './manual-job-trigger/manual-job-trigger.component';
import { ManualJobTriggerEditDialogComponent } from './manual-job-trigger-edit-dialog/manual-job-trigger-edit-dialog.component';



const routes: Routes = [];
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    QualityAndExceptionComponent,
    EbomIsDashboardComponent,
    ManualJobTriggerComponent,
    ManualJobTriggerEditDialogComponent
  ],
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RepVehicleModule,
    RepUnitsModule,
    MngDataTrnsfrModule,
    SapDataLoadModule,
    VerTypesModule,
    VerQualModule,
    MajPartLstModule,
    MajPartTypModule,
    PartSrcModule,
    PartSrcRtgModule,
    ExcRateModule,
    SlctyPartRuleModule,
    HundPerSpecModule,
    FamProjNamcModule,
    ExtPartLstModule,
    PcspecExcLstModule,
    FamilyModule,
    HikiateModule,
    PlntUsrCdModule,
    IsSysParmModule,
    ProcStatusModule,
    VersionModule,
    ProdWithinVersionModule,
    ProdProcStatusModule,
    ManAdjRuleModule,
    PartSrcAdjModule,
    ReferenceModule,
    QualAndExcepModule,
    NgIdleKeepaliveModule.forRoot(),

  ],
  providers: [VersionDialogData, DialogData, ProductWithinVersion, GLReconPeriod, GlobalService,
   
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    
    
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
