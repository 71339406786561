import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { SapDataMultieditDialogService } from './sap-data-multiedit-dialog.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-sap-data-multiedit-dialog',
  templateUrl: './sap-data-multiedit-dialog.component.html',
  styleUrls: ['./sap-data-multiedit-dialog.component.css']
})
export class SapDataMultieditDialogComponent implements OnInit {
  [x: string]: any;


  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //to main page
  message = "records updated successfully";

  errorMessage = "";

  //hikiate
  hikiateLstFromDB: any = [];
  selectedHikiateLst: any = [];
  dummyHikiateLst: any;
  dummyHikiateLstToDB: any = [];
  familyLst: any = []
  namcLst: any = [];
  //ngModelValues
  eMonthlyRep: any = "";
  eCompBom: any;
  eNamc: any;
  eModelNumber: any;
  eOption: any;
  eSapMaterialId: any;
  eFamily: any;
  epartNumber: any;
  eproductType: any;
  eKatashiki: any;
  eCustomerId: any;
  eDest: any;
  eHikiate: any;
  eSP: any;
  ePcSpec: any;
  eCompPcSpec: any;
  eNetSpec: any;
  eHundPercentSpec: any;
  eCompHundPercentSpec: any;
  eExtractionTimeIn: any = "";
  eExtractionTimeOut: any;
  eComments: any;
  erepproddefid: any = [];
  hikiatevalue: any;
  //respone after inserting a record
  response: any;
  spinnerMessage = "Please Wait"

  constructor(public dialogRef: MatDialogRef<SapDataMultieditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private SapDataMultiEditDialogService: SapDataMultieditDialogService, private readonly SpinnerService: NgxSpinnerService) { }

  async ngOnInit() {
    console.log(this.data);
    let a = await this.getNamc();
    let namc_id;
    if (a) {
      console.log(this.data);

      for (let i = 0; i < this.namcLst.length; i++) {
        if (this.data[0].namc_cd == this.namcLst[i].namc_cd) {
          namc_id = this.namcLst[i].namc_lgl_entity_id
        }
      }
    }
    let arrayvalue: any;
    arrayvalue = this.data;
    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = "'" + arrayvalue[i].rep_prod_def_sap_id + "'"
      } else {
        str += ',' + "'" + arrayvalue[i].rep_prod_def_sap_id + "'";
      }
      this.erepproddefid = str;
    }
    console.log(this.erepproddefid)
    this.eNamc = namc_id;
    console.log(this.eNamc)
    this.getFamCdByNamc()
    // this.getHikiateByDest();
  }

  getNamc() {
    // to get namc
    return new Promise(res => {
      this.SapDataMultiEditDialogService.getNamcSap().subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.namcLst = data.payload;
        res(true);
      });
    })
  }
  //closealert
  closeAlert() {
    this.errorMessage = "";
  }
  updateSapDataLoad() {
    let user = localStorage.getItem('workDayId');
    let fileName=localStorage.getItem('Filename')
    console.log(this.eExtractionTimeIn);
    // if (!this.eExtractionTimeIn) {
    //   this.errorMessage = "Please enter all mandatory field";
    // }
    // else 
    if (this.eExtractionTimeIn && this.eExtractionTimeOut &&
      this.pipe.transform(this.eExtractionTimeIn, 'yyyy-MM-dd') >= this.pipe.transform(this.eExtractionTimeOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    }

    else {
      this.errorMessage = "";
      if (this.eExtractionTimeOut == "") {
        this.eExtractionTimeOut = "9999-12-31"
      }
      let monthlyrep = ""
      if (this.eMonthlyRep == true) {
        monthlyrep = "Y"
      } else if (this.eMonthlyRep == false) {
        monthlyrep = "N"
      }
      let hikiatelstArray: any;
      hikiatelstArray = this.selectedHikiateLst;
      console.log(hikiatelstArray.length, hikiatelstArray);
      let str = '';
      for (let i = 0; i < hikiatelstArray.length; i++) {
        if (str == '') {
          str = hikiatelstArray[i]
        } else {
          str += ',' + hikiatelstArray[i]
        }
        // this.hikiatevalue = str;
      }
      this.hikiatevalue = str;
      let body = {
        'famCd': this.eFamily,
        'hikiateStatCombCd': this.hikiatevalue,
        'effDt': this.eExtractionTimeIn,
        'exprDt': this.eExtractionTimeOut,
        'rmrkTxt': this.eComments,
        'repProdDefSapId': this.erepproddefid,
        'monthlyRepFlg': monthlyrep,
        'fileName': fileName
        
      }
      console.log(body);
      this.SpinnerService.show();
      this.SapDataMultiEditDialogService.editSap(body).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });

    }
  }
  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  getFamCdByNamc(): void {
    if (this.eNamc == undefined) {
      this.eNamc = ""
    }
    console.log(this.eNamc)
    this.SapDataMultiEditDialogService.getFamilySap(this.eNamc).subscribe(data => {
      console.log(data);
      this.familyLst = data.payload;
      console.log(this.familyLst)
      // this.family = ""
    });
  }
  getHikiateByDest(): void {
    if (this.eNamc == undefined) {
      this.eNamc = ""
    }
    if (this.eFamily == undefined) {
      this.eFamily = ""
    }

    this.SapDataMultiEditDialogService.getHikiate(this.eFamily, this.eNamc).subscribe(data => {
      console.log(data);
      this.hikiateLstFromDB = data.payload;
      this.ehikiate = ""
    });
  }

  //hikiate
  // moveHikiForward(): void {
  //   let hikiatelst = this.hikiateLstFromDB
  //   console.log(this.dummyHikiateLst);
  //   this.dummyHikiateLst.forEach(hiki => {
  //     this.selectedHikiateLst.push(hiki);
  //     for (let i = 0; i < hikiatelst.length; i++) {
  //       if (hiki == hikiatelst[i].hikiate) {
  //         hikiatelst.splice(i, 1);
  //       }
  //     }
  //   });
  //   this.hikiateLstFromDB = hikiatelst;
  //   console.log(this.hikiateLstFromDB);
  //   console.log(this.selectedHikiateLst);
  //   this.dummyHikiateLstToDB = this.selectedHikiateLst;
  //   this.dummyHikiateLst = ""
  // }
  // moveHikiBackward(): void {
  //   this.dummyHikiateLstToDB.forEach(hiki => {
  //     let body = {
  //       "hikiate": hiki
  //     }
  //     this.hikiateLstFromDB.push(body);
  //     this.index = this.selectedHikiateLst.indexOf(hiki);
  //     this.selectedHikiateLst.splice(this.index, 1);
  //   });
  //   if (this.dummyHikiateLstToDB.length >= 1) {
  //     this.hikiateLstFromDB = this.hikiateLstFromDB.sort((a, b) => a.hikiate > b.hikiate ? 1 : -1);
  //     // this.dummyHikiateLstToDB = this.selectedHikiateLst;-
  //     this.dummyHikiateLstToDB = []
  //   }
  // }

  moveHikiForward() {

    let hikiatelst = this.hikiateLstFromDB
    console.log(this.dummyHikiateLst);
    this.dummyHikiateLst.forEach(hiki => {
      this.selectedHikiateLst.push(hiki);
      for (let i = 0; i < hikiatelst.length; i++) {
        if (hiki == hikiatelst[i].hikiate) {
          hikiatelst.splice(i, 1);
        }
      }
    });
    this.hikiateLstFromDB = hikiatelst;
    this.dummyHikiateLstToDB = this.selectedHikiateLst;
    this.dummyHikiateLst = ""
  }
  moveHikiBackward() {
    this.dummyHikiateLstToDB.forEach(hiki => {
      let body = {
        "hikiate": hiki
      }
      this.hikiateLstFromDB.push(body);
      this.index = this.selectedHikiateLst.indexOf(hiki);
      this.selectedHikiateLst.splice(this.index, 1);
    });
    if (this.dummyHikiateLstToDB.length >= 1) {
      this.hikiateLstFromDB = this.hikiateLstFromDB.sort((a, b) => a.hikiate > b.hikiate ? 1 : -1);
      this.dummyHikiateLstToDB = [];
    }
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'eExtractionTimeOut') {
      this.eExtractionTimeIn = "";
    } else if (name == 'eExtractionTimeOut') {
      this.eExtractionTimeOut = "";
    }
  }
}
