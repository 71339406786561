import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { DialogData } from '../dialog-data/dialog-data';
import { FamilyMultieditDialogService } from './family-multiedit-dialog.service';

@Component({
  selector: 'app-family-multiedit-dialog',
  templateUrl: './family-multiedit-dialog.component.html',
  styleUrls: ['./family-multiedit-dialog.component.css']
})
export class FamilyMultieditDialogComponent implements OnInit {
  [x: string]: any;
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');
  //to main page
  message = "Records Edited Successfully";
  index: any;
  errorMessage = "";
  eFamily: any = "";
  //ngModelValues
  eEffectiveInDate: any;
  eEffectiveOutDate: any;
  family_id: any;
  constructor(public dialogRef: MatDialogRef<FamilyMultieditDialogComponent>, private readonly SpinnerService: NgxSpinnerService, @Inject(MAT_DIALOG_DATA) public data: DialogData, public familyMultiEditService: FamilyMultieditDialogService) {
    console.log(this.data)
    this.eFamily = this.data.family;
    let arrayvalue: any;
    arrayvalue = this.data;
    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = "'" + arrayvalue[i].fam_cd + "'"
      } else {
        str += ',' + "'" + arrayvalue[i].fam_cd + "'";
      }
      this.family_id = str;
    }
  }


  ngOnInit(): void {
  }
  //closealert
  closeAlert() {
    this.errorMessage = "";
  }


  updateFam() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    console.log(this.eEffectiveInDate, this.eEffectiveOutDate);

    if (!this.eEffectiveInDate || !this.eEffectiveOutDate) {

      this.errorMessage = "Please enter all mandatory field";

    }
    else if (this.eEffectiveInDate && this.eEffectiveOutDate &&
      this.pipe.transform(this.eEffectiveInDate, 'yyyy-MM-dd') > this.pipe.transform(this.eEffectiveOutDate, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
    }

    else {
      let indateFormat = ''; let outdateFormat = '';
      if (this.eEffectiveInDate != "") {
        let d1 = moment(this.eEffectiveInDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.eEffectiveInDate = date
      }
      if (this.eEffectiveOutDate != "") {
        let d1 = moment(this.eEffectiveOutDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.eEffectiveOutDate = date
      }
      let body = {
        "fam_cd": this.family_id,
        "expr_dt": outdateFormat,
        "eff_dt": indateFormat,
        "screenNo":"NBPC_9995",
        "fileName" :fileName

        
	
      }
      this.SpinnerService.show();
      this.familyMultiEditService.updateMultiFamily(body).subscribe(data => {
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }


  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'eEffectiveInDate') {
      this.eEffectiveInDate = "";
    } else if (name == 'eEffectiveOutDate') {
      this.eEffectiveOutDate = "";
    }
  }
}
