import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('Ebom_accessToken'),
      'x-api-key':localStorage.getItem('Filename')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class HunderedSpecificationsAddDialogService {

  addhundPerData = 'add100Spec';
  namc = '/getNamcHps';
  family = '/getFamilyByNamcHps';
  hikiate = '/getHikiateByNamc';
  destination = '/getDestRepVeh';
  katashiki = "/getKatashikiHps";
  pcSpec = '/getPcSpecHunSpec';

  constructor(private http: HttpClient) { }

  //existscheck100Spec
  existscheck100Spec(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = `${environment.Master_ActionapiUrl}/${'existChkHunPro'}`;
    return this.http.post(API_URL, body,httpOptions)
      .pipe(
        catchError(this.error)

      )
  }
  //addhundPer
  addhundPer(glObj): Observable<any> {
     var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = `${environment.Master_ActionInsertApiUrl}/${this.addhundPerData}`;
    return this.http.post(API_URL, glObj,httpOptions)
      .pipe(
        catchError(this.error)

      )
  }
  //namc
  getNamcHps(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = environment.Mastersearchdropdown2apiUrl + this.namc+'?screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //family
  getFamilyHps(namc): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let famParams = new HttpParams().set('namcId', namc)
      
    let API_URL = environment.Mastersearchdropdown2apiUrl + this.family + '?namcId=' + namc+'&screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //hikiate
  getHikiateStatusHps(famCd): Observable<any> {
     var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let hikiParams = new HttpParams().set('famCd', famCd)
    let API_URL = environment.Mastersearchdropdown1apiUrl + this.hikiate + '?famCd=' + famCd+'&screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //destination
  getDestHps(katashiki): Observable<any> {
     var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let destParams = new HttpParams().set('katashikiCd', katashiki)
    let API_URL = environment.MastersearchdropdownapiUrl + this.destination + '?katashikiCd=' + katashiki+'&screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //katashiki
  getKatashikiHps(namc, family): Observable<any> {
     var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let katasParams = new HttpParams().set('namcId', namc).append('famCd', family)
    let API_URL = environment.Mastersearchdropdown2apiUrl + this.katashiki + '?namcId=' + namc +
    '&famCd=' + family+'&screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //pcSpec
  getPcSpecHps(family): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let specParams = new HttpParams().set('famCd', family)
    let API_URL = environment.Mastersearchdropdown1apiUrl + this.pcSpec + '?famCd=' + family+'&screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }



  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
