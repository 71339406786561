import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { CircularParentChildService } from './circular-parent-child.service';
import { DialogData } from '../dialog-data/dialog-data';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';
import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-circular-parent-child',
  templateUrl: './circular-parent-child.component.html',
  styleUrls: ['./circular-parent-child.component.css']
})
export class CircularParentChildComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //export
  fileName = "c4circularParentChild";
  exportData = [];
  sheetName = [];

  //alertMessage
  errorMessage: string = '';

  //ngModel values for search
  step = 0;
  namc: any = "";
  family: any = "";
  katashiki: any = "";
  mfgSpecCode: any = "";
  partNumber: any = "";
  grpi: any = "";
  startDate: any = "";
  endDate: any = "";
  part: any = "";
  partDescription: any = "";
  parentPart: any = "";
  partLevel: any = "";
  katashikiPart: any = "";
  vin: any = "";
  bomEffectivityDate: any = "";
  suspensionDate: any = "";
  prodDefId: any = "";
  prodBomId: any = "";
  spinnerMessage = "Please Wait...";


  //list
  namcLst: string[] = [];
  familyLst: string[] = [];
  katashikiLst: string[] = [];
  mfgSpecCodeLst: string[] = [];
  partNumberLst: string[] = [];
  grpiLst: string[] = [];
  startDateLst: string[] = [];
  endDateLst: string[] = [];
  recordcount: any;
  accessKey: any;
  secretKey: any;
  sessionToken: any;
  accessEnable: boolean;
  roleAccess: any = []
  Roledata: any = []

  getGridData() {
    this.errorMessage = "";
    if (this.namc == undefined) {
      this.namc = "";
    }
    if (this.family == undefined) {
      this.family = "";
    }
    if (this.part == undefined) {
      this.part = "";
    }
    if (this.katashikiPart == undefined) {
      this.katashikiPart = "";
    }
    if (this.mfgSpecCode == undefined) {
      this.mfgSpecCode = "";
    }
    if (this.grpi == undefined) {
      this.grpi = "";
    }

    let indateFormat = ''; let outdateFormat = '';
    if (this.startDate != "") {
      let d1 = moment(this.startDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.startDate = date
    }
    if (this.endDate != "") {
      let d1 = moment(this.endDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.endDate = date
    }
    let newDate = new Date();
    let date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
    let fileName = 'c4-cir_parnt_child/c4gridsrc' + date + '.json';

    let gridData =
    {
      part: this.partNumber,
      namc: this.namc,
      family: this.family,
      katashikiPart: this.katashiki,
      mfgSpecCode: this.mfgSpecCode,
      grpi: this.grpi,
      startDate: indateFormat,
      suspensionDate: outdateFormat,
      fileName: fileName
    }
    this.SpinnerService.show();
    this.circularParentChildService.getGridData(gridData).subscribe(data => {
      console.log(data)
      this.showdata = true;
      console.log(this.showdata);
      this.recordcount = data.pagination.totalRecords;
      let datarow = data.payload;
      if (data.payload[0].RecordCnt > 1000) {
        setTimeout(() => {
          this.getFileFromS3(fileName);
        }, 2000);
      } else {
        this.rows = data.payload;
        this.SpinnerService.hide();
        this.errorMessage = "";
      }
    }, err => {
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      if (err == 0) {
        this.errorMessage = "Request timed out. Please try again.";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      } else {
        this.errorMessage = "No Records";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      }
    });

  }
  //mat-dialog
  data: any = {};

  //ngx-datatable
  showGrid: boolean = false;
  showButtons: boolean = false;
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;

  showdata: boolean = true;

  columns = [


    { name: 'part', prop: 'part_no' }, { name: 'partDescription', prop: 'part_desc' },
    { name: 'parentPart', prop: 'par_part_no' }, { name: 'partLevel', prop: 'part_level' },
    { name: 'namc', prop: 'namc_cd' }, { name: 'family', prop: 'fam_cd' },
    { name: 'katashki/part', prop: 'katashki_cd' }, { name: 'mfgSpecCode', prop: 'mfg_spec_cd' },
    { name: 'grpi', prop: 'grpi_cd' }, { name: 'vin', prop: 'vin' },
    { name: 'bomEffectivityDate', prop: 'prod_trnfr_dt' }, { name: 'suspensionDate', prop: 'excp_dt' },
    { name: 'prodDefId', prop: 'prod_def_id' }, { name: 'prodBomId', prop: 'prod_bom_id' }


  ];
  ColumnMode = ColumnMode;

  constructor(private circularParentChildService: CircularParentChildService, public dialog: MatDialog,
    private dialogData: DialogData, private exportService: ExportToExcelService, private readonly SpinnerService: NgxSpinnerService, public globalrole: GlobalService,) {

  }

  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    this.SpinnerService.hide();
    if (a) {
      console.log('a', a)
      //grid
      this.onloadfn();
    }
  }
  onloadfn() {

    //to get namc
    this.circularParentChildService.getNamc().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });


    
    this.circularParentChildService.getFamily(this.namc).subscribe(data => {
      console.log(data);
      this.familyLst = data.payload;
    });

  
    this.circularParentChildService.getKatashiki(this.family).subscribe(data => {
      console.log(data);
      this.katashikiLst = data.payload;
    });
  }

  getFamCdByNamc() {
    if (this.namc == undefined) {
      this.namc = "";
    }
    //to get family
    this.circularParentChildService.getFamily(this.namc).subscribe(data => {
      console.log(data);
      this.familyLst = data.payload;
      this.family = "";
    });
  }

  getKatashikiByFamCd() {
    if (this.family == undefined) {
      this.family = "";
    }
    this.circularParentChildService.getKatashiki(this.family).subscribe(data => {
      console.log(data);
      this.katashikiLst = data.payload;
      this.katashiki = "";
    });
  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }
  onActivate(event) {
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }


  generateReport(): void {
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      this.showGrid = true;
      this.showButtons = true;
      this.errorMessage = ""
      this.getGridData();
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  exportFile(): void {
    console.log(this.rows);
    if (this.rows.length != 0) {
      this.rows.forEach(row => {

        let obj =
        {
          "Part": row.part_no,
          "Part Description": row.part_desc,
          "Parent Part": row.par_part_no,
          "Part Level": row.part_level,
          "NAMC": row.namc_cd,
          "Family": row.fam_cd,
          "Katashki/Part": row.katashiki_cd,
          "MFG Spec Code": row.mfg_spec_cd,
          "GRPI": row.grpi_cd,
          "VIN": row.vin,
          "BOM Effectivity Date": row.prod_trnfr_dt,
          "Suspension Date": row.excp_dt,
          "Prod Def Id": row.prod_def_id,
          "Product": row.prod_bom_id

        }
        this.exportData.push(obj);
      });
    } else {
      let obj =
      {
        "Part": "",
        "Part Description": "",
        "Parent Part": "",
        "Part Level": "",
        "NAMC": "",
        "Family": "",
        "Katashki/Part": "",
        "MFG Spec Code": "",
        "GRPI": "",
        "VIN": "",
        "BOM Effectivity Date": "",
        "Suspension Date": "",
        "Prod Def Id": "",
        "Product": "",
      }
      this.exportData.push(obj)
    }
    this.exportService.exportAsExcelFileForReport(this.exportData, this.fileName);
    this.exportData = []
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'startDate') {
      this.startDate = "";
    } else if (name == 'endDate') {
      this.endDate = "";
    }
  }

  closeAlert() {
    this.errorMessage = "";
  }


  async getFileFromS3(fileName) {
    this.SpinnerService.show();

    let a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.log(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.log('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.log(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.log("res and reult", res, result)
          if (res.length > 0) {
            this.rows = res;
            console.log(this.rows);
          }
        }
      })
    }

  }

  getS3Info() {
    return new Promise(resolve => {
      this.circularParentChildService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {

          resolve(false)
        }
      }, err => {

      });
    })
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_7211.11') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

          if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
            this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }else{
          console.log(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        };
      }
      console.log(this.roleAccess);
    })
  }
}
