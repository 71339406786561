<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2 mat-dialog-title cdkDragHandle>View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
</h2>
<mat-dialog-content class="mat-typography">
  <div class="container">
    <!-- row1 -->
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>NAMC:</label></div>
          <div class=col-8><input type="text" class="inputtext" [(ngModel)]="vNamc" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>eBoM to SAP Transmission Type:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vEbomToSAPTransmissionType" class="inputtext" disabled></div>
        </div>
      </div>
    </div>
    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Send To SAP Start Date:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vSendToSAPStartDate" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Send To SAP End Date:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vSendToSAPEndDate" class="inputtext" disabled></div>
        </div>
      </div>
    </div>
    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Send To SAP Start Time:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vSendToSAPStartTime" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Send To SAP End Time:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vSendToSAPEndTime" class="inputtext" disabled></div>
        </div>
      </div>
    </div>
    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"> <textarea class="w-100" [(ngModel)]="vComments"
          class="inputtext" rows="2" cols="72" disabled>
        </textarea>
      </div>

    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>

</mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
