import { templateJitUrl } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { VersionQualifierAddDialogService } from './version-qualifier-add-dialog.service';

@Component({
  selector: 'app-version-qualifier-add-dialog',
  templateUrl: './version-qualifier-add-dialog.component.html',
  styleUrls: ['./version-qualifier-add-dialog.component.css']
})
export class VersionQualifierAddDialogComponent implements OnInit {

  //errormessage
  errorMessage: string = "";

  //list
  verTypeLst = [];

  //ngModel
  addVerType: string;
  addVerQual: string
  addVerQualVal: any='ACT_AH';
  //response from backend after insert/update records
  response: any;
  EnableAddqualselect: boolean = false;
  //to main page
  message = "Record Added Successfully";

  spinnerMessage = "Please Wait...";

  constructor(public dialogRef: MatDialogRef<VersionQualifierAddDialogComponent>,
    private readonly SpinnerService: NgxSpinnerService,
    private verQualAddService: VersionQualifierAddDialogService) { }

  ngOnInit(): void {
    //vertype
    this.SpinnerService.show();
    this.verQualAddService.getVersionType().subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.verTypeLst = data.payload;
    });
  }

  //save
  submitVerQual(): void {
    let fileName= localStorage.getItem('Filename')
    if (!this.addVerType || !this.addVerQual) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else {
      let versionQual = ""
      let scenarioId = ''
      this.errorMessage = "";
      console.log(this.addVerQualVal)
      if (this.addVerType == "4") {
        if (this.addVerQualVal == 'ACT_AH') {
          versionQual = this.addVerQualVal + this.addVerQual;
          scenarioId = '2'
        } else if (this.addVerQualVal == 'STD_AH') {
          versionQual = this.addVerQualVal + this.addVerQual;
          scenarioId = '1'
        }
      } else {
        versionQual = this.addVerQual;
      }
      let body = {
        "vertyp": this.addVerType,
        "verQualCd": versionQual,
        "scenarioId": scenarioId,
        "Adhocflg":this.EnableAddqualselect,
        "screenNo":"NBPC_10105",
        "fileName" :fileName
      }
      this.SpinnerService.show();
      this.verQualAddService.ExistsVersionQual(body).subscribe(data => {
        this.response = data.payload;
        console.log(data);
        if (this.response[0].result == 'Data Already Exists') {
          this.errorMessage = "Same combination of Version Type and Version Qualifier exists already";
          this.SpinnerService.hide();
        } else if (this.response[0].result == 'success') {
          this.AddversionQual();
        } else if (this.response[0].result == 'Invalid') {
          this.errorMessage = "Version Type Invalid";
          this.SpinnerService.hide();
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }

  }
  AddversionQual() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let versionQual = ""
    this.errorMessage = "";
    let scenarioId = ''
    if (this.addVerType == "4") {
      if (this.addVerQualVal == 'ACT_AH') {
        versionQual = this.addVerQualVal + this.addVerQual;
        scenarioId = '2'
      } else if (this.addVerQualVal == 'STD_AH') {
        versionQual = this.addVerQualVal + this.addVerQual;
        scenarioId = '1'
      }
    } else {
      versionQual = this.addVerQual;
    }
    let body = {
      "vertyp": this.addVerType,
      "verqual": versionQual,
      "scenarioId": scenarioId,
      "Adhocflg": this.EnableAddqualselect,
      "screenNo":"NBPC_10105",
      "fileName" :fileName


    }
    this.SpinnerService.show();
    this.verQualAddService.addVersionQual(body).subscribe(data => {
      this.response = data.payload;
      console.log(data);
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.errorMessage = "Data not Added";
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }
  getVerQual() {
    var qualifierFmt = "";
    var curDate = new Date();

    var calYear = curDate.getFullYear();
    var calMonth = curDate.getMonth();
    var fiscalYr = calYear + 1;
    console.log(this.addVerType)
    if (this.addVerType == "2") {
      this.addVerQual = calYear + ("0" + (calMonth + 1)).slice(-2);
      this.EnableAddqualselect = false;
      console.log(this.addVerQual);
    } else if (this.addVerType == "4") {
      this.addVerQual = calYear.toString().substr(2, 2) + ("0" + (calMonth + 1)).slice(-2);
      this.EnableAddqualselect = true
      console.log(this.addVerQual);
    } else if (this.addVerType == "3") {
      if (calMonth < 6) {
        this.addVerQual = "FY" + calYear.toString().substr(2, 2) + "-" + 2;
        console.log(this.addVerQual);
        this.EnableAddqualselect = false;
      } else {
        this.addVerQual = "FY" + fiscalYr.toString().substr(2, 2) + "-" + 1;
        this.EnableAddqualselect = false;
      }
    } else if (this.addVerType == "1") {
      this.addVerQual = fiscalYr + "FY";
      console.log(this.addVerQual);
      this.EnableAddqualselect = false;
    }
    else {
      this.addVerQual = "";
      console.log(this.addVerQual);
      this.EnableAddqualselect = false;
    }
  }



  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }
  closeAlert() {
    this.errorMessage = '';
  }



}
