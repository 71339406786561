import { Component, OnInit } from '@angular/core';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-mng-data-trans-view-dialog',
  templateUrl: './mng-data-trans-view-dialog.component.html',
  styleUrls: ['./mng-data-trans-view-dialog.component.css']
})
export class MngDataTransViewDialogComponent implements OnInit {
  vNamc:any;
  vEbomToSAPTransmissionType:any;
  vSendToSAPStartDate:any;
  vSendToSAPEndDate:any;
  vSendToSAPStartTime:any;
  vSendToSAPEndTime:any;
  vComments:any;
  spinnerMessage="Please Wait...";
 

  constructor(public data: DialogData,private readonly SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.vNamc=this.data.namc;
    this.vEbomToSAPTransmissionType=this.data.ebomToSAPTransmissionType;
    this.vSendToSAPStartDate=this.data.sendToSAPStartDate;
    this.vSendToSAPStartTime=this.data.sendToSAPStartTime;
    this.vSendToSAPEndDate=this.data.sendToSAPEndDate;
    this.vSendToSAPEndTime=this.data.sendToSAPEndTime;
    this.vComments=this.data.comments;
    
  }


}
