import { Component, OnInit, Inject } from '@angular/core';
import { ExportToExcelService } from '../common/export-to-excel.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { HunderedSpecificationsEditDialogService } from './hundered-specifications-edit-dialog.service';
@Component({
  selector: 'app-hundered-specifications-edit-dialog',
  templateUrl: './hundered-specifications-edit-dialog.component.html',
  styleUrls: ['./hundered-specifications-edit-dialog.component.css']
})
export class HunderedSpecificationsEditDialogComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  [x: string]: any;
  //to main page
  message = "Records Edited Successfully";
  index: any;
  errorMessage = "";
  spinnerMessage = "Please Wait...";


  // filename 
  fileName: string = "Hundred-specifications"
  exportData: any = []
  sheetName = [];
  //hikiate
  hikiateLstFromDB: any = [];
  selectedHikiateLst: any = [];
  dummyHikiateLst: any;
  dummyHikiateLstToDB: any = [];
  editexcellst: any = [];
  // ngModelValues
  editnamc: any;
  editfamily: any;
  editkatashiki: any;
  editdestination: any;
  edithikiate: any;
  editpcSpec: any;
  edittimeInDt: any;
  edittimeOutDt: any;
  editcomments: any;
  editvdwEffIn: any;
  editvdwEffOut: any;
  edithundPercent: any;
  editreqd_100pct_pc_spec_optn_id: any;
  namc_id: any;
  constructor(private editHunPerService: HunderedSpecificationsEditDialogService, private readonly SpinnerService: NgxSpinnerService, public dialogRef: MatDialogRef<HunderedSpecificationsEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private exportService: ExportToExcelService) { }

  rows = [];

  async ngOnInit() {
    let a = await this.getNamc();
    if (a) {
      console.log(this.data);
      for (let i = 0; i < this.namcLst.length; i++) {
        if (this.data.namc == this.namcLst[i].namc_cd) {
          this.namc_id = this.namcLst[i].namc_lgl_entity_id
        }
      }
    }
    console.log(this.data)
    this.editnamc = this.data.namc;
    this.editfamily = this.data.family;
    this.editkatashiki = this.data.katashiki;
    this.editdestination = this.data.dest;
    this.editnamc_id = this.namc_id;
    if (this.data.hikiate != '' && this.data.hikiate != null) {
      this.dummyHikiateLst = this.data.hikiate.split(',');;
      this.selectedHikiateLst = this.data.hikiate.split(',');
    }
    // this.dummyHikiateLst = this.data.hikiate.split(',');//hiki
    // this.selectedHikiateLst = this.data.hikiate.split(',');
    console.log(this.selectedHikiateLst);
    this.editpcSpec = this.data.pcSpec;
    this.edittimeInDt = this.data.timeInDt;
    this.edittimeOutDt = this.data.timeOutDt;
    this.editcomments = this.data.comments;
    this.editvdwEffIn = this.data.vdwEffIn;
    this.editvdwEffOut = this.data.vdwEffOut;
    this.edithundPercent = this.data.hundPercent;
    this.editreqd_100pct_pc_spec_optn_id = this.data.reqd_100pct_pc_spec_optn_id;
    let d = await this.getHikiate();
    if (d) {
      let hikiatelst = this.hikiateLstFromDB
      console.log(hikiatelst)
      if (hikiatelst != [] && hikiatelst != undefined) {
        this.dummyHikiateLst.forEach(hiki => {
          if (hikiatelst != [] && hikiatelst != undefined) {
            for (let i = 0; i < hikiatelst.length; i++) {
              if (hiki == hikiatelst[i].hikiate_stat_cd) {
                hikiatelst.splice(i, 1);
              }
            }
            this.hikiateLstFromDB = hikiatelst;
          }

        });
        console.log(this.hikiateLstFromDB)
      }
    }
  }



  getNamc() {
    return new Promise(res => {
      this.editHunPerService.getNamc().subscribe(data => {
        console.log(data);
        this.namcLst = data.payload;
        res(true)
      });
    })
  }
  // Export to excel of common combinations
  exportFile(): void {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "namc": this.editnamc_id,
      "famCd": this.editfamily,
      "katashiki": this.editkatashiki,
      "dest": this.editdestination,
      "hikiate": this.selectedHikiateLst,
      "pcSpec": this.editreqd_100pct_pc_spec_optn_id,
      "inactv_flg": "",
      'screenNo':'NBPC_9178',
      'fileName': fileName

    }
    this.editHunPerService.update100SpecExcl(body).subscribe(data => {
      console.log(data);
      this.editexcellst = data.payload;
      this.editexcellst.forEach(row => {
        let obj = {
          "NAMC": row.namc_cd,
          "Family": row.fam_cd,
          "Katashiki": row.katashiki_cd,
          "Dest": row.dest_ctry_id,
          "Hikiate Status": row.hikiate_stat_comb_cd,
          "PC Spec": row.pc_spec_cd_comb_txt,
          "Time In Date": row.eff_dt,
          "Time Out Date": row.expr_dt,
          "VDW Effective In": row.vdw_eff_dt,
          "VDW Effective Out": row.vdw_expr_dt,
          "Comments": row.cmt_txt,
          "100% Spec Id": row.reqd_100pct_pc_spec_optn_id

        }
        this.exportData.push(obj);
      });

      this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    }, err => {
      this.errorMessage = "There is no records with the same combination"
    })
  }

  getHikiate() {
    if (this.editfamily == undefined) {
      this.editfamily = ''
    }

    this.SpinnerService.show();
    return new Promise(res => {
      this.editHunPerService.getHikiateStatusHps(this.editfamily).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.hikiateLstFromDB = data.payload;
        res(true)
      })
    });
  }
  //hikiate
  moveHikiForward(): void {
    let hikiatelst = this.hikiateLstFromDB
    console.log(this.dummyHikiateLst);
    this.dummyHikiateLst.forEach(hiki => {
      this.selectedHikiateLst.push(hiki);
      for (let i = 0; i < hikiatelst.length; i++) {
        console.log(i);
        console.log(hiki, hikiatelst[i].hikiate_stat_cd);
        console.log(hiki === hikiatelst[i].hikiate_stat_cd);
        console.log(i);
        if (hiki == hikiatelst[i].hikiate_stat_cd) {
          hikiatelst.splice(i, 1);
        }
      }
    });
    this.hikiateLstFromDB = hikiatelst;
    console.log(this.hikiateLstFromDB);
    console.log(this.selectedHikiateLst);
    this.dummyHikiateLst = ""
  }


  moveHikiBackward(): void {
    this.dummyHikiateLstToDB.forEach(hiki => {
      let body = {
        "hikiate_stat_cd": hiki
      }
      this.hikiateLstFromDB.push(body);
      this.index = this.selectedHikiateLst.indexOf(hiki);
      this.selectedHikiateLst.splice(this.index, 1);
    });
    console.log(this.hikiateLstFromDB)
    console.log(this.selectedHikiateLst);
    if (this.dummyHikiateLstToDB.length >= 1) {
      this.hikiateLstFromDB = this.hikiateLstFromDB.sort((a, b) => a.hikiate_stat_cd > b.hikiate_stat_cd ? 1 : -1);
      this.dummyHikiateLstToDB = [];
    }
  }



  //closealert
  closeAlert() {
    this.errorMessage = "";
  }



  editHundPer() {
    let fileName= localStorage.getItem('Filename')
    console.log(this.dummyHikiateLstToDB);

    if (!this.edittimeInDt) {

      this.errorMessage = "Please enter all mandatory field";

    }
    else if (this.edittimeInDt && this.edittimeOutDt &&
      this.pipe.transform(this.edittimeInDt, 'yyyy-MM-dd') > this.pipe.transform(this.edittimeOutDt, 'yyyy-MM-dd')) {

      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";

    }
    else {
      let hikiatelstArray: any;
      hikiatelstArray = this.dummyHikiateLstToDB;
      console.log(hikiatelstArray.length, hikiatelstArray);
      let str = '';
      for (let i = 0; i < hikiatelstArray.length; i++) {
        if (str == '') {
          str = hikiatelstArray[i]
        } else {
          str += ',' + hikiatelstArray[i]
        }
        this.hikiatevalue = str;
      }

      if (this.edittimeOutDt == "") {
        this.edittimeOutDt = "9999-12-31"
      }
      let effInformat = ''; let effOutFormat = ""
      if (this.edittimeInDt != "") {
        let d1 = moment(this.edittimeInDt).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        effInformat = moment(date).format('YYYY-MM-DD')
        this.edittimeInDt = date
      }
      if (this.edittimeOutDt != "") {
        let d1 = moment(this.edittimeOutDt).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        effOutFormat = moment(date).format('YYYY-MM-DD')
        this.edittimeOutDt = date
        // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
      }

      let user = localStorage.getItem('workDayId');
      this.errorMessage = "";
      let cmts;
    if (this.editcomments!= undefined) {
      cmts = this.editcomments.replaceAll("'", "''");
    } else {
      cmts = this.editcomments
    }
      let body = {
        'effIn': effInformat,
        'exprOut': effOutFormat,
        'hikistat': this.selectedHikiateLst,
        'comment': cmts,
        'req100Pcid': this.editreqd_100pct_pc_spec_optn_id,
        'screenNo':'NBPC_9178',
        'fileName': fileName
        
        
      }
      this.SpinnerService.show();
      this.editHunPerService.editHunPer(body).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });

    }
  }
  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'edittimeInDt') {
      this.edittimeInDt = "";
    } else if (name == 'edittimeOutDt') {
      this.edittimeOutDt = "";
    }
  }
}
