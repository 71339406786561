<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

<div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
  {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>
<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

      <mat-expansion-panel-header class="mat-bg-color">
        <mat-panel-title>
          <strong>Search</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>Version Type</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="verType" name="verType"
          (selectionChange)='getDescByTyp()'>
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let verType of verTypeLst" [value]="verType.bom_ver_typ_id">{{verType.bom_ver_typ_cd}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Verison Type Description</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="verTypeDesc" name="verTypeDesc">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let verTypeDesc of verTypeDescLst" [value]="verTypeDesc.bom_ver_typ_desc">
          {{verTypeDesc.bom_ver_typ_desc}}</mat-option>
      </mat-select>
    </mat-form-field>



    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>

  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex">
  <button mat-raised-button (click)="openAddDialog()"> Add </button>
  <button mat-raised-button (click)="openDeleteDialog()">Delete </button>
</div>

  <!-- <mat-card> -->
  <div class="row">
    <div class="col-sm-12 col-md-8 col-lg-7 col-xl-7">
      <ngx-datatable #verTypTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force"
        [headerHeight]="45" [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
        [selectionType]="SelectionType.single" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
        (activate)="onActivate($event)" (select)="onSelect($event)" [selectCheck]="singleSelectCheck"
        [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">
        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
            <div class="page-count">
              <!-- {{selectedCount}} selected / {{recordcount}} total -->
            </div>
            <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
              (change)="table.onFooterPage($event)">
            </datatable-pager>
            <div class="test">
              <select [(ngModel)]="limitSelected">
                <option>50</option>
                <option>100</option>
                <option>500</option>
              </select>
              <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
                (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
                {{(rowCount)}}

              </div>
            </div>
          </ng-template>
        </ngx-datatable-footer>
        <ngx-datatable-column [width]="65" [sortable]="false" [canAutoResize]="false" [draggable]="false"
          [resizeable]="false" [headerCheckboxable]="false" [checkboxable]="true" name="Select"></ngx-datatable-column>

        <ngx-datatable-column class="w-50" name="Version Type" prop="bom_ver_typ_cd"></ngx-datatable-column>
        <ngx-datatable-column name="Version Type Description" prop="bom_ver_typ_desc"></ngx-datatable-column>
      </ngx-datatable>
      <div class="col-5">
      </div>
    </div>
  </div>
  </span>
  <div *ngIf="!accessEnable">
    <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">{{spinnerMessage}} </p>
  </ngx-spinner>