import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";
import { MajorPartTypeEditDialogService } from './major-part-type-edit-dialog.service';

@Component({
  selector: 'app-major-part-type-edit-dialog',
  templateUrl: './major-part-type-edit-dialog.component.html',
  styleUrls: ['./major-part-type-edit-dialog.component.css']
})
export class MajorPartTypeEditDialogComponent implements OnInit {

  //errormessage
  errorMessage: string = "";

  //ngModel
  majorPartEditPartType: any;
  majorPartEditProdType: any;
  majorPartEditActive: any;
  majorPartEditComments: any;
  majorPartEditPartTypeId: any;
  //to main page
  message = "Record Updated Successfully";

  spinnerMessage = "Please Wait...";

  //response from backend after insert/update records
  response: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<MajorPartTypeEditDialogComponent>, private readonly SpinnerService: NgxSpinnerService,
    private majPartTypEdit: MajorPartTypeEditDialogService) { }

  ngOnInit(): void {
    console.log("data", this.data);
    if (this.data.majorPartStatus == 'Inactive') {
      this.majorPartEditActive = false
    }
    if (this.data.majorPartStatus == 'Active') {
      this.majorPartEditActive = true
    }
    this.majorPartEditPartType = this.data.majorPartType;
    this.majorPartEditProdType = this.data.majorPartProdType;
    this.majorPartEditComments = this.data.majorPartComments;
    this.majorPartEditPartTypeId = this.data.majorPartTypId;
  }
 
  //save
  updatecheck() {
    let fileName= localStorage.getItem('Filename')
    this.errorMessage = "";
    if (this.majorPartEditActive == false) {
      let body = {
        'MajPrtTypId': this.majorPartEditPartTypeId,
        "screenNo":"NBPC_2221",
        "fileName" :fileName
      }
      this.SpinnerService.show();
      this.majPartTypEdit.updatecheckFlg(body).subscribe(data => {
        console.log(data);
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.updateMajPart();
        } else if (this.response[0].result == 'fail') {
          this.SpinnerService.hide();
          this.errorMessage = "Major Part Type <" + this.majorPartEditPartType + " > can not be inactive, as Major Parts exist for this type"
        }
      }, err => {
        this.SpinnerService.hide();
      });
    } else {
      this.updateMajPart();
    }
  }
  updateMajPart(): void {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    this.errorMessage = "";
    let cmts;
    if (this.majorPartEditComments!= undefined) {
      cmts = this.majorPartEditComments.replaceAll("'", "''");
    } else {
      cmts = this.majorPartEditComments
    }
    let body = {
      'active': this.majorPartEditActive == false ? 'Y' : 'N',
      'comments': cmts,
      'MajPrtTypId': this.majorPartEditPartTypeId,
      "screenNo":"NBPC_2221",
      "fileName" :fileName
    }
    this.SpinnerService.show();
    this.majPartTypEdit.updateMajPartTyp(body).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else if (this.response[0].result == 'AlreadyExists') {
        this.errorMessage = 'Data Entered already Exists!';
      }
    },err=>{ 
      this.SpinnerService.hide();
    });

  }

  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  closeAlert() {
    this.errorMessage = '';
  }

}
