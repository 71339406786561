import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogData } from '../dialog-data/dialog-data';
import { IsSystemParameterEditDialogService } from './is-system-parameter-edit-dialog.service';

@Component({
  selector: 'app-is-system-parameter-edit-dialog',
  templateUrl: './is-system-parameter-edit-dialog.component.html',
  styleUrls: ['./is-system-parameter-edit-dialog.component.css']
})
export class IsSystemParameterEditDialogComponent implements OnInit {
  [x: string]: any;
  //to main page
  message = "Records Edited Successfully";
  index: any;
  errorMessage = "";

  //ngModelValues
  eParameterName: any;
  eParameterValue: any;
  //respone after inserting a record
  response: any;

  spinnerMessage = "Please Wait";
  constructor(private IsSystemParameterEditDialogService: IsSystemParameterEditDialogService, public dialogRef: MatDialogRef<IsSystemParameterEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    console.log(this.data)
    this.eParameterName=this.data.job;
    this.eParameterValue=this.data.processingState;
  }
    //closealert
closeAlert() {
  this.errorMessage = "";
}
  uploadParm() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    console.log(this.eParameterValue);
  
    if (!this.eParameterValue) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else {
      this.errorMessage = "";
      let body = {
        'parm_val_txt': this.eParameterValue,
        'parm_nm': this.eParameterName,
        "screenNo":"NBPC_9994",
        "fileName" :fileName
       
      }
      this.SpinnerService.show();
      this.IsSystemParameterEditDialogService.editIsSysParm(body).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });

  }
  
}