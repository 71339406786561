import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogData } from '../dialog-data/dialog-data';
import { RepUnitsMultieditDialogService } from './rep-units-multiedit-dialog.service';
@Component({
  selector: 'app-rep-units-multiedit-dialog',
  templateUrl: './rep-units-multiedit-dialog.component.html',
  styleUrls: ['./rep-units-multiedit-dialog.component.css']
})
export class RepUnitsMultieditDialogComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //errormessage
  errorMessage: string = "";
  response: any
  //ngModel
  emComments: any = "";
  emMonthlyRep: any = "";
  emExtractionTimeIn: any = "";
  emExtractionTimeOut: any = "";
  erepproddefid: any = "";
  //to main page
  message = "Record Updated Successfully";

  spinnerMessage = "Please Wait...";

  constructor(public dialogRef: MatDialogRef<RepUnitsMultieditDialogComponent>, private readonly SpinnerService: NgxSpinnerService, @Inject(MAT_DIALOG_DATA) public data: DialogData, public repMultiEditService: RepUnitsMultieditDialogService) { }

  ngOnInit(): void {
    console.log(this.data)
    let arrayvalue: any;
    arrayvalue = this.data;
    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = "'" + arrayvalue[i].rep_prod_def_id + "'"
      } else {
        str += ',' + "'" + arrayvalue[i].rep_prod_def_id + "'";
      }

      this.erepproddefid = str;
      console.log(this.erepproddefid)
    }
  }

  updateMultiRepUnits() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    console.log(this.emExtractionTimeIn);

    if (!this.emExtractionTimeIn || !this.emExtractionTimeOut) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.emExtractionTimeIn && this.emExtractionTimeOut &&
      this.pipe.transform(this.emExtractionTimeIn, 'yyyy-MM-dd') > this.pipe.transform(this.emExtractionTimeOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    }
    else {
      this.errorMessage = "";
      let indateFormat = ''; let outdateFormat = '';
      if (this.emExtractionTimeIn != "") {
        let d1 = moment(this.emExtractionTimeIn).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.emExtractionTimeIn = date
      }
      if (this.emExtractionTimeOut != "") {
        let d1 = moment(this.emExtractionTimeOut).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.emExtractionTimeOut = date
      }
      console.log(this.emMonthlyRep)
      let datamonthlyrep;
      if (this.emMonthlyRep == true) {
        this.emMonthlyRep =true
        datamonthlyrep= 'Y';
      } else if (this.emMonthlyRep != true) {
        this.emMonthlyRep =false
        datamonthlyrep = 'N';
      }

      console.log(this.emMonthlyRep)
      let cmts;
      if (this.emComments != undefined) {
        cmts = this.emComments.replaceAll("'", "''");
      } else {
        cmts = this.emComments
      }
      let body = {
        "monRepFlg":datamonthlyrep,
        "extTimeIn": indateFormat,
        "extTimeOut": outdateFormat,
        "comment": cmts,
        "repProdDefid": this.erepproddefid,
        "screenNo":"NBPC_9355",
        "fileName" :fileName
        
      }
      this.SpinnerService.show();
      this.repMultiEditService.updateMultiRepUnit(body).subscribe(data => {
        this.response = data.payload;
        this.SpinnerService.hide();
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          // this.dialogRef.close(this.response[0].result);
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }
  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'emExtractionTimeIn') {
      this.emExtractionTimeIn = "";
    } else if (name == 'emExtractionTimeOut') {
      this.emExtractionTimeOut = "";
    }
  }

  closeAlert(): void {
    this.errorMessage = '';
  }

}
