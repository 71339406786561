import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";
import { ManualAdjustmentRuleCorrectBomService } from './manual-adjustment-rule-correct-bom.service';

@Component({
  selector: 'app-manual-adjustment-rule-correct-boms',
  templateUrl: './manual-adjustment-rule-correct-boms.component.html',
  styleUrls: ['./manual-adjustment-rule-correct-boms.component.css']
})
export class ManualAdjustmentRuleCorrectBomsComponent implements OnInit {
  response: any;
  total: any;
  TotalActuals: any;
  TotalRep: any
  hide: boolean;
  show: boolean;
  constructor(private readonly SpinnerService: NgxSpinnerService, @Inject(MAT_DIALOG_DATA) public data: DialogData, private MalAdjRuleCrtBomService: ManualAdjustmentRuleCorrectBomService, public dialogRef: MatDialogRef<ManualAdjustmentRuleCorrectBomsComponent>) { }

  correctBomLst: any = "";
  errormessage: any = ""
  manadjid: any
  spinnerMessage = "Please Wait...";
  displaydata: any = [];
  Vinaffected: any;
  BOMaffected: any;
  totalaffected: any;

  ngOnInit(): void {
    this.SpinnerService.show();
    console.log(this.data)
    this.manadjid = this.data
    let arrayvalue: any;
    arrayvalue = this.data;
    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = arrayvalue[i].man_adjstmnt_rule_def_id
      } else {
        str += ',' + arrayvalue[i].man_adjstmnt_rule_def_id;
      }
      this.correctBomLst = str;
    }
    console.log(this.correctBomLst);
    this.FetchtruncTempRepProd();
  }

  FetchtruncTempRepProd() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "table_name": 'EBOM_ADM.TMP_CORRECT_BOMS',
      "screenNo":"NBPC_10122",
          "fileName" :fileName
    }

    this.MalAdjRuleCrtBomService.truncTempRepProd(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.FetchCorrectBomsIds()
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
  FetchCorrectBomsIds() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "manAdjstmntRuleDefId": this.correctBomLst,
      "screenNo":"NBPC_10122",
          "fileName" :fileName

    }
    this.MalAdjRuleCrtBomService.insertCorrectBomsIds(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].response == 'success') {
        this.updatetProcProcessStatus();
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].response);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
  updatetProcProcessStatus() {
    let fileName= localStorage.getItem('Filename')
    let mail = localStorage.getItem('emailId');
    let user = localStorage.getItem('workDayId');
    let body = {
      'mailId': mail,
      
      'proc_nm': 'CORRECT_BOMS',
      'proc_status': 'INITIATED',
      "screenNo":"NBPC_10122",
          "fileName" :fileName
    }
    this.MalAdjRuleCrtBomService.editProcProcessStatus(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.updatestartedfn();
        // this.FetchSpCall();
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  FetchSpCall() {
    let data = 'EBOM_ADM.SP_EBOM_CORRECT_BOMS'
    this.MalAdjRuleCrtBomService.getSpCall(data).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      this.FetchProcStatus();
    }, err => {
      this.FetchProcStatus();
      this.dialogRef.close("Function Failed");
    });
  }

  updatestartedfn() {
    let mail = localStorage.getItem('emailId');
    let user = localStorage.getItem('workDayId');
    let fileName= localStorage.getItem('Filename')
    let body = {
      'mailId': mail,
      
      'proc_nm': 'CORRECT_BOMS',
      'proc_status': 'STARTED',
      "screenNo":"NBPC_10122",
      "fileName" :fileName
    }
    this.MalAdjRuleCrtBomService.editProcProcessStatus(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.getprocstatus();
      } else {
        this.SpinnerService.hide();
        // this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
  getprocstatus(){
    let fileName= localStorage.getItem('Filename')
    let body ={
      'proc_nm': 'CORRECT_BOMS',
      "screenNo":"NBPC_10122",
          "fileName" :fileName
    }
    this.MalAdjRuleCrtBomService.getProcStatus(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].proc_status == 'COMPLETED') {
        // if (this.data.buttonName == 'addtoversion') {
        //   this.addversionProcApi();
        // } else {
        this.FetchProcStatus();
        // }
      } else if (this.response[0].proc_status == 'INITIATED' || this.response[0].proc_status == 'INPROGRESS' || this.response[0].proc_status == 'IN_PROGRESS' || this.response[0].proc_status == 'STARTED') {
        this.getprocstatus();
      }
      else if (this.response[0].proc_status == 'ERROR') {
        this.SpinnerService.hide()
        this.dialogRef.close("Error returned in Procedure");
        console.log("error returned in procedure");
      }
      else {

      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
  FetchProcStatus() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "manAdjstmntRuleDefId": this.correctBomLst,
      "screenNo":"NBPC_10122",
      "fileName" :fileName
    }
    this.MalAdjRuleCrtBomService.returnMessageCorrectBoms(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].err_msg == 'SUCCESS') {
        this.fetchBomImpactCountRep();
        this.dataTotalCountOfProdBomsRep();
      } else {
        this.errormessage = ""
        this.SpinnerService.hide();
        if (this.response[0].err_msg != null) {
          this.dialogRef.close(this.response[0].err_msg);
        } else {
          this.dialogRef.close('');
        }
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  // FetchManAdjstDefId() {
  //   let body = {
  //     "man_adjstmnt_rule_def_id": this.correctBomLst
  //   }
  //   this.MalAdjRuleCrtBomService.getManAdjstDefId(body).subscribe(data => {
  //     console.log(data);
  //     this.response = data.payload;
  //     if (this.response[0].err_msg == 'SUCCESS') {
  //       this.fetchBomImpactCountRep();
  //     } else {
  //       this.errormessage = ""
  //     }
  //   }, err => {
  //     this.SpinnerService.hide();
  //     this.dialogRef.close("Function Failed");
  //   });
  // }
  fetchBomImpactCountRep() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "man_adjstmnt_rule_def_id": this.correctBomLst,
      "screenNo":"NBPC_10122",
          "fileName" :fileName
    }
    this.MalAdjRuleCrtBomService.getBomImpactCountRep(body).subscribe(data => {
      console.log(data);
      // this.BOMaffected = data.payload[0].count;
      this.FetchBomImpactCountProd();
      if (data.payload[0].result == "No Records") {
        this.hide = true;
        let data1 = this.correctBomLst.split(',')
        console.log(data1);
        let src = {
          man_adjstmnt_rule_def_id: data1,
        };
        let result = Object.keys(src).reduce(
          (acc, curr) => [...acc, ...src[curr].map((val) => ({ [curr]: val }))],
          []
        );
        console.log(result);
        let a = result.map(el => ({
          ...el,
          count: 'NA'
        }))
        console.log(a);
        this.totalaffected = a.map(el => ({
          ...el,
          count: a.find(o => o.man_adjstmnt_rule_def_id === el.man_adjstmnt_rule_def_id)?.count || 'NA'
        }))
        console.log(this.totalaffected)
      } else {
        this.BOMaffected = data.payload;
        console.log(this.BOMaffected)
        this.hide = false;
      }
    }, err => {
      this.SpinnerService.hide();

      // this.FetchBomImpactCountProd();
      // this.dialogRef.close("Function Failed");
    });
  }
  FetchBomImpactCountProd() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "man_adjstmnt_rule_def_id": this.correctBomLst,
      "screenNo":"NBPC_10122",
          "fileName" :fileName
    }
    this.MalAdjRuleCrtBomService.getBomImpactCountProd(body).subscribe(data => {
      console.log(data);

      if (data.payload[0].result == "No Records") {
        this.show = true;
        console.log(this.show)
        // let withoutQuotes = this.correctBomLst.replaceAll('"', '');
        let data1 = this.correctBomLst.split(',')
        console.log(data1);
        let src = {
          man_adjstmnt_rule_def_id: data1,
        };
        let result = Object.keys(src).reduce(
          (acc, curr) => [...acc, ...src[curr].map((val) => ({ [curr]: val }))],
          []
        );
        console.log(result);
        let a = result.map(el => ({
          ...el,
          count: 'NA'
        }))
        console.log(a);
        if (this.hide == false) {
          this.totalaffected = this.BOMaffected.map(el => ({
            ...el,
            vincount: a.find(o => o.man_adjstmnt_rule_def_id === el.man_adjstmnt_rule_def_id)?.count || 'NA'
          }))
        } else {
          this.totalaffected = a.map(el => ({
            ...el,
            vincount: a.find(o => o.man_adjstmnt_rule_def_id === el.man_adjstmnt_rule_def_id)?.count || 'NA'
          }))
        }
        console.log(this.totalaffected);
        this.SpinnerService.hide();
      } else {
        // this.Vinaffected = data.payload
        this.Vinaffected = data.payload
        this.totalaffected = this.BOMaffected.map(el => ({
          ...el,
          vincount: this.Vinaffected.find(o => o.man_adjstmnt_rule_def_id === el.man_adjstmnt_rule_def_id)?.count || 'NA'
        }))
        console.log(this.totalaffected)
        this.SpinnerService.hide();
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
  dataTotalCountOfProdBomsRep() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "man_adjstmnt_rule_def_id": this.correctBomLst,
      "screenNo":"NBPC_10122",
          "fileName" :fileName
    }
    this.MalAdjRuleCrtBomService.getTotalCountOfProdBomsRep(body).subscribe(data => {
      console.log(data);
      this.TotalRep = data.payload[0].count;
      this.dataTotalCountOfProdBomsActuals();
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
      // this.FetchBomImpactCountProd();
      // this.dialogRef.close("Function Failed");
    });
  }
  dataTotalCountOfProdBomsActuals() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "man_adjstmnt_rule_def_id": this.correctBomLst,
      "screenNo":"NBPC_10122",
          "fileName" :fileName
    }
    this.MalAdjRuleCrtBomService.getTotalCountOfProdBomsActuals(body).subscribe(data => {
      console.log(data);
      this.TotalActuals = data.payload[0].count;
      this.total = Number(this.TotalRep) + Number(this.TotalActuals)
      console.log(this.total)
      this.SpinnerService.hide();
    }, err => {
      this.SpinnerService.hide();
      // this.FetchBomImpactCountProd();
      // this.dialogRef.close("Function Failed");
    });
  }

  EditManAdjCorrectBoms() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId')
    let text = "Do you want to trigger the Batch Job Now?";
    if (confirm(text) == true) {
      let body = {
        "manAdjstmntRuleDefId": this.correctBomLst,
        "screenNo":"NBPC_10122",
          "fileName" :fileName
      }
      this.MalAdjRuleCrtBomService.updateManAdjCorrectBoms(body).subscribe(data => {
        console.log(data);
        this.response = data.payload;
        if (this.response[0].response == 'records_already_processed') {
          this.EditSysParamCorrectBoms();
        } else {
          this.SpinnerService.hide();
          this.dialogRef.close(this.response[0].response);
        }
      }, err => {
        this.SpinnerService.hide();
        this.dialogRef.close("Function Failed");
      });
    }
    else {
      console.log("cancel")
      this.dialogRef.close("");
    }
  }
  EditSysParamCorrectBoms() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId')
    let body = {
      "manAdjstmntRuleDefId": this.correctBomLst,
      "screenNo":"NBPC_10122",
      "fileName" :fileName
    }
    this.MalAdjRuleCrtBomService.updateSysParamCorrectBoms(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'records_already_processed') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
}
