import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { HikiateStatusAddDialogService } from './hikiate-status-add-dialog.service';


@Component({
  selector: 'app-hikiate-status-add-dialog',
  templateUrl: './hikiate-status-add-dialog.component.html',
  styleUrls: ['./hikiate-status-add-dialog.component.css']
})
export class HikiateStatusAddDialogComponent implements OnInit {
  [x: string]: any;
  //to main page
  message = "Records updated successfully";
  errorMessage = "";
  successMessage = "";

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');
  //ngModelValues
  eNAMC: any = '';
  eFamily: any = '';
  eHikiateStatus: any = '';
  eHikiateStatusDescription: any = '';
  eEffectiveInDate: any = '';
  eEffectiveOutDate: any = '';
  spinnerMessage = "Please Wait...";
  //respone after inserting a record
  response: any = '';
  namcArray: any = [];
  familyArray: any = [];

  constructor(public dialogRef: MatDialogRef<HikiateStatusAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private hikstatusaddDialogService: HikiateStatusAddDialogService,
    private readonly SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    console.log("data inside add", this.data);

    this.getNamcData();
    
  }

  getNamcData() {
    this.SpinnerService.show();
    this.hikstatusaddDialogService.getNamcdropdown().subscribe(response => {
      console.log("res from getnamc", response);
      this.SpinnerService.hide();
      this.namcArray = response.payload
    })
  }
  getFamilyData() {
    if (this.eNAMC == undefined) {
      this.eNAMC = ""
    }
    console.log("datad")
    if (this.eNAMC != "") { 
     this.SpinnerService.show();
      this.hikstatusaddDialogService.getFamilydropdown(this.eNAMC).subscribe(response => {
        console.log("res from getfamily", response);
        this.SpinnerService.hide();
        this.eFamily = "";
        this.familyArray = response.payload
      })
    }
    else {
      this.eFamily = ""
      this.familyArray = []
    }
  }



  closeAlert() {
    this.errorMessage = "";
    this.successMessage = "";
  }

  save() {
    let fileName= localStorage.getItem('Filename')
    console.log(this.eNAMC, this.eFamily, this.eHikiateStatus, this.eHikiateStatusDescription, this.eEffectiveInDate, this.eEffectiveOutDate);
    if (!this.eNAMC || !this.eFamily || !this.eHikiateStatus
      || !this.eHikiateStatusDescription || !this.eEffectiveInDate || !this.eEffectiveOutDate) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.eEffectiveInDate && this.eEffectiveOutDate && this.pipe.transform(this.eEffectiveInDate, 'yyyy-MM-dd') > this.pipe.transform(this.eEffectiveOutDate, 'yyyy-MM-dd')) {

      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
    }
    else {
      this.errorMessage = "";
      let body = {
        'namc': this.eNAMC,
        'fam': this.eFamily,
        'hikist': this.eHikiateStatus,
        "screenNo":"NBPC_9996",
        "fileName" :fileName
      }
      this.SpinnerService.show();
      this.hikstatusaddDialogService.ExitsCheckHikiateStatus(body).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'Data Already Exists') {
          this.errorMessage = "Data Already Exists";
          this.SpinnerService.hide();
        } else if (this.response[0].result == 'success') {
          this.AddHikiate();
        } else if (this.response[0].result == 'Invalid') {
          this.errorMessage = "Data Invalid";
          this.SpinnerService.hide();
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }

  AddHikiate() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let indateFormat = ''; let outdateFormat = '';
    if (this.eEffectiveInDate != "") {
      let d1 = moment(this.eEffectiveInDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.eEffectiveInDate = date
    }
    if (this.eEffectiveOutDate != "") {
      let d1 = moment(this.eEffectiveOutDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.eEffectiveOutDate = date
      // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
    }
    let cmts;
    if (this.eHikiateStatusDescription!= undefined) {
      cmts = this.eHikiateStatusDescription.replaceAll("'", "''");
    } else {
      cmts = this.eHikiateStatusDescription
    }
    let body = {
      'namc': this.eNAMC,
      'fam': this.eFamily,
      'hikist': this.eHikiateStatus,
      'hikistDesc': cmts,
      'effDateIn': indateFormat,
      'effDateOut': outdateFormat,      
      "screenNo":"NBPC_9996",
      "fileName" :fileName      
    }

    this.SpinnerService.show();
    this.hikstatusaddDialogService.addHikiateStatus(body).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.errorMessage = "Data not Added";
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }


  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'eEffectiveInDate') {
      this.eEffectiveInDate = "";
    } else if (name == 'eEffectiveOutDate') {
      this.eEffectiveOutDate = "";
    }
  }
}
