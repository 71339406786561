import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as $ from 'jquery';
import * as moment from 'moment';
import { RepVehAddDialogServiceService } from './rep-veh-add-dialog-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { data } from 'jquery';


@Component({
  selector: 'app-rep-vehicle-add-dialog',
  templateUrl: './rep-vehicle-add-dialog.component.html',
  styleUrls: ['./rep-vehicle-add-dialog.component.css'],

})
export class RepVehicleAddDialogComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  index: any;

  //errormessage
  errorMessage: string = "";

  //ngModel
  aMnthlyRep: any = "";
  aCompBom: any = "";
  aMdlNo: any = "";
  aOptnCd: any = "";
  aSapMatId: any = "";
  aNamc: any = "";
  aFamily: any = "";
  aKatashiki: any = "";
  aCust: any = "";
  aDest: any = "";
  aSP: any = "";
  CompPcSpecvalue: any = "";
  PcSpecvalue: any = "";
  hikiatevalue: any = "";
  //hikiate
  hikiateLstFromDB: any = [];
  selectedHikiateLst: any = [];
  dummyHikiateLst: any;
  dummyHikiateLstToDB: any = [];
  //pc spec
  pcSpecLstFromDB: any = [];
  selectedPcSpecLst: any = [];
  dummyPcSpecLst: any = [];
  dummyPcSpecLstToDB: any = [];
  netSpecData: any = []
  checkPcSpecLst: any = []
  //compPcSpec
  compPcSpecLstFromDB: any = [];
  selectedCompPcSpecLst: any = [];
  dummyCompPcSpecLst: any;
  dummyCompPcSpecLstToDB: any = [];

  aNetSpec: any = "";
  aHundPerSpec: any = "";
  aCompHundPerSPec: any = "";
  aEFfDtIn: any = "";
  aExprDt: any = "";
  aComments: any = "";

  //lst
  namcLst = [];
  famcdLst = [];
  katashikiLst = [];
  customerLst = [];
  destLst = [];

  //response from backend after insert/update records
  response: any;

  //to main page
  message = "Record Added Successfully";

  spinnerMessage = "Please wait...";
  hidedata: any = "false";


  constructor(public dialogRef: MatDialogRef<RepVehicleAddDialogComponent>,
    private repVehAddService: RepVehAddDialogServiceService, private readonly SpinnerService: NgxSpinnerService) { }

  ngOnInit() {

    this.SpinnerService.show();
    //to get namc
    this.repVehAddService.getNamc().subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.namcLst = data.payload;
    });
    

    // to get dest
    this.getDestByCust();

    

    // to get customer
    // this.getCust();

    this.repVehAddService.getCust().subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.customerLst = data.payload;
    });

  }
  getpcspecByFamily() {
    // to get pcSpec
    if (this.aFamily == "") {
      this.pcSpecLstFromDB = []
    } else {
      this.repVehAddService.getPcSPec(this.aFamily).subscribe(data => {
        console.log(data);
        this.pcSpecLstFromDB = data.payload
        this.selectedPcSpecLst = [];
      });
    }
  }

  getcomppcspecByFamily() {
    // to get pcSpec
    if (this.aFamily == "") {
      this.compPcSpecLstFromDB = []
    } else {
      this.repVehAddService.getPcSPec(this.aFamily).subscribe(data => {
        console.log(data);
        this.compPcSpecLstFromDB = data.payload;
        this.selectedCompPcSpecLst = []
      });
    }
  }
  //onchange
  getFamCdByNamc() {
    console.log(this.aNamc)
    if (this.aNamc == undefined) {
      this.aNamc = ""
    }
    if (this.aNamc == "") {
      this.famcdLst = []
      this.katashikiLst = []
      this.hikiateLstFromDB = []
      this.pcSpecLstFromDB = []
      this.compPcSpecLstFromDB = []
    } else {
      this.SpinnerService.show();
      this.repVehAddService.getFamily(this.aNamc).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.famcdLst = data.payload;
        this.aFamily = "";
        this.aKatashiki = "";
        this.hikiateLstFromDB = []
        this.pcSpecLstFromDB = []
        this.compPcSpecLstFromDB = []
        this.dummyHikiateLst = "";
        this.dummyCompPcSpecLst = "";
        this.dummyPcSpecLst = "";
        this.selectedHikiateLst = [];
        this.selectedPcSpecLst = [];
        this.selectedCompPcSpecLst = [];
      });
    }
  }
  getKatashikiByFamCd() {
    if (this.aFamily == undefined) {
      this.aFamily = ""
    }
    this.SpinnerService.show();
    this.repVehAddService.getKatashiki(this.aFamily).subscribe(data => {
      this.SpinnerService.hide();
      this.katashikiLst = data.payload;
      this.aKatashiki = ""
      this.dummyHikiateLst = "";
      this.dummyCompPcSpecLst = "";
      this.dummyPcSpecLst = "";
      this.selectedHikiateLst = [];
      this.selectedPcSpecLst = [];
      this.selectedCompPcSpecLst = [];
    });
    // this.getpcspecByFamily();
    // this.getcomppcspecByFamily();
  }

  getCustByKatashikionload() {
    if (this.aKatashiki == undefined) {
      this.aKatashiki = ""
    }
    if (this.aFamily == undefined) {
      this.aFamily = ""
    }
    if (this.aNamc == undefined) {
      this.aNamc = ""
    }
    this.SpinnerService.show();
    this.repVehAddService.getCustomer(this.aNamc, this.aFamily, this.aKatashiki).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.customerLst = data.payload;
      this.aCust = ""
    });
  }

  getCustByKatashiki() {
    if (this.aKatashiki == undefined) {
      this.aKatashiki = ""
    }
    if (this.aFamily == undefined) {
      this.aFamily = ""
    }
    if (this.aNamc == undefined) {
      this.aNamc = ""
    }
    if (this.aKatashiki == "") {
      this.customerLst = []
    } else {
      this.SpinnerService.show();
      this.repVehAddService.getCustomer(this.aNamc, this.aFamily, this.aKatashiki).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.customerLst = data.payload;
        this.aCust = ""
      });
    }
  }

  getDestByCust() {
    if (this.aKatashiki == undefined) {
      this.aKatashiki = ""
    }
    if (this.aFamily == undefined) {
      this.aFamily = ""
    }
    if (this.aNamc == undefined) {
      this.aNamc = ""
    }
    if (this.aCust == undefined) {
      this.aCust = ""
    }
    this.SpinnerService.show();
    this.repVehAddService.getDest(this.aNamc, this.aFamily, this.aKatashiki, this.aCust).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.destLst = data.payload;
      this.aDest = ""
    });
  }

  getHikiateByDest() {
    if (this.aFamily == undefined) {
      this.aFamily = ""
    }
    if (this.aNamc == undefined) {
      this.aNamc = ""
    }
    if (this.aFamily == "") {
      this.hikiateLstFromDB = []
    } else {
      this.SpinnerService.show();
      this.repVehAddService.getHikiate(this.aNamc, this.aFamily).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.hikiateLstFromDB = data.payload;
        this.hikiatevalue = ""
      });
    }
  }



  //hikiate

  moveHikiForward() {

    let hikiatelst = this.hikiateLstFromDB
    console.log(this.dummyHikiateLst);
    this.dummyHikiateLst.forEach(hiki => {
      this.selectedHikiateLst.push(hiki);
      for (let i = 0; i < hikiatelst.length; i++) {
        if (hiki == hikiatelst[i].hikiate) {
          hikiatelst.splice(i, 1);
        }
      }
    });
    this.hikiateLstFromDB = hikiatelst;
    this.dummyHikiateLstToDB = this.selectedHikiateLst;
    this.dummyHikiateLst = ""
  }
  moveHikiBackward() {
    this.dummyHikiateLstToDB.forEach(hiki => {
      let body = {
        "hikiate": hiki
      }
      this.hikiateLstFromDB.push(body);
      this.index = this.selectedHikiateLst.indexOf(hiki);
      this.selectedHikiateLst.splice(this.index, 1);
    });
    if (this.dummyHikiateLstToDB.length >= 1) {
      this.hikiateLstFromDB = this.hikiateLstFromDB.sort((a, b) => a.hikiate > b.hikiate ? 1 : -1);
      this.dummyHikiateLstToDB = [];
    }
  }
  //pcSpec

  movePcSPecForward() {
    let pcSpecLst = this.pcSpecLstFromDB
    console.log(this.dummyPcSpecLst);
    this.dummyPcSpecLst.forEach(pcSPec => {
      this.selectedPcSpecLst.push(pcSPec);
      for (let i = 0; i < pcSpecLst.length; i++) {
        console.log(i);
        console.log(pcSPec, pcSpecLst[i].pc_spec);
        console.log(pcSPec === pcSpecLst[i].pc_spec);
        console.log(i);
        if (pcSPec == pcSpecLst[i].pc_spec) {
          pcSpecLst.splice(i, 1);
        }
      }
    });
    this.pcSpecLstFromDB = pcSpecLst;
    console.log(this.pcSpecLstFromDB);
    console.log(this.selectedPcSpecLst);
    this.dummyPcSpecLst = ""
    this.dummyPcSpecLstToDB = [];
    let arrayvalue: any;
    arrayvalue = this.selectedCompPcSpecLst.sort();
    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = arrayvalue[i]
      } else {
        str += ',' + arrayvalue[i]
      }
      this.aNetSpec = str
      console.log(this.aNetSpec);

    }

  }

  movePcSpecBackward() {
    this.dummyPcSpecLstToDB.forEach(pcSPec => {
      let body = {
        "pc_spec": pcSPec
      }
      this.pcSpecLstFromDB.push(body);
      this.index = this.selectedPcSpecLst.indexOf(pcSPec);
      this.selectedPcSpecLst.splice(this.index, 1);
    });
    if (this.dummyPcSpecLstToDB.length >= 1) {
      this.pcSpecLstFromDB = this.pcSpecLstFromDB.sort((a, b) => a.pc_spec > b.pc_spec ? 1 : -1);
      console.log(this.pcSpecLstFromDB)
      console.log(this.selectedPcSpecLst);
      this.dummyPcSpecLstToDB = [];

      let arrayvalue: any = [];
      arrayvalue = this.selectedPcSpecLst.sort();
      console.log(arrayvalue.length, arrayvalue);
      let str = '';
      for (let i = 0; i < arrayvalue.length; i++) {
        if (str == '') {
          str = arrayvalue[i]
        } else {
          str += ',' + arrayvalue[i]
        }
        this.aNetSpec = str
        console.log(this.aNetSpec);
        this.getnetSpec();
      }
    }
  }


  //compPcSPec
  moveCompPcSpecForward() {
    let compPcSpeclst = this.compPcSpecLstFromDB
    console.log(this.dummyCompPcSpecLst);
    this.dummyCompPcSpecLst.forEach(compPcSpec => {
      this.selectedCompPcSpecLst.push(compPcSpec);
      for (let i = 0; i < compPcSpeclst.length; i++) {
        if (compPcSpec == compPcSpeclst[i].pc_spec) {
          compPcSpeclst.splice(i, 1);
        }
      }
    });
    this.compPcSpecLstFromDB = compPcSpeclst;
    this.dummyCompPcSpecLst = ""
    this.format()

  }

  format() {
    let array = []
    array = JSON.parse(JSON.stringify(this.selectedPcSpecLst))
    console.log(array);
    let myArray = array.filter(el => {
      return !this.selectedCompPcSpecLst.includes(el);
    });
    console.log('myArray', myArray);
    let sortnetspec= myArray.sort()
    this.aNetSpec = sortnetspec.join(',');
    console.log(this.aNetSpec);
  }

  getnetSpec() {
    let pcSpecLst = this.pcSpecLstFromDB
    console.log(this.dummyPcSpecLstToDB);
    if (this.dummyPcSpecLstToDB.length != 0 || this.dummyPcSpecLstToDB != undefined) {
      this.dummyPcSpecLstToDB.forEach(pcSPec => {
        for (let i = 0; i < pcSpecLst.length; i++) {
          if (pcSPec == pcSpecLst[i].pc_spec) {
            pcSpecLst.splice(i, 1);
          }
        }
      });
      this.pcSpecLstFromDB = pcSpecLst;
      this.aNetSpec = this.dummyPcSpecLstToDB;
    }
  }


  moveCompPcSpecBackward() {
    this.dummyCompPcSpecLstToDB.forEach(compPcSpec => {
      let body = {
        "pc_spec": compPcSpec
      }
      this.compPcSpecLstFromDB.push(body);
      this.index = this.selectedCompPcSpecLst.indexOf(compPcSpec);
      this.selectedCompPcSpecLst.splice(this.index, 1);
    });
    if (this.dummyCompPcSpecLstToDB.length >= 1) {
      this.compPcSpecLstFromDB = this.compPcSpecLstFromDB.sort((a, b) => a.pc_spec > b.pc_spec ? 1 : -1);
      this.dummyCompPcSpecLstToDB = [];
    }
  }


  //closealert
  closeAlert() {
    this.errorMessage = '';
  }

  //save
  submitRepVeh() {
    console.log(this.dummyCompPcSpecLstToDB, this.dummyHikiateLstToDB, this.dummyPcSpecLstToDB)
    console.log(typeof this.aEFfDtIn);

    console.log(this.selectedPcSpecLst);
    if (!this.aNamc || !this.aFamily || !this.aKatashiki
      || !this.aCust || !this.aEFfDtIn || !this.aSP) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.aEFfDtIn && this.aExprDt &&
      this.pipe.transform(this.aEFfDtIn, 'yyyy-MM-dd') > this.pipe.transform(this.aExprDt, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    }
    else if (this.selectedPcSpecLst.length > 1) {
      let word, digits;
      let word1, digits1;
      let spclarray = [];
      let str1; let str2;
      let datafinal
      for (let i = 0; i < this.selectedPcSpecLst.length; i++) {
        for (let j = i + 1; j < this.selectedPcSpecLst.length; j++) {
          str1 = this.selectedPcSpecLst[i];
          [word, digits] = str1.match(/\D+|\d+/g);
          console.log(word, digits)
          str2 = this.selectedPcSpecLst[j];
          [word1, digits1] = str2.match(/\D+|\d+/g);
          console.log(word, digits)
          if (word == word1) {
            spclarray.push(str1, str2)
            console.log('spclarray', spclarray);
            datafinal = [...new Set(spclarray)]
          }
          // else {
          //   // this.checkfnflg = true
          //   console.log(this.checkfnflg)
          //   // this.elsechsckfn();
          // }
        }
      }
      console.log("digits", word);
      console.log("spclarray", spclarray);
      console.log("spclarray", datafinal);
      if (spclarray.length > 0) {
        this.errorMessage = "Pc Specs " + datafinal + " are Incompatible";
      } else {
        this.elsechsckfn();
      }
    }
    else {
      this.elsechsckfn();
    }

  }
  elsechsckfn() {
    let fileName= localStorage.getItem('Filename')
    if (this.aExprDt == "") {
      this.aExprDt = "9999-12-31T00:00:00z"
    }
    let indateFormat = ''; let outdateFormat = '';
    if (this.aEFfDtIn != "") {
      let d1 = moment(this.aEFfDtIn).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.aEFfDtIn = date
      // let date = this.pipe.transform(this.effDateIn, 'yyyy-MM-dd')
      // this.effDateIn = date
      // console.log("this.effDateIn",date,this.effDateIn);

    }
    if (this.aExprDt != "") {
      let d1 = moment(this.aExprDt).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.aExprDt = date
      // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
    }

    let HundPerSpec_flg;
    if (this.aHundPerSpec == true) {
      HundPerSpec_flg = 'Y'
    }
    else {
      HundPerSpec_flg = 'N'
    }
    let hikiatelstArray: any;
    hikiatelstArray = this.selectedHikiateLst;
    console.log(hikiatelstArray.length, hikiatelstArray);
    let str = '';
    for (let i = 0; i < hikiatelstArray.length; i++) {
      if (str == '') {
        str = hikiatelstArray[i]
      } else {
        str += ',' + hikiatelstArray[i]
      }
      this.hikiatevalue = str;
    }
    let PcSpecLstArray: any;
    PcSpecLstArray = this.selectedPcSpecLst;
    console.log(PcSpecLstArray.length, PcSpecLstArray);
    let str1 = '';
    for (let i = 0; i < PcSpecLstArray.length; i++) {
      if (str1 == '') {
        str1 = PcSpecLstArray[i]
      } else {
        str1 += ',' + PcSpecLstArray[i]
      }
      this.PcSpecvalue = str1;
    }
    let CompPcSpecLstArray: any;
    CompPcSpecLstArray = this.selectedCompPcSpecLst;
    console.log(CompPcSpecLstArray.length, CompPcSpecLstArray);
    let str2 = '';
    for (let i = 0; i < CompPcSpecLstArray.length; i++) {
      if (str2 == '') {
        str2 = CompPcSpecLstArray[i]
      } else {
        str2 += ',' + CompPcSpecLstArray[i]
      }

    }
    let comppsspec = str2
    if (this.aSP == 'P') {
      let pcspeccomppclst = []
      console.log("pcspeccomppclst", this.selectedPcSpecLst, this.selectedCompPcSpecLst)
      for (let i = 0; i < this.selectedPcSpecLst.length; i++) {
        for (let j = i; j < this.selectedCompPcSpecLst.length; j++) {
          console.log(this.selectedPcSpecLst[i] == this.selectedCompPcSpecLst[j]);
          if (this.selectedPcSpecLst[i] == this.selectedCompPcSpecLst[j]) {
            pcspeccomppclst.push(this.selectedPcSpecLst[i]);
          }
        }
      }
      console.log("pcspeccomppclst", pcspeccomppclst)

      if (((this.selectedCompPcSpecLst.length == pcspeccomppclst.length) && (this.selectedPcSpecLst.length == pcspeccomppclst.length)) && this.selectedCompPcSpecLst.length != 0 && pcspeccomppclst.length != 0) {
        console.log("number", this.selectedCompPcSpecLst.length, pcspeccomppclst.length)
        this.errorMessage = "PC Spec and Comp Pc Spec cannot be same"
      } else {
        let body = {
          'namc': this.aNamc,
          'family': this.aFamily,
          'katashiki': this.aKatashiki,
          'custId': this.aCust,
          'hikiate': this.hikiatevalue,
          'dest': this.aDest,
          // 'pcSpec': this.PcSpecvalue,
          // 'hunSpec': HundPerSpec_flg,
          'extTimeIn': indateFormat,
          'extTimeOut': outdateFormat,
          'compPcSpec': comppsspec,
          'cmpHunPctOptFlg': this.aCompHundPerSPec == true ? 'Y' : 'N',
          "screenNo":"NBPC_9350",
          "fileName" :fileName
          // 'sp': this.aSP,
        }
        this.SpinnerService.show();
        this.repVehAddService.ValbaseOptTypVal(body).subscribe(data => {
          console.log(data);
          this.SpinnerService.hide();
          this.response = data.payload;
          if (this.response[0].result == 'Base spec found and hence the spec p can be added') {
            this.CheckExistsAddRepVeh()
          } else if (this.response[0].result == 'Base spec not found and  the spec p cannot be added') {
            this.errorMessage = "Option Spec should be entered after Base Spec is defined";

          } else if (this.response[0].result == 'Invalid') {
            this.errorMessage = 'PC Spec and Comp PC Spec Invalid!';
          }
        }, err => {
          this.SpinnerService.hide();
        });
      }
    } else if (this.aSP == "S") {
      this.CheckExistsAddRepVeh();
    }


  }
  CheckExistsAddRepVeh() {
    let fileName= localStorage.getItem('Filename')
    let HundPerSpec_flg; let compHundPerSpec_flg;
    if (this.aHundPerSpec == true) {
      HundPerSpec_flg = 'Y'
    }
    else {
      HundPerSpec_flg = 'N'
    }
    if (this.aCompHundPerSPec == true) {
      compHundPerSpec_flg = 'Y'
    }
    else {
      compHundPerSpec_flg = 'N'
    }
    let body = {
      'namc': this.aNamc,
      'family': this.aFamily,
      'katashiki': this.aKatashiki,
      'custId': this.aCust,
      'dest': this.aDest,
      'hikiate': this.hikiatevalue,
      'pcSpec': this.PcSpecvalue,
      'compPcSpec': this.CompPcSpecvalue,
      'hunSpec': HundPerSpec_flg,
      'cmpHunSpec': compHundPerSpec_flg,
      'sp': this.aSP,
      "screenNo":"NBPC_9350",
      "fileName" :fileName
    }
    this.SpinnerService.show();
    this.repVehAddService.CheckExistsRepVehicle(body).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.AddRepVeh();
      } else if (this.response[0].result == 'Invalid') {
        this.SpinnerService.hide();
        this.errorMessage = 'Part Number Invalid';
      } else if (this.response[0].result == 'Data Already Exists') {
        this.SpinnerService.hide();
        this.errorMessage = 'Record already Exists!';
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  AddRepVeh() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');

    let mnthly_flg; let compbom_flg; let HundPerSpec_flg; let compHundPerSpec_flg;
    if (this.aMnthlyRep == true) {
      mnthly_flg = 'Y'
    }
    else {
      mnthly_flg = 'N'
    }
    if (this.aCompBom == true) {
      compbom_flg = 'Y'
    }
    else {
      compbom_flg = 'N'
    }
    if (this.aHundPerSpec == true) {
      HundPerSpec_flg = 'Y'
    }
    else {
      HundPerSpec_flg = 'N'
    }
    if (this.aCompHundPerSPec == true) {
      compHundPerSpec_flg = 'Y'
    }
    else {
      compHundPerSpec_flg = 'N'
    }

    let hikiatelstArray: any;
    hikiatelstArray = this.selectedHikiateLst;
    console.log(hikiatelstArray.length, hikiatelstArray);
    let str = '';
    for (let i = 0; i < hikiatelstArray.length; i++) {
      if (str == '') {
        str = hikiatelstArray[i]
      } else {
        str += ',' + hikiatelstArray[i]
      }
      this.hikiatevalue = str;
    }
    let PcSpecLstArray: any;
    PcSpecLstArray = this.selectedPcSpecLst;
    console.log(PcSpecLstArray.length, PcSpecLstArray);
    let str1 = '';
    for (let i = 0; i < PcSpecLstArray.length; i++) {
      if (str1 == '') {
        str1 = PcSpecLstArray[i]
      } else {
        str1 += ',' + PcSpecLstArray[i]
      }
      this.PcSpecvalue = str1;
    }
    let CompPcSpecLstArray: any;
    CompPcSpecLstArray = this.selectedCompPcSpecLst;
    console.log(CompPcSpecLstArray.length, CompPcSpecLstArray);
    let str2 = '';
    for (let i = 0; i < CompPcSpecLstArray.length; i++) {
      if (str2 == '') {
        str2 = CompPcSpecLstArray[i]
      } else {
        str2 += ',' + CompPcSpecLstArray[i]
      }
      this.CompPcSpecvalue = str2;
    }
    console.log(this.PcSpecvalue, this.CompPcSpecvalue, this.hikiatevalue)

    let indateFormat = ''; let outdateFormat = '';
    if (this.aEFfDtIn != "") {
      let d1 = moment(this.aEFfDtIn).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.aEFfDtIn = date
      // let date = this.pipe.transform(this.effDateIn, 'yyyy-MM-dd')
      // this.effDateIn = date
      // console.log("this.effDateIn",date,this.effDateIn);

    }
    if (this.aExprDt != "") {
      let d1 = moment(this.aExprDt).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.aExprDt = date
      // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
    }
    this.errorMessage = "";
    let cmts;

    if (this.aComments != undefined) {

      cmts = this.aComments.replaceAll("'", "''");

    } else {

      cmts = this.aComments

    }
    let body = {
      'namc': this.aNamc,
      'family': this.aFamily,
      'katashiki': this.aKatashiki,
      'dest': this.aDest,
      'pcSpec': this.PcSpecvalue,
      'hikiate': this.hikiatevalue,
      'hunSpec': HundPerSpec_flg,
      'custId': this.aCust,
      'sp': this.aSP,
      'compPcSpec': this.CompPcSpecvalue,
      'cmpHunSpec': compHundPerSpec_flg,
      'monRepFlg': mnthly_flg,
      'compBomFlg': compbom_flg,
      'comment': cmts,
      'extTimeIn': indateFormat,
      'extTimeOut': outdateFormat,
      'netSpec': this.aNetSpec,
      'mdlNo': this.aMdlNo,
      'optCd': this.aOptnCd,
      'sapMtrlId': this.aSapMatId,
      "screenNo":"NBPC_9350",
      "fileName" :fileName
      
    }
    console.log(body);
    this.SpinnerService.show();
    this.repVehAddService.addRepVehicleData(body).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.errorMessage = "Data not Added";
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  getCompSpec() {
    console.log(this.aSP)
    if (this.aSP == "S") {
      console.log(this.hidedata)
      this.hidedata = "true";
      this.selectedCompPcSpecLst = []
    } else {
      this.hidedata = "false";
      console.log(this.hidedata)
    }
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'aEFfDtIn') {
      this.aEFfDtIn = "";
    } else if (name == 'aExprDt') {
      this.aExprDt = "";
    }
  }

}
