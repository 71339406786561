import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductWithinVersion } from '../dialog-data/product-within-version';
import { ProdVersionActionsComponent } from '../prod-version-actions/prod-version-actions.component';
import {ProdVersionPart2ActionsService} from './prod-version-part2-actions.service'

@Component({
  selector: 'app-prod-version-part2-actions',
  templateUrl: './prod-version-part2-actions.component.html',
  styleUrls: ['./prod-version-part2-actions.component.css']
})
export class ProdVersionPart2ActionsComponent implements OnInit {
  user: any;
  repProdId:any;

  constructor(public prodVerAction2Service: ProdVersionPart2ActionsService,
    @Inject(MAT_DIALOG_DATA) public data: ProductWithinVersion,
    public dialogRef: MatDialogRef<ProdVersionActionsComponent>,
    private readonly SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    console.log(this.data);
    this.user = localStorage.getItem('workDayId')
    this.SpinnerService.show();
    let arrayvalue: any;
    arrayvalue = this.data.rep_prod_id;
    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = arrayvalue[i]
      } else {
        str += ',' + arrayvalue[i];
      }
      this.repProdId = str;
    }
    console.log(this.repProdId)

    if (this.data.buttonName == 'resetReadyforReview') {
      this.fetchVerifiedStatusChkResetToReady();
    }  if (this.data.buttonName == 'sendtosap') {
      this.fetchValidNamcSendToSap();
    }
  }

  


  /////send to sap func/////////////
  fetchValidNamcSendToSap(){
    let fileName= localStorage.getItem('Filename')
    let body ={
      'repProdId':this.repProdId,
      "screenNo":"NBPC_10110",
      "fileName" :fileName
    }
    this.prodVerAction2Service.getValidNamcSendToSap(body).subscribe(res=>{
      console.log("res from validnamc chk",res.payload);
      if(res.payload[0].response == 'success'){
        this.fetchBomVerifiedStatusChk();
      }
      else{
        this.dialogRef.close(res.payload[0].response)
      this.SpinnerService.hide()
      }
    },err =>{
      this.SpinnerService.hide()
      this.dialogRef.close(err)
    })
  }

  fetchBomVerifiedStatusChk(){
    let fileName= localStorage.getItem('Filename')
    let body ={
      'repProdId':this.repProdId,
      "screenNo":"NBPC_10110",
      "fileName" :fileName      
    }
    this.prodVerAction2Service.getBomVerifiedStatusChk(body).subscribe(res=>{
      console.log("res from getBomVerifiedStatusChk",res.payload);
      if(res.payload[0].response == 'success'){
        this.fetchSapVerifiedStatusChk();
      }
      else{
        this.dialogRef.close(res.payload[0].response)
      this.SpinnerService.hide()
      }
    },err =>{
      this.SpinnerService.hide()
      this.dialogRef.close(err)
    })
  }

  fetchSapVerifiedStatusChk(){
    let fileName= localStorage.getItem('Filename')
    let body ={
      'repProdId':this.repProdId,
      "screenNo":"NBPC_10110",
      "fileName" :fileName
    }
    this.prodVerAction2Service.getSapVerifiedStatusChk(body).subscribe(res=>{
      console.log("res from getSapVerifiedStatusChk",res.payload);
      if(res.payload[0].response == 'success'){
        this.fetchStdVersionStatusChk();
      }
      else{
        this.dialogRef.close(res.payload[0].response)
      this.SpinnerService.hide()
      }
    },err =>{
      this.SpinnerService.hide()
      this.dialogRef.close(err)
    })
  }

  fetchStdVersionStatusChk(){
    let fileName= localStorage.getItem('Filename')
    let body ={
      'repProdId':this.repProdId,
      "screenNo":"NBPC_10110",
      "fileName" :fileName
    }
    this.prodVerAction2Service.getStdVersionStatusChk(body).subscribe(res=>{
      console.log("res from getStdVersionStatusChk",res.payload);
      if(res.payload[0].response == 'success'){
        this.updSendToSap();
      }
      else{
        this.dialogRef.close(res.payload[0].response)
      this.SpinnerService.hide()
      }
    },err =>{
      this.SpinnerService.hide()
      this.dialogRef.close(err)
    })
  }

  updSendToSap(){
    let fileName= localStorage.getItem('Filename')
    let body ={
      'repProdId':this.repProdId,
      "screenNo":"NBPC_10110",
      "fileName" :fileName
    }
    this.prodVerAction2Service.updateSendToSap(body).subscribe(res=>{
      console.log("res from updateSendToSap",res.payload);
      if(res.payload[0].response == 'success'){
        this.dialogRef.close(res.payload[0].response)
        this.SpinnerService.hide()
      }
      else{
        this.dialogRef.close(res.payload[0].response)
      this.SpinnerService.hide()
      }
    },err =>{
      this.SpinnerService.hide()
      this.dialogRef.close(err)
    })
  }

  /////reset ready for review
  fetchVerifiedStatusChkResetToReady(){
    let fileName= localStorage.getItem('Filename')
    let body ={
      'repProdId':this.repProdId,
      "screenNo":"NBPC_10110",
      "fileName" :fileName
    }
    this.prodVerAction2Service.getVerifiedStatusChkResetToReady(body).subscribe(res=>{
      console.log("res from getVerifiedStatusChkResetToReady",res.payload);
      if(res.payload[0].response == 'success'){
        this.updRepProd();
      }
      else{
        this.dialogRef.close(res.payload[0].response)
      this.SpinnerService.hide()
      }
    },err =>{
      this.SpinnerService.hide()
      this.dialogRef.close(err)
    })
  }

  updRepProd(){
    let fileName= localStorage.getItem('Filename')
    let body ={
      'repProdId':this.repProdId,
      "screenNo":"NBPC_10110",
      "fileName" :fileName      
    }
    this.prodVerAction2Service.updateRepProd(body).subscribe(res=>{
      console.log("res from updateRepProd chk",res.payload);
      if(res.payload[0].response == 'success'){
        this.updProdBom();
      }
      else{
        this.dialogRef.close(res.payload[0].response)
      this.SpinnerService.hide()
      }
    },err =>{
      this.SpinnerService.hide()
      this.dialogRef.close(err)
    })
  }

  updProdBom(){
    let fileName= localStorage.getItem('Filename')
    let body ={
      'repProdId':this.repProdId,
      "screenNo":"NBPC_10110",
      "fileName" :fileName
    }
    this.prodVerAction2Service.updateProdBom(body).subscribe(res=>{
      console.log("res from updateProdBom chk",res.payload);
      if(res.payload[0].response == 'success'){
        this.dialogRef.close(res.payload[0].response)
        this.SpinnerService.hide()
      }
      else{
        this.dialogRef.close(res.payload[0].response)
      this.SpinnerService.hide()
      }
    },err =>{
      this.SpinnerService.hide()
      this.dialogRef.close(err)
    })
  }

}
