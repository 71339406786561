
import * as CryptoJS from 'crypto-js';
var cv = '';
function generateRandomString(length) {
  var text = '';
  let codeVerifier = localStorage.getItem('code_verifier');
  if (codeVerifier !== null && codeVerifier !== undefined && codeVerifier !== '') {
    cv = codeVerifier;
    return codeVerifier;
  } else {
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    cv = text;
    localStorage.setItem('code_verifier', cv);
    return text;
  }
}

function generateCodeChallenge(codeVerifier) {
  const codeChallange = base64URL(CryptoJS.SHA256(codeVerifier));
  return codeChallange + '&code_challenge_method=S256';
}

function base64URL(string: any) {
  return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}
export const environment = {
  production: false,
//redirectUrl: `https://login.microsoftonline.com/tmnatest.onmicrosoft.com/oauth2/authorize?client_id=0d47c5e2-e321-4225-a474-935e750c99b4&response_type=id_token&redirect_uri=https%3A%2F%2Fqa.ebom.toyota.com%2F&scope=openid&state=12345&nonce=678910`,
  redirectUrl: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef/oauth2/v2.0/authorize?client_id=0d47c5e2-e321-4225-a474-935e750c99b4&response_type=code&redirect_uri=https://qa.ebom.toyota.com&response_mode=query&scope=openid&state=23343&code_challenge=',
  apiUrl: "http://localhost:8080/ebom",
  MastergridapiUrl: 'https://api.dev.ebom.toyota.com/qa_master_rest/api',
  MastersearchdropdownapiUrl: 'https://api.dev.ebom.toyota.com/qa_common_rest/api',
  Mastersearchdropdown1apiUrl: 'https://api.dev.ebom.toyota.com/qa_common_s2_rest/api',
  Mastersearchdropdown2apiUrl: 'https://api.dev.ebom.toyota.com/qa_common_s3_rest/api',
  Mastersearchdropdown3apiUrl: 'https://api.dev.ebom.toyota.com/qa_common_s4_rest/api',
  BussinessgridapiUrl: 'https://api.dev.ebom.toyota.com/qa_business_rest/api',
  QualandExcpgridapiUrl: 'https://api.dev.ebom.toyota.com/qa_excp_report_rest/api',
  QualandExcpgridapi1Url: 'https://api.dev.ebom.toyota.com/qa_i_excp_report_rest/api',
  BusinesssearchdropdownapiUrl: 'https://api.dev.ebom.toyota.com/qa_common_s5_rest/api',
  Business_Qe_dropdownapiURL: 'https://api.dev.ebom.toyota.com/qa_common_s6_rest/api',
  Master_ActionapiUrl: 'https://api.dev.ebom.toyota.com/qa_master_action_rest/api',
  Master_EditapiUrl: 'https://api.dev.ebom.toyota.com/qa_master_edit_action_rest/api',
  Master_ActionInsertApiUrl: 'https://api.dev.ebom.toyota.com/qa_master_insert/api',
  BussinessppsvapiUrl: 'https://api.dev.ebom.toyota.com/qa_business_ppsv/api',
  BusinessActionRestapiUrl: 'https://api.dev.ebom.toyota.com/qa_business_action_rest/api',
  BusinessActionS2apiUrl: 'https://api.dev.ebom.toyota.com/qa_business_action_s2_rest/api',
  Uploadrest: ' https://api.dev.ebom.toyota.com/qa_upload_rest/api',
  region: 'us-west-2',
  uploadBucketName: 'ebom.fileupload',
  logoutUrl: 'https://login.microsoftonline.com/common/oauth2/logout',
  rmvUserDetailsUrl:'https://th4huac7nb.execute-api.us-west-2.amazonaws.com/dev/api/',
  tokenURL: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef/oauth2/v2.0/token',
  grantType: 'authorization_code',
  clientId: '0d47c5e2-e321-4225-a474-935e750c99b4',
  redirectURI: 'https://qa.ebom.toyota.com',
  codeVerifier: generateRandomString(128),
  codeChallenge: generateCodeChallenge(cv)
};


