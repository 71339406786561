import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { MajorPartlstAddDialogService } from './major-partlst-add-dialog.service';

@Component({
  selector: 'app-major-partlst-add-dialog',
  templateUrl: './major-partlst-add-dialog.component.html',
  styleUrls: ['./major-partlst-add-dialog.component.css']
})
export class MajorPartlstAddDialogComponent implements OnInit {
  [x: string]: any;
  enablePartnum: boolean = false;
  enableKatashiki: boolean = true;

  //errormessage
  errorMessage: string = "";

  //index
  index: any;

  spinnerMessage = "Please Wait...";

  //ngModel values
  majorPartAddFamily: string = "";
  majorPartAddPartType: string = "";
  majorPartAddExpPartQty: string = "";
  majorPartAddProdType: string = "";
  majorPartAddActPartCd: string = "";
  majorPartAddInActPartCd: string = "";
  majorPartAddPartCd: any;
  majorPartAddSlctPartCd: string = "";
  majorPartAddComments: any = "";
  majorPartList: string = "";
  majorPartAddNamc: any = '';
  majorPartAddKatashiki: any = "";
  listId: any;
  majPartTypeId: any;
  //list
  partCodeList: any = [];
  famcdLst = [];
  majPartTypLst = [];
  prodTypLst = [];
  inactiveLst = [];
  activeLst = [];
  namcLst = [];
  katashikiLst = [];
  partnumLst = [];
  // expectedLst = [];

  //response from backend after insert/update records
  response: any;

  onloadtrue: boolean = false
  //to main page
  message = "Record Added Successfully";

  constructor(public dialogRef: MatDialogRef<MajorPartlstAddDialogComponent>,
    private readonly SpinnerService: NgxSpinnerService, private majPartLstAddService: MajorPartlstAddDialogService) { }

  ngOnInit(): void {
    //active partcode

    if (localStorage.getItem('partcodepopup')) {
      localStorage.removeItem('partcodepopup')
    }
    this.SpinnerService.show();
    //namc
    this.majPartLstAddService.getNamc().subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.namcLst = data.payload;
    });

    //famBynamc
    this.majPartLstAddService.getfamByNamc(this.majorPartAddNamc).subscribe(data => {
      console.log(data);
      this.famcdLst = data.payload;
      this.SpinnerService.hide();
    });

    // //Family
    // this.majPartLstAddService.getMajPartFam().subscribe(data => {
    //   console.log(data);
    //   this.famcdLst = data.payload;
    //   this.SpinnerService.hide();
    // });

    //majPartTyp
    this.majPartLstAddService.getMajPartTyp().subscribe(data => {
      console.log(data);
      this.majPartTypLst = data.payload;
      this.majorPartAddPartType = "";

    });
  }
  
  //family
  getFamily(): void {
    console.log(this.majorPartAddNamc)
    if (this.majorPartAddNamc == '') {
      this.majorPartAddFamily= "";
      this.majorPartAddKatashiki = ""
      this.majorPartAddExpPartQty = "";
      this.majorPartAddProdType = "";
      this.majorPartAddActPartCd = "";
      this.majorPartAddInActPartCd = "";
      this.majorPartAddComments = "";
      this.katashikiLst= [];
      this.prodTypLst = [];
      this.activeLst = [];
      this.inactiveLst = [];
      this.SpinnerService.hide();
    }
    if (this.majorPartAddNamc == undefined) {
      this.majorPartAddNamc = ""
    }
    else {
      this.SpinnerService.show();
    }
    this.majPartLstAddService.getfamByNamc(this.majorPartAddNamc).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.famcdLst = data.payload;
      this.majorPartAddFamily = ""
      this.majorPartAddKatashiki =""
      this.majorPartAddExpPartQty=""
      this.majorPartAddActPartCd = ""
      this.majorPartAddInActPartCd = ""
      this.majorPartAddComments = ""
      this.majorPartAddProdType=""
      this.katashikiLst= []
      this.activeLst = []
      this.inactiveLst = []
      // this.prodTypLst = [];
    });
  }
  //katashiki from rpd tbl
  getKatashiki() {
    return new Promise(res => {
      this.SpinnerService.show();
      console.log(this.majorPartAddNamc)
      if (this.majorPartAddNamc == undefined) {
        this.majorPartAddNamc = ""
      }
      if (this.majorPartAddFamily == undefined) {
        this.majorPartAddFamily = ""
      }
      this.majPartLstAddService.getkatashikiByFam(this.majorPartAddNamc, this.majorPartAddFamily).subscribe(data => {
        console.log(data);
        this.katashikiLst = data.payload;
        this.majorPartAddKatashiki = ""
        res(this.katashikiLst)
        console.log(this.katashikiLst)
      }, err => {
        this.katashikiLst = []
        res(this.katashikiLst);
        this.SpinnerService.hide();

      });
    });

  }

  //katashiki from mfrd tbl
  getKatashikii() {
    return new Promise(res => {
      this.SpinnerService.show();
      console.log(this.majorPartAddNamc)
      if (this.majorPartAddNamc == undefined) {
        this.majorPartAddNamc = ""
      }
      if (this.majorPartAddFamily == undefined) {
        this.majorPartAddFamily = ""
      }

      this.majPartLstAddService.getkatasByFam(this.majorPartAddNamc, this.majorPartAddFamily).subscribe(data => {
        console.log(data);
        this.katashikiLst = data.payload;
        this.majorPartAddKatashiki = ""
        res(this.katashikiLst)
        console.log(this.katashikiLst)
      }, err => {
        this.katashikiLst = []
        res(this.katashikiLst)
        this.SpinnerService.hide();

      });
    });
  }

  //part no rpd tbl
  getpartNumber() {
    return new Promise(res => {
      this.SpinnerService.show();
      console.log(this.majorPartAddNamc)
      if (this.majorPartAddNamc == undefined) {
        this.majorPartAddNamc = ""
      }
      if (this.majorPartAddFamily == undefined) {
        this.majorPartAddFamily = ""
      }
      this.majPartLstAddService.getpartNoRpd(this.majorPartAddNamc, this.majorPartAddFamily).subscribe(data => {
        console.log(data);
        this.partnumLst = data.payload;
        this.majorPartAddKatashiki = ""
        res(this.partnumLst)
      }, err => {
        this.partnumLst = []
        res(this.partnumLst)
        this.SpinnerService.hide();

      });
    })

  }

  //partno mfrd tbl
  getpartNo() {
    this.SpinnerService.show();
    return new Promise(res => {
      console.log(this.majorPartAddNamc)
      if (this.majorPartAddNamc == undefined) {
        this.majorPartAddNamc = ""
      }
      if (this.majorPartAddFamily == undefined) {
        this.majorPartAddFamily = ""
      }
      this.majPartLstAddService.getpartNoMfrd(this.majorPartAddNamc, this.majorPartAddFamily).subscribe(data => {
        console.log(data);
        this.partnumLst = data.payload;
        this.majorPartAddKatashiki = ""
        res(this.partnumLst)
      }, err => {
        this.partnumLst = []
        res(this.partnumLst)
        this.SpinnerService.hide();

      });
    })
  }


  //main call
  async onchangecall() {
    this.SpinnerService.show()
    console.log(this.majorPartAddFamily)
    if (this.majorPartAddFamily == '' ) {
      this.majorPartAddKatashiki = ""
      this.majorPartAddExpPartQty = "";
      this.majorPartAddProdType = "";
      this.majorPartAddActPartCd = "";
      this.majorPartAddInActPartCd = "";
      this.majorPartAddComments = "";
      this.katashikiLst= [];
      this.activeLst = [];
      this.inactiveLst = [];
      this.SpinnerService.hide();
    }
    else {
      this.katashikiLst = []
      this.partnumLst = []
      this.SpinnerService.show();
      let a: any = await this.getKatashiki();
      let b: any = await this.getpartNumber();
      let c: any = await this.getKatashikii();
      let d: any = await this.getpartNo();
      console.log(a, b)
      console.log(a.length, b.length)
      let e = a.concat(c);
      let f = b.concat(d);
      console.log(e, f)
      console.log(e.length, f.length)
      if (f.length != 0 && e.length != 0) {
        function renameKey(obj, oldKey, newKey) {
          obj[newKey] = obj[oldKey];
          delete obj[oldKey];
        }
        const arr = f;
        arr.forEach(obj => renameKey(obj, 'partno', 'katashiki_cd'));
        // arr.forEach(obj => renameKey(obj, 'part_no', 'katashiki_cd'));
        const updatedJson = arr;
        console.log(updatedJson);
        let datatemp = e.concat(updatedJson);
        var katashikitemp = new Map();
        datatemp.forEach(function (item) {
          katashikitemp.set(JSON.stringify(item), item);
        });
        this.katashikiLst = [...katashikitemp.values()]
        console.log(this.katashikiLst);
        console.log(this.katashikiLst)
        this.SpinnerService.hide();
        this.enableKatashiki = true;
        this.enablePartnum = false
      }
      else if (e.length != 0) {
        var katashikitemp = new Map();
        e.forEach(function (item) {
          katashikitemp.set(JSON.stringify(item), item);
        });
        this.katashikiLst = [...katashikitemp.values()]
        console.log(this.katashikiLst);
        this.SpinnerService.hide();
        this.enableKatashiki = true;
        this.enablePartnum = false
      }
      else if (f.length != 0) {
        var parttemp = new Map();
        f.forEach(function (item) {
          parttemp.set(JSON.stringify(item), item);
        });
        this.partnumLst = [...parttemp.values()]
        console.log(this.partnumLst);
        this.SpinnerService.hide();
        this.enableKatashiki = false;
        this.enablePartnum = true
      } else {
        this.SpinnerService.hide()
      }
      this.majorPartAddExpPartQty = "";
      this.majorPartAddProdType = "";
      this.majorPartAddActPartCd = "";
      this.majorPartAddInActPartCd = "";
      this.majorPartAddComments = "";
      this.activeLst = [];
      this.inactiveLst = [];
    }
  }

  //onChangeMethod
  getProdTypByMajPartNM(): void {
    if (this.majorPartAddPartType == undefined) {
      this.majorPartAddPartType = "";
    }
    console.log("datad")
    if (this.majorPartAddPartType != "") {
      this.majPartLstAddService.getProdTypByMajPartNm(this.majorPartAddPartType).subscribe(data => {
        console.log(data);
        this.prodTypLst = data.payload;
        this.majorPartAddProdType = ""
        this.majorPartAddProdType = "";
        this.majorPartAddComments = "";
        this.majorPartAddExpPartQty = "";
        this.activeLst = [];
        this.inactiveLst = [];
      }, err => {
        this.SpinnerService.hide();
      })
    }
    else {
      this.majorPartAddProdType = ""
      this.prodTypLst = [];
      this.majorPartAddProdType = "";
      this.majorPartAddComments = "";
      this.majorPartAddExpPartQty = "";
      this.activeLst = [];
      this.inactiveLst = [];

    }
  }
  //active part code
  getActiveByProd(): void {
    this.SpinnerService.show();
    let parentpartValue = "";
    let katashikivalue = ""
    if (this.majorPartAddPartType == undefined) {
      this.majorPartAddPartType = "";
    }

    if (this.majorPartAddFamily == undefined) {
      this.majorPartAddFamily = "";
    }
    if (this.majorPartAddNamc == undefined) {
      this.majorPartAddNamc = ""
    }
    console.log("length", this.majorPartAddKatashiki.length)
    if (this.majorPartAddKatashiki.length == 10) {
      parentpartValue = this.majorPartAddKatashiki
    } else {
      katashikivalue = this.majorPartAddKatashiki
    }
    if (this.majorPartAddKatashiki == undefined) {
      parentpartValue = ''
      katashikivalue = ''
    }
    console.log(this.majorPartAddProdType);
    if (this.majorPartAddProdType != "") {
      this.majPartLstAddService.getActiveByProd(this.majorPartAddPartType, this.majorPartAddFamily, this.majorPartAddProdType, this.majorPartAddNamc, parentpartValue, katashikivalue).subscribe(data => {
        console.log(data);
        this.activeLst = data.payload;
        this.SpinnerService.hide();
      }, err => {
        this.SpinnerService.hide();
      });
    }

    else {
      this.majorPartAddActPartCd = "";
      this.activeLst = [];
      this.SpinnerService.hide();
    }
  }

  onchangekatashiki() {

  this.majorPartAddExpPartQty = "";
  this.majorPartAddProdType = "";
  this.majorPartAddActPartCd = "";
  this.majorPartAddInActPartCd = "";
  this.majorPartAddComments = "";
  this.activeLst = [];
  this.inactiveLst = [];


}

  //inactive partcode
  getinactiveByProd(): void {
    this.SpinnerService.show();
    let parentpartValue = "";
    let katashikivalue = ""
    if (this.majorPartAddPartType == undefined) {
      this.majorPartAddPartType = "";
    }
    if (this.majorPartAddFamily == undefined) {
      this.majorPartAddFamily = "";
    }
    if (this.majorPartAddNamc == undefined) {
      this.majorPartAddNamc = ""
    }
    if (this.majorPartAddKatashiki.length == '10') {
      parentpartValue = this.majorPartAddKatashiki
    } else {
      katashikivalue = this.majorPartAddKatashiki
    }
    if (this.majorPartAddKatashiki == undefined) {
      parentpartValue = ''
      katashikivalue = ''
    }
    if (this.majorPartAddProdType != "") {

      this.majPartLstAddService.getinactiveByProd(this.majorPartAddPartType, this.majorPartAddFamily, this.majorPartAddProdType, this.majorPartAddNamc, parentpartValue, katashikivalue).subscribe(data => {
        console.log(data);
        this.inactiveLst = data.payload;
        this.SpinnerService.hide();
      }, err => {
        this.SpinnerService.hide();
      });
    }
    else {
      this.majorPartAddInActPartCd = "";
      this.inactiveLst = [];
      this.SpinnerService.hide();
    }
  }


  getQtyCommentsByProdType(): void {
    this.SpinnerService.show();
    let parentpartValue = "";
    let katashikivalue = ""
    if (this.majorPartAddPartType == undefined) {
      this.majorPartAddPartType = "";
    }
    if (this.majorPartAddFamily == undefined) {
      this.majorPartAddFamily = "";
    }
    if (this.majorPartAddNamc == undefined) {
      this.majorPartAddNamc = ""
    }
    console.log("length", this.majorPartAddKatashiki.length)
    if (this.majorPartAddKatashiki.length == '10') {
      parentpartValue = this.majorPartAddKatashiki
    } else {
      katashikivalue = this.majorPartAddKatashiki
    }
    if (this.majorPartAddKatashiki == undefined) {
      parentpartValue = ''
      katashikivalue = ''
    }
    this.majPartLstAddService.getQtyComments(this.majorPartAddPartType, this.majorPartAddFamily, this.majorPartAddNamc, parentpartValue, katashikivalue).subscribe(data => {
      console.log(data);
      // this.expectedLst = data.payload;
      this.SpinnerService.hide();
      this.majorPartAddComments = data.payload[0].rmrk_txt;
      this.majorPartAddExpPartQty = data.payload[0].expct_part_qty;
      
    }, err => {
      this.SpinnerService.hide();

    });
  }

  //list of partcode when add button is clicked
  addPartCode(): void {
    let fileName= localStorage.getItem('Filename')
    this.errorMessage = ""
    console.log("data")

    if (this.majorPartAddPartCd.length < 5) {
      this.errorMessage = "Please provide first 5 or 6 characters for Major part";
    }

    else {

      console.log(this.activeLst);
      let activecdlist = []

      if (
        this.activeLst != undefined)
        this.activeLst.filter((res) => {
          if (res.maj_part_prfx_cd == this.majorPartAddPartCd) {
            activecdlist.push(this.majorPartAddPartCd);
            this.majorPartAddPartCd = this.activeLst;
            this.majorPartAddPartCd = ""
          }

          else if (
            this.inactiveLst != undefined)
            this.inactiveLst.filter((res) => {
              if (res.col_0_0_ == this.majorPartAddPartCd) {
                activecdlist.push(this.majorPartAddPartCd);
                this.majorPartAddPartCd = this.inactiveLst;
                this.majorPartAddPartCd = ""
              }

            })
        })
      console.log(activecdlist);
      if (activecdlist.length > 0) {
        this.errorMessage = "Part Code already added"
        this.majorPartAddPartCd = ""
      }
      else {
        let partNobody = {
          "partNo": this.majorPartAddPartCd,
          "screenNo":"NBPC_2220",
          "fileName" :fileName
        }
        this.SpinnerService.show();
        this.majPartLstAddService.CheckpartNo(partNobody).subscribe(data => {
          this.response = data.payload;
          this.SpinnerService.hide();
          if (this.response[0].result == 'success') {
            this.partCodeList.push(this.majorPartAddPartCd.toLocaleUpperCase());

            this.majorPartAddPartCd = ""
          } else if (this.response[0].result == 'Missing_Part_Number') {
            this.errorMessage = 'Missing Part Code!';
          }
          else if (this.response[0].result == 'Invalid Part Num') {
            this.errorMessage = "Part Code <" + this.majorPartAddPartCd + "> Invalid"
            this.majorPartAddPartCd = ""
          }

        }, err => {
          this.SpinnerService.hide();
        });
      }
    }
  }


  removePartCode(): void {
    this.index = this.partCodeList.indexOf(this.majorPartAddSlctPartCd[0]);
    this.partCodeList.splice(this.index, 1);


    // this.majorPartAddPartCd = this.partCodeList;
  }

  //save
  submitMajLst(): void {
    let fileName= localStorage.getItem('Filename')
    console.log(this.activeLst);
    let parentpartValue = "";
    let katashikivalue = "";
    this.majorPartAddSlctPartCd = this.partCodeList;
    console.log(this.majorPartAddSlctPartCd)
    console.log(this.majorPartAddPartType, this.majorPartAddProdType, this.majorPartAddExpPartQty)
    if (!this.majorPartAddPartType || !this.majorPartAddProdType || !this.majorPartAddExpPartQty) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if ((this.majorPartAddSlctPartCd.length == 0) && (this.activeLst == undefined)) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else {
      if (this.majorPartAddNamc == undefined) {
        this.majorPartAddNamc = ""
      }
      if (this.majorPartAddKatashiki.length == '10') {
        parentpartValue = this.majorPartAddKatashiki
      } else {
        katashikivalue = this.majorPartAddKatashiki
      }
      this.errorMessage = "";
      let body = {
        'namc': this.majorPartAddNamc,
        'family': this.majorPartAddFamily,
        'katashikiCd': katashikivalue,
        'majPartTypId': this.majorPartAddPartType,
        'partNo': parentpartValue,
        "screenNo":"NBPC_2220",
        "fileName" :fileName
      }

      this.SpinnerService.show();
      this.majPartLstAddService.checkMajPartLst(body).subscribe(data => {
        console.log("129", data);
        this.response = data.payload;
        // this.SpinnerService.hide();
        if (this.response[0].result == 'success') {
          let data = this.response.payload
          console.log("134", data);
          this.addmajorpartlist();
          // this.dialogRef.close(this.response[0].result);
        } else {
          console.log("139", this.response[0].result);
          this.updateMajParLst(this.response[0].result);
          console.log(data, "Updated");
        }
      }, err => {
        this.SpinnerService.hide();
      });


    }

  }

  updateMajParLst(response) {
    let fileName= localStorage.getItem('Filename')
    let arrayvalue: any;
    let parentpartValue = "";
    let katashikivalue = "";
    let user = localStorage.getItem('workDayId');
    if (this.majorPartAddKatashiki.length == '10') {
      parentpartValue = this.majorPartAddKatashiki
    } else {
      katashikivalue = this.majorPartAddKatashiki
    }
    arrayvalue = this.partCodeList;
    console.log("arrayvalue", arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = arrayvalue[i]
      } else {
        str += ',' + arrayvalue[i];
      }
    }
    console.log("id", response);

    let majPartListId = response;

    console.log("majPartListId", majPartListId);
    let cmts;
    if (this.majorPartAddComments != undefined) {
      cmts = this.majorPartAddComments.replaceAll("'", "''");
    } else {
      cmts = this.majorPartAddComments
    }
    let body = {
      'maj_part_lst_id': majPartListId,
      'namc': this.majorPartAddNamc,
      'family': this.majorPartAddFamily,
      'katashikiCd': katashikivalue,
      'majPartTypId': this.majorPartAddPartType,
      'expMajPartTypQty': this.majorPartAddExpPartQty,
      'prodTyp': this.majorPartAddProdType,
      'partCd': str,
      'comments': cmts,
      'partNo': parentpartValue,
      "screenNo":"NBPC_2220",
      "fileName" :fileName
    }
    this.majPartLstAddService.updateMajParLst(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.errorMessage = 'Failed to Update Major Part List';

        this.SpinnerService.hide();
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }
  addmajorpartlist() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let arrayvalue: any;
    let parentpartValue = "";
    let katashikivalue = ""
    arrayvalue = this.partCodeList;
    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = arrayvalue[i]
      } else {
        str += ',' + arrayvalue[i];
      }
    }
    if (this.majorPartAddKatashiki.length == '10') {
      parentpartValue = this.majorPartAddKatashiki
    } else {
      katashikivalue = this.majorPartAddKatashiki
    }
    let cmts;
    if (this.majorPartAddComments != undefined) {
      cmts = this.majorPartAddComments.replaceAll("'", "''");
    } else {
      cmts = this.majorPartAddComments
    }
    let body = {
      'namc': this.majorPartAddNamc,
      'family': this.majorPartAddFamily,
      'katashikiCd': katashikivalue,
      'majPartTypId': this.majorPartAddPartType,
      'expMajPartTypQty': this.majorPartAddExpPartQty,
      'prodTyp': this.majorPartAddProdType,
      'partCd': str,
      'comments': cmts,
      'partNo': parentpartValue,
      "screenNo":"NBPC_2220",
      "fileName" :fileName
    }
    this.majPartLstAddService.addMajPartLst(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else if (this.response[0].result == 'AlreadyExists') {
        this.errorMessage = 'Data Entered already Exists!';
      } else if (this.response[0].result == 'Invalid') {
        this.errorMessage = "Data Invalid";
        this.SpinnerService.hide();
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  closeAlert() {
    this.errorMessage = '';
  }

  clickerroroff() {
    // if (this.errorMessage != '') {
    //   this.closeAlert();
    // }
  }

}
