import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ExtraneousPartsListAddDialogService } from './extraneous-parts-list-add-dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-extraneous-parts-list-add-dialog',
  templateUrl: './extraneous-parts-list-add-dialog.component.html',
  styleUrls: ['./extraneous-parts-list-add-dialog.component.css']
})
export class ExtraneousPartsListAddDialogComponent implements OnInit {
  [x: string]: any;
  //to main page
  message = "Records Added Successfully";
  errorMessage = "";
  index: any;

  //ngModelValues
  extPart: any = "";
  extDesc: any = "";
  matPart: any = "";
  matPartDesc: any = "";
  compCode: any = "";
  srcRout: any = "";
  destRout: any = "";
  effectiveInDate: any = "";
  effectiveOutDate: any = "";
  comments: any = "";

  constructor(private ExtraneousPartsListAddDialogService: ExtraneousPartsListAddDialogService, public dialogRef: MatDialogRef<ExtraneousPartsListAddDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private readonly SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
  }
  //closealert
  closeAlert() {
    this.errorMessage = "";
  }

  updateExtPart() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    console.log(this.extPart, this.effectiveInDate, this.matPartDesc);

    if (!this.extPart || !this.effectiveInDate) {

      this.errorMessage = "Please enter all mandatory field";

    }
    else if (!this.matPart && !this.matPartDesc && !this.compCode && !this.srcRout && !this.destRout) {
      this.errorMessage = "Please enter all mandatory field";
    }

    else {
      this.errorMessage = "";
      if (this.effectiveOutDate == "") {
        this.effectiveOutDate = "9999-12-31"
      }
      let indateFormat = ""
      if (this.effectiveInDate != "") {
        let d1 = moment(this.effectiveInDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.effectiveInDate = date
      }
      let outdateFormat = ""
      if (this.effectiveOutDate != "") {
        let d1 = moment(this.effectiveOutDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.effectiveOutDate = date
      }
      let body = {
        "mtchgPartKywrdTxt": this.matPart,
        'effDt': indateFormat,
        'exprDt': outdateFormat,
        "screenNo":"NBPC_2250",
        "fileName" :fileName

        
      }
      console.log(body)
      this.SpinnerService.show();
      this.ExtraneousPartsListAddDialogService.CheckExixtsaddExtPart(body).subscribe(data => {
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.extraneousAdd();
        } else if (this.response[0].result == 'Data Already Exists') {
          // this.dialogRef.close(this.response[0].result);
          this.SpinnerService.hide();
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }
  extraneousAdd() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    if (this.effectiveOutDate == "") {
      this.effectiveOutDate = "9999-12-31"
    }
    let indateFormat = ""
    if (this.effectiveInDate != "") {
      let d1 = moment(this.effectiveInDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.effectiveInDate = date
    }
    let outdateFormat = ""
    if (this.effectiveOutDate != "") {
      let d1 = moment(this.effectiveOutDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.effectiveOutDate = date
    }
    let cmts;
    if (this.comments!= undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
    let body = {
      'spclPartTypNm': this.extPart,
      'spclPartTypDesc': this.extDesc,
      'mtchgPartKywrdTxt': this.matPart,
      'mtchgDescKywrdTxt': this.matPartDesc,
      'cmpntCd': this.compCode,
      'srcRtgCd': this.srcRout,
      'destRtgCd': this.destRout,
      'effDt': indateFormat,
      'exprDt': outdateFormat,
      'rmrkTxt': cmts,
      "screenNo":"NBPC_2250",
      "fileName" :fileName

    }
    console.log(body)
    this.SpinnerService.show();
    this.ExtraneousPartsListAddDialogService.addExtPart(body).subscribe(data => {
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else if (this.response[0].result == 'AlreadyExists') {
        // this.dialogRef.close(this.response[0].result);
        this.errorMessage = 'Record already Exists!';
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'effectiveInDate') {
      this.effectiveInDate = "";
    } else if (name == 'effectiveOutDate') {
      this.effectiveOutDate = "";
    }
  }

}
