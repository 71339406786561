<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Model Number:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vModelNumber" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Option:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vOption" disabled></div>
          </div>
        </div>
      </div>

      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>SAP Material ID:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vSapMatId" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
          </div>
        </div>
      </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Version Type:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vVerType" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Version Qualifier:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vVerQual" disabled></div>
        </div>
      </div>
    </div>

    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Version Revision:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vVerRev" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>BoM Effectivity Date:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vBomEffDt" disabled></div>
        </div>
      </div>
    </div>

    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Cost Effectivity Date:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vCostEffDt" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Status:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vStatus" disabled></div>
        </div>
      </div>
    </div>

    <!-- row6 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>NAMC:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vNamc" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Family:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vFamily" disabled></div>
        </div>
      </div>
    </div>

      <!-- row7 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Katashiki:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vKatashiki" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Part Number (Product):</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vPartNo" disabled></div>
          </div>
        </div>
      </div>

    <!-- row8 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Customer:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vCust" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Dest:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vDest" disabled></div>
        </div>
      </div>
    </div>

    <!-- row 9 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Hikiate Status:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vHikiate" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>S/P:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vSP" disabled></div>
        </div>
      </div>
    </div>

    <!-- row10 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>PC Spec:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vPcSpec" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>100% Spec:</label></div>
          <div class=col-8><input type="checkbox" [(ngModel)]="vHundPercentSpec" disabled></div>
        </div>
      </div>
    </div>


    <!-- row11 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Comp PC Spec</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vCompPcSpec" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Comp 100% Spec:</label></div>
          <div class=col-8><input type="checkbox" [(ngModel)]="vCompHundPercentSpec" disabled></div>
        </div>
      </div>
    </div>

    <!-- row12 -->
    <div class="row rowClass">
      <div class="col-2"> <label>Net Spec:</label></div>
      <div class=col-10><input type="text" class="w-100" [(ngModel)]="vNetSpec" disabled>
      </div>
    </div>

    <!-- row13 -->
    <div class="row rowClass">
      <div class="col-2"> <label>On-Hold Status:</label></div>
      <div class=col-10><input type="checkbox" [(ngModel)]="vOnHoldStat" disabled>
      </div>
    </div>

    <!-- row14 -->
    <div class="row rowClass">
      <div class="col-2"> <label>Comments:</label></div>
      <div class=col-10><textarea name="w3review" rows="2" cols="70" [(ngModel)]="vComments" disabled>
          </textarea>
      </div>
    </div>

  </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>