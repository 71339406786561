import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExportToExcelService } from '../common/export-to-excel.service';

@Component({
  selector: 'app-quality-and-exception',
  templateUrl: './quality-and-exception.component.html',
  styleUrls: ['./quality-and-exception.component.css']
})
export class QualityAndExceptionComponent implements OnInit {

  //ngModel value
  rptName:string="";

  //List
  rptNameLst=[
    {
      "rptUrl":"c1UnSlctvty",
      "rptDesc":"C1 Unresolved Selectivity"
    },
    {
      "rptUrl":"c2UnPartSource",
      "rptDesc":"C2 Unresolved Part Source"
    },
    {
      "rptUrl":"c4CirParChildRel",
      "rptDesc":"C4 Circular Parent-Child Relationship"
    },
    {
      "rptUrl":"c5MajPartsDisc",
      "rptDesc":"C5 Major Parts Discrepancy"
    },
    {
      "rptUrl":"c6DupGrpi",
      "rptDesc":"C6 Duplicate GRPI"
    },
    {
      "rptUrl":"c7ParWitCosOrZerValSum",
      "rptDesc":"C7 Parts Without Cost Or Zero Value-Summary"
    },
    {
      "rptUrl":"c8ParWitCosOrZerValDet",
      "rptDesc":"C8 Parts Without Cost Or Zero Value-Detail"
    },
    //{
      //"rptUrl":"c9MiOrConUniProd",
      //"rptDesc":"C9 Missing Or Conflicting Unit Products"
  //  },
    // {
    //   "rptUrl":"c10IcProSusDuMfgView",
    //   "rptDesc":"C10 IC Products Suspended During Mfg View"
    // },
    {
      "rptUrl":"c11PcSpToMfgSPCon",
      "rptDesc":"C11 PC Spec To MFG Spec Conversion"
    },
    // {
    //   "rptUrl":"c12GlRecUnitWiKat",
    //   "rptDesc":"C12 <GL Recon>VIN/Unit With Katashiki/Part Missing from Representative Version"
    // },
    {
      "rptUrl":"c13UsVarCal",
      "rptDesc":"C13 Usage Variance Calculation"
    },
    // {
    //   "rptUrl":"i1PartChngWithinGrpi",
    //   "rptDesc":"I1 Part Changes Within GRPI-PSMS"
    // },
    // {
    //   "rptUrl":"i2GrpiwithinFam",
    //   "rptDesc":"I2 GRPI Within Family-PSMS"
    // },
    // {
    //   "rptUrl":"i3SpecChngWithinKatashiki",
    //   "rptDesc":"I3 Spec Change Within Katashiki -PSMS"
    // },
    // {
    //   "rptUrl":"i4KatashikiChngWithinFam",
    //   "rptDesc":"I4 Katashiki Change Within Family-PSMS"
    // },
    // {
    //   "rptUrl":"i5HikiateFamComb",
    //   "rptDesc":"I5 New Hikiate Status And Family Combination-PSMS"
    // },
    // {
    //   "rptUrl":"i6MismRtngParChild",
    //   "rptDesc":"I6 Mismatched Routing Between Parent-Child-PSMS"
    // },
    // // {
    //   "rptUrl":"i7ProdPartStdCost",
    //   "rptDesc":"I7 <Cost>Production Parts without Standard Cost"
    // },
    // {
    //   "rptUrl":"i8StandCostChanges",
    //   "rptDesc":"I8 <Cost> Standard Cost Changes"
    // },
    // {
    //   "rptUrl":"i9PartsWithoutFirmSum",
    //   "rptDesc":"I9 Parts Without Firm PO - Summary "
    // },
    // {
    //   "rptUrl":"i10PartsWithoutFirmDtl",
    //   "rptDesc":"I10 Parts Without Firm PO - Detail "
    // },
    // {
    //   "rptUrl":"i11PartNumConversion",
    //   "rptDesc":"I11 Part Number Conversion - Source "
    // },
    // {
    //   "rptUrl":"i12MissSupplierNm",
    //   "rptDesc":"I12 Missing Supplier Name - Source "
    // },
    // {
    //   "rptUrl":"i13MissBuyerNm",
    //   "rptDesc":"I13 Missing Buyer Name - WARP "
    // },
    // {
    //   "rptUrl":"i14InvalidSupplier",
    //   "rptDesc":"I14 Invalid Supplier - Source "
    // },
    // {
    //   "rptUrl":"i15DupOrMissVinInfo",
    //   "rptDesc":"I15 Duplicate or Missing VIN Information"
    // },
    // {
    //   "rptUrl":"i16VINRepVersion",
    //   "rptDesc":"I16 <GL Recon> VIN with PC Specs Missing from Representative Version"
    // },
    // {
    //   "rptUrl":"i17DuplSerNum",
    //   "rptDesc":"I17 Duplicate Serial Number of Unit"
    // },
    // {
    //   "rptUrl":"i18ProdMissRepVersion",
    //   "rptDesc":"I18 <V count LE Summary> Products Missing from Representative Version"
    // },
    // {
    //   "rptUrl":"i19InhouseParts",
    //   "rptDesc":"I19 Inhouse Parts Without Structure"
    // },
    // {
    //   "rptUrl":"i20HundPerSpecChng",
    //   "rptDesc":"I20 100% Spec Changes - VDW"
    // },
    // {
    //   "rptUrl":"i21VinIcPartInVinBoM",
    //   "rptDesc":"I21 VINs Where Serialized Part Does Not Match IC Part in VIN BoM"
    // },
    // {
    //   "rptUrl":"i22PartsWithMulSupp",
    //   "rptDesc":"I22 Parts with Multiple Suppliers"
    // },
    // {
    //   "rptUrl":"i23MissCombBlankHikiate",
    //   "rptDesc":"I23 Missing SSN-MY-NAMC Combination or Blank Hikiate"
    // },
    // {
    //   "rptUrl":"i24MissPCSpecGrpi",
    //   "rptDesc":"I24 Missing PC Spec From GRPI"
    // },
    {
      "rptUrl":"i27RecordsCntFromDataSrc",
      "rptDesc":"I27 Records Counts from Data Sources"
    },
   
  ]

  constructor( private router: Router,private exportService:ExportToExcelService) { }

  ngOnInit(): void {
  }
  routeToExceptioRpt()
  {
    console.info(this.rptName)
    this.rptNameLst.forEach(row => {
      if(row.rptUrl==this.rptName)
      {
       this.exportService.reportName=row.rptDesc;
       if(row.rptDesc.startsWith("C"))
       {
        this.exportService.severity="Critical"
       }
       else{
         this.exportService.severity="Informational"
       }
      }
    });
    this.router.navigateByUrl('/qualityExceptRpt/(quality:'+this.rptName+')');
  }

}
