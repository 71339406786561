import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { FamilyProjectNamcMultieditDialogService } from './family-project-namc-multiedit-dialog.service';
import { DialogData } from '../dialog-data/dialog-data';


@Component({
  selector: 'app-family-project-namc-multiedit-dialog',
  templateUrl: './family-project-namc-multiedit-dialog.component.html',
  styleUrls: ['./family-project-namc-multiedit-dialog.component.css']
})
export class FamilyProjectNamcMultieditDialogComponent implements OnInit {

  spinnerMessage = "Please Wait...";
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  [x: string]: any;
  //to main page
  message = "Records Edited Successfully";
  index: any;
  errorMessage = "";



  //ngModelValues
  fProdDateIn: any = '';
  fProdDateOut: any = ''
  fEffDateIn: any = '';
  fEffDateOut: any = '';
  fComments: any;
  //response after insert in a record
  response: any;
  fFam_gnrt_proj_relshp_id: any;

  constructor(private multiEditfamProNamcService: FamilyProjectNamcMultieditDialogService, private readonly SpinnerService: NgxSpinnerService, public dialogRef: MatDialogRef<FamilyProjectNamcMultieditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  ngOnInit(): void {

    let arrayvalue: any;
    arrayvalue = this.data;

    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = "'" + arrayvalue[i].fam_gnrt_proj_relshp_id + "'"
      } else {
        str += ',' + "'" + arrayvalue[i].fam_gnrt_proj_relshp_id + "'";
      }

      this.fFam_gnrt_proj_relshp_id = str;
    }
  }


  //closealert
  closeAlert() {
    this.errorMessage = "";
  }


  updateFamProNamc() {
    let user = localStorage.getItem('workDayId');
    let fileName= localStorage.getItem('Filename')

    console.log(this.fEffDateIn, this.fEffDateOut, this.fProdDateIn, this.fProdDateOut);


    if (!this.fProdDateIn && !this.fProdDateOut && !this.fEffDateIn && !this.fEffDateOut && !this.destRout) {
      this.errorMessage = "Please enter all mandatory field";
    }

    else if (this.fEffDateIn && this.fEffDateOut &&
      this.pipe.transform(this.fEffDateIn, 'yyyy-MM-dd') > this.pipe.transform(this.fEffDateOut, 'yyyy-MM-dd')) {

      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";

    }
    else {
      if (this.fEffDateOut == "") {
        this.fEffDateOut = "9999-12-31"
      }
      this.errorMessage = "";
      let indateFormat = ''; let outdateFormat = '';
      if (this.fEffDateIn != "") {
        let d1 = moment(this.fEffDateIn).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.fEffDateIn = date
      }
      if (this.fEffDateOut != "") {
        let d1 = moment(this.fEffDateOut).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.fEffDateOut = date
      }
      let prodDateINFormat = ''; let prodDateOutFormat = '';
      if (this.fProdDateIn != "") {
        let d1 = moment(this.fProdDateIn).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        prodDateINFormat = moment(date).format('YYYY-MM-DD')
        this.fProdDateIn = date

      }
      if (this.fProdDateOut != "") {
        let d1 = moment(this.fProdDateOut).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        prodDateOutFormat = moment(date).format('YYYY-MM-DD')
        this.fProdDateOut = date
      }
      let cmts;
    if (this.fComments!= undefined) {
      cmts = this.fComments.replaceAll("'", "''");
    } else {
      cmts = this.fComments
    }
      let body = {
        'prod_strt_dt': prodDateINFormat,
        'prod_end_dt': prodDateOutFormat,
        'extInCd': indateFormat,
        'extOutCd': outdateFormat,
        'rmrk_txt': cmts,
        'famGnrtProjRelshpId': this.fFam_gnrt_proj_relshp_id,
        "screenNo":"NBPC_9120",
        "fileName" :fileName
      }
      this.SpinnerService.show();
      this.multiEditfamProNamcService.multiEditFamProNamc(body).subscribe(data => {
        this.response = data.payload;
        this.SpinnerService.hide();
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          // this.dialogRef.close(this.response[0].result);
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });

    }
  }


  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'fProdDateIn') {
      this.fProdDateIn = "";
    } else if (name == 'fProdDateOut') {
      this.fProdDateOut = "";
    }
    else if (name == 'fEffDateIn') {
      this.fEffDateIn = "";
    }
    else if (name == 'fEffDateOut') {
      this.fEffDateOut = "";
    }
  }
}




