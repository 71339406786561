<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
<h2 mat-dialog-title cdkDragHandle >Upload Document <mat-icon class="close-icon"  (click)="cancel()" >close</mat-icon></h2>
<mat-dialog-content class="mat-typography"  >
    <div class="container">
        <div class="row">
            <div class="col-12">
            Browse to the document you intend to upload.
            </div>
        </div>
        <div class="row">
            <div class="col-12" >
                <label>Name:</label>
                  <input type="file" id="browse" style="display: none" #file accept=".csv,.xlsx,.xls" (change)="uploadFile($event)" />
                <input type="text" id="filename" readonly="true" [(ngModel)]="fileName"/>
                <input type="button" value="Browse..." id="fakeBrowse"  (click)="file.click();"/>
            </div>
        </div>
      <div class="row" *ngIf="!fileFormat">
            <div class="col-12">
               File types supported are .xlxs,.xls and .csv only and
               File Size should be below 2MB
            </div>
        </div>
        <div class="row">

            <div *ngIf="fileFormat" class="col-11">
                <span class="fst-italicUpl">File types supported are .xlxs,.xls and .csv only</span>
            </div>
            <div *ngIf="fileundefined" class="col-11">
                <span class="fst-italicUpl">Please Upload .xlsx/.xls/.csv File</span>
            </div>
            <div *ngIf="showAttachErrorMsg" class="col-11">
                <span class="fst-italicUpl">File Size supported below 2MB.</span>
            </div>
        </div>
      
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button cdkFocusInitial (click)="getProcStatus()">Upload</button>
    <button mat-button (click)="cancel()" >Cancel</button>
  </mat-dialog-actions>

</ng-container>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>