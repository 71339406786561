<span *ngIf="accessEnable">

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
    {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong>Search</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>Currency Code From</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="currencyCodeFrom" name="currencyCodeFrom"
          (selectionChange)='getCostTypeByCurFrom()'>
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let currencyCodeFrom of currencyCodeFromLst" [value]="currencyCodeFrom.crncy_1_cd">
            {{currencyCodeFrom.crncy_1_cd}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Currency Code To</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="currencyCodeTo" name="currencyCodeTo"
          (selectionChange)='getCostTypeByCur()'>
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let currencyCodeTo of currencyCodeToLst" [value]="currencyCodeTo.crncy_2_cd">
            {{currencyCodeTo.crncy_2_cd}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Cost Type</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="costType" name="costType">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let costType of costTypeLst" [value]="costType.exchng_rt_typ">{{costType.exchng_rt_typ}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Effective In Date</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="effectiveInDate" (dateChange)='getExchangeRateByDt()'>
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effectiveInDate')">clear</mat-icon>
    </mat-form-field>

      <mat-form-field class="picker">
        <mat-label>Effective Out Date</mat-label>
        <input matInput [matDatepicker]="outPicker" [(ngModel)]="effectiveOutDate" (dateChange)='getExchangeRateByDt()'>
        <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
        <mat-datepicker #outPicker></mat-datepicker>
        <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effectiveOutDate')">clear
        </mat-icon>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Exchange Rate</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="exchangeRate" name="exchangeRate">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let exchangeRate of exchangeRateLst" [value]="exchangeRate.cur_exchng_rt">
            {{exchangeRate.cur_exchng_rt}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="status" name="status">

          <mat-option value="Active">Active</mat-option>
          <mat-option value="Inactive">Inactive</mat-option>
          <mat-option value="All">All</mat-option>
        </mat-select>
      </mat-form-field>



      <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
      <button mat-button style="float: right;" (click)="search()">Search</button>

    </mat-expansion-panel>
  </mat-accordion>

  <div class="d-flex">
    <button mat-raised-button (click)="openAddDialog()">Add </button>
    <button mat-raised-button (click)="openEditDialog()">Edit </button>


  </div>

  <!-- <mat-card> -->
  <ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
    [footerHeight]="40" rowHeight="30" [limit]="limitSelected" [selected]="selected"
    [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
    (activate)="onActivate($event)" (select)="onSelect($event)"
    [ngClass]="{'hideerror': showdata, 'showerror': !showdata}">
    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
        let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
        <div class="page-count">
          {{selectedCount}} selected / {{rowCount}} total
        </div>
        <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
          (change)="table.onFooterPage($event)">
        </datatable-pager>
        <div class="test">
          <select [(ngModel)]="limitSelected">
            <option>50</option>
            <option>100</option>
            <option>500</option>
          </select>
          <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
            (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
            {{(rowCount)}}

        </div>
      </div>
    </ng-template>
  </ngx-datatable-footer>
  <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false"
    [headerCheckboxable]="true" [checkboxable]="true">
  </ngx-datatable-column>

  <ngx-datatable-column name="Currency Code From" prop="crncy_1_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Currency Code To" prop="crncy_2_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Cost Type" prop="exchng_rt_typ"></ngx-datatable-column>
  <ngx-datatable-column name="Effective In Date" prop="eff_dt"></ngx-datatable-column>
  <ngx-datatable-column name="Effective Out Date" prop="expr_dt"></ngx-datatable-column>
  <ngx-datatable-column name="Exchange Rate" prop="cur_exchng_rt"></ngx-datatable-column>
  <ngx-datatable-column name="Status" prop="inactv_flg"></ngx-datatable-column>
  <ngx-datatable-column [width]="350" name="Comments" prop="rmrk_txt"></ngx-datatable-column>

  </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>
