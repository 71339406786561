<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Summary Report <mat-icon class="close-icon" [mat-dialog-close]="true">close
    </mat-icon>
  </h2>

  <mat-dialog-content class="mat-typography">
    <div class="container">
      <div class="d-flex">
        <span class="fst-italic" *ngIf="productTyp!='onHold'">All Products are displayed.</span>
        <span class="fst-italic" *ngIf="productTyp=='onHold'">Only on-hold products are displayed</span>

        <span class="ms-auto">
          <button (click)="Exportexcel()">Export Excel</button>
          <button (click)="print()">Print</button>
          <button [mat-dialog-close]="true">Close</button></span>
      </div>

      <div class="table-container mat-elevation-z8" [ngClass]="{'display-none': gridList.length == 0}" #genRepVer
        id="summRep">
        <table aria-describedby="table" mat-table [dataSource]="dataSource" multiTemplateDataRows>
          <ng-container matColumnDef="version_name">
            <th scope="col"  mat-header-cell *matHeaderCellDef> Version </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex"
              [ngClass]="{'statusBold': (element.prod_stat_typ_cd == '') && (element.bom_proc_stat_typ_desc == '') && (element.namc_nm == '')}">
              {{element.version_name}} </td>
          </ng-container>
          <ng-container matColumnDef="prod_stat_typ_cd">
            <th scope="col"  mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex"
              [ngClass]="{'statusBold': (element.version_name == '') && (element.bom_proc_stat_typ_desc == '') && (element.namc_nm == '')}">
              {{element.prod_stat_typ_cd}} </td>
          </ng-container>
          <ng-container matColumnDef="bom_proc_stat_typ_desc">
            <th scope="col"  mat-header-cell *matHeaderCellDef> Status Description </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex"
              [ngClass]="{'TotalColor': (element.version_name == '') && (element.prod_stat_typ_cd == '') && (element.namc_nm == '')}">
              {{element.bom_proc_stat_typ_desc}} </td>
          </ng-container>
          <ng-container matColumnDef="namc_nm">
            <th scope="col"  mat-header-cell *matHeaderCellDef> Namc </th>
            <td mat-cell *matCellDef="let element;"
              [ngClass]="{'TotalColor': (element.version_name == '') && (element.prod_stat_typ_cd == '') && (element.namc_nm == '')}">
              {{element.namc_nm}} </td>
          </ng-container>
          <ng-container matColumnDef="count">
            <th scope="col"  mat-header-cell *matHeaderCellDef> Total </th>
            <td mat-cell *matCellDef="let element;"
              [ngClass]="{'TotalColor': (element.version_name == '') && (element.prod_stat_typ_cd == '') && (element.namc_nm == '')}">
              {{element.count}}</td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;let index = dataIndex;"
            [ngClass]="{alternate: index % 2 == 0 }"></tr>
        </table>
      </div>
      <div style="text-align: center;" *ngIf="!showdata">
        <h5 style="color: red;margin-top: 25px;">No Records Found</h5>
      </div>

    </div>
  </mat-dialog-content>
</ng-container>