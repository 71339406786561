import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { MajorPartTypeAddDialogService } from './major-part-type-add-dialog.service';

@Component({
  selector: 'app-major-part-type-add-dialog',
  templateUrl: './major-part-type-add-dialog.component.html',
  styleUrls: ['./major-part-type-add-dialog.component.css']
})
export class MajorPartTypeAddDialogComponent implements OnInit {

  //errormessage
  errorMessage: string = "";

  spinnerMessage = "Please Wait...";


  //ngModel
  majorPartAddPartType: any;
  majorPartAddProdType: any;
  majorPartAddActive: any = true;
  majorPartComments: any;

  //lst
  prodTypLst = [];

  //response from backend after insert/update records
  response: any;

  //to main page
  message = "Record Added Successfully";

  constructor(public dialogRef: MatDialogRef<MajorPartTypeAddDialogComponent>,
    private readonly SpinnerService: NgxSpinnerService, private majPartTypAddService: MajorPartTypeAddDialogService) { }

  ngOnInit(): void {
    //majProdTyp
    this.SpinnerService.show();
    this.majPartTypAddService.getProdTyp().subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.prodTypLst = data.payload;
    });

  }

  //save
  submitMajPart(): void {
    let fileName= localStorage.getItem('Filename')
    if (!this.majorPartAddPartType || !this.majorPartAddProdType) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else {
      this.errorMessage = "";
      let body = {
        'majPartTyp': this.majorPartAddPartType,
        'ProdTyp': this.majorPartAddProdType,
        "screenNo":"NBPC_2221",
        "fileName" :fileName
      }
      this.SpinnerService.show();
      this.majPartTypAddService.ChecKExistsMajPartTyp(body).subscribe(data => {
        console.log(data);
        this.response = data.payload;
        if (this.response[0].result == 'Data Already Exists') {
          this.errorMessage = "Major Part Type <" + this.majorPartAddPartType + "> and Product Type <" + this.majorPartAddProdType + " > combination already exists";
          this.SpinnerService.hide();
        } else if (this.response[0].result == 'success') {
          this.AddMajorPartType();
        } else if (this.response[0].result == 'Invalid') {
          this.errorMessage = "Data Invalid";
          this.SpinnerService.hide();
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }

  }

  AddMajorPartType() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    if (this.majorPartComments == undefined) {
      this.majorPartComments = ""
    }
    this.errorMessage = "";
    let cmts;
    if (this.majorPartComments!= undefined) {
      cmts = this.majorPartComments.replaceAll("'", "''");
    } else {
      cmts = this.majorPartComments
    }
    let body = {
      'majPartTyp': this.majorPartAddPartType,
      'ProdTyp': this.majorPartAddProdType,
      'act': this.majorPartAddActive == true ? "N" : "Y",
      'comments': cmts,
      "screenNo":"NBPC_2221",
      "fileName" :fileName      
    }
    console.log(body);
    this.SpinnerService.show();
    this.majPartTypAddService.addMajPartTyp(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.errorMessage = "Data not Added";
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  closeAlert() {
    this.errorMessage = '';
  }

}
