import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ManualAdjustmentRuleService } from './manual-adjustment-rule.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { BackendData } from '../dialog-data/backend-data';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ManualAdjustmentRuleAddDialogComponent } from '../manual-adjustment-rule-add-dialog/manual-adjustment-rule-add-dialog.component';
import { ManualAdjustmentRuleDeleteDialogComponent } from '../manual-adjustment-rule-delete-dialog/manual-adjustment-rule-delete-dialog.component';
import { ManualAdjustmentRuleEditDialogComponent } from '../manual-adjustment-rule-edit-dialog/manual-adjustment-rule-edit-dialog.component';
import { ManualAdjustmentRuleViewDialogComponent } from '../manual-adjustment-rule-view-dialog/manual-adjustment-rule-view-dialog.component';
import { ManualAdjustmentRuleUploadDialogComponent } from '../manual-adjustment-rule-upload-dialog/manual-adjustment-rule-upload-dialog.component';
import { ManualAdjustmentRuleCorrectBomsComponent } from '../manual-adjustment-rule-correct-boms/manual-adjustment-rule-correct-boms.component';
import { GlobalService } from '../global.service';

import * as S3 from 'aws-sdk/clients/s3';


@Component({
  selector: 'app-manual-adjustment-rule',
  templateUrl: './manual-adjustment-rule.component.html',
  styleUrls: ['./manual-adjustment-rule.component.css']
})
export class ManualAdjustmentRuleComponent implements OnInit {
  [x: string]: any;
  sameLengthRows: boolean = false;
  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';

  // filename 
  fileName = 'ManualAdjRule';
  exportData = [];
  sheetName = [];
  errorSheetName = ['MAN_ADJ_ERROR'];
  errorFileName = 'ManAdjError';
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //ngModel values for search
  step = 0;
  ruleType: any = "";
  versionName: any = "";
  namc: any = "";
  sp: any = "";
  cmpRouting: any = "";
  actUnitCst: any = "";
  statusDesc: any = "";
  manAdjRuleId: any = "";
  createdBy: any = "";
  comments: any = "";
  netSpec: any = "";
  dest: any = "";
  family: any = "";
  katasPart: any = "";
  grpi: any = "";
  partNo: any = "";
  ruleStatus: any = "";
  createFromDt: any = "";
  createToDt: any = "";
  bomEffFrom: any = "";
  bomEffTo: any = "";


  spinnerMessage = "Please Wait...";
  // create Bom from Backend
  totCountOfAffProdBom: string;
  noOfVinOrSerAff: string;
  noOfBomAff: string;
  // Add-Edit dialog box
  partDesc: string;
  actCurrencyCd: string;
  // List
  ruleTypeLst = [];
  versionNameLst = [];
  namcLst = [];
  famLst = [];
  katasPartLst = [];
  grpiLst = [];
  partNoLst = [];
  ruleStatusLst = [];

  //ngx-dataTable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  dataFromBackend = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;

  showdata: boolean = true;
  columns = [
    { name: 'ruleType', prop: 'man_adjstmnt_rule_typ_nm' }, { name: 'versionName', prop: 'version_name' }, { name: 'namc', prop: 'namc_cd' },
    { name: 'family', prop: 'fam_cd' }, { name: 'katasPart', prop: 'katashiki_cd' }, { name: 'grpi', prop: 'grpi_cd' },
    { name: 'partNo', prop: 'child_part_no' }, { name: 'ruleStatus', prop: 'rule_status' }, { name: 'createFromDt', prop: 'last_exec_ts' },
    { name: 'createToDt', prop: 'create_ts' }, { name: 'bomEffFrom', prop: 'bom_eff_dt_from' }, { name: 'bomEffTo', prop: 'bom_eff_dt_to' },
    { name: 'sp', prop: 'base_optn_typ_cd' }, { name: 'netSpec', prop: 'net_spec_cd_comb_txt' }, { name: 'dest', prop: 'dest_ctry_id_comb_txt' }, { name: 'qtyPerPar', prop: 'per_veh_qty' },
    { name: 'cmpRouting', prop: 'cmplt_rtg_cd' }, { name: 'actUnitCst', prop: 'actl_unit_cost_amt' }, { name: 'statusDesc', prop: 'man_adjstmnt_rule_stat_desc' },
    { name: 'manAdjRuleId', prop: 'man_adjstmnt_rule_typ_id' }, { name: 'comments', prop: 'rmrk_txt' }, { name: 'createdBy', prop: 'create_by_id' }
  ];
  ColumnMode = ColumnMode;
  roleAccess: any = [];
   accessEnable: boolean;
  Roledata: any;
  constructor(private readonly SpinnerService: NgxSpinnerService, private manualAdjRuleService: ManualAdjustmentRuleService, public dialog: MatDialog,
    private dialogData: DialogData, private exportService: ExportToExcelService, private backendData: BackendData, public globalrole: GlobalService, ) { }

  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();
  }
  onloadfn(){
    //to get version Name
    this.manualAdjRuleService.getVersionNameBpMar(this.ruleType).subscribe(data => {
      console.log(data);
      this.versionNameLst = data.payload;
    });

    //to get namc
    this.manualAdjRuleService.getNamcBpMar(this.ruleType, this.versionName).subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });

    //to get family
    this.manualAdjRuleService.getFamilyBpMar(this.ruleType, this.versionName, this.namc).subscribe(data => {
      console.log(data);
      this.famLst = data.payload;
    });

    //to get rule type
    this.manualAdjRuleService.getRuleTypeBpMar().subscribe(data => {
      console.log(data);
      this.ruleTypeLst = data.payload;
    });

    //to get grpi
    this.manualAdjRuleService.getGrpiBpMar(this.namc, this.family, this.katasPart).subscribe(data => {
      console.log(data);
      this.grpiLst = data.payload;
    });

    //to get part number
    this.manualAdjRuleService.getPartNoMar(this.namc, this.family, this.katasPart, this.grpi,).subscribe(data => {
      console.log(data);
      this.partNumberLst = data.payload;
    });

    //to get rule Status
    this.manualAdjRuleService.getRuleStatusMar(this.namc, this.family, this.katasPart, this.grpi, this.partNo).subscribe(data => {
      console.log(data);
      this.ruleStatusLst = data.payload;
    });

    //getkatashiki
    // this.getkatashiki();

  }
  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    this.selected = selected
    if (this.selected.length == selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length == 0) {
      this.sameLengthRows = false
    }
    console.log('Select Event', selected, this.selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }
  openAddDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(ManualAdjustmentRuleAddDialogComponent, {
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');

        if (result == 'success') {
          // this.resetAll("add");
          this.getGridData("add")
          this.successMessage = "Records Added Successfully"
          this.selected = []
        }
        else if (result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!'
          this.globalrole.goToTop();
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  correctBoms(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Create')) {
      if (this.selected.length >= 1) {
        let arraydata = []
        this.selected.filter((res, index, arr) => {
          if ((res.rule_status == 'P-PROCESSING') || (res.rule_status == 'R-READY_TO_APPLY')) {
            arraydata.push(res.rule_status);
            this.bom_typ_cd = "R"
          }
        })
        this.sendToCorrectBom(this.selected)
        // this.backendToCorrectBom(this.dataFromBackend);
        if (arraydata.length == 0) {
          const dialogRef = this.dialog.open(ManualAdjustmentRuleCorrectBomsComponent, {
            disableClose: true,
            data: this.selected

          });
          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            if (result == true) {
              // this.resetAll("add");
              this.getGridData("add")
              this.errorMessage = ""
            } else {
              this.errorMessage = result
            }
          });
        } else {
          this.errorMessage = 'Records in "P - PROCESSING" and "R - READY_TO_APPLY " status cannot be selected for Correct BoMs'
          this.globalrole.goToTop();
        }
      }
      else {
        this.errorMessage = "Please select a row to Correct BoMs";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }

  openEditDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(ManualAdjustmentRuleEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.selected = [];
          if (result == 'success') {
            // this.resetAll("add");
            this.getGridData("add")
            this.successMessage = "Data Updated Successfully";
            this.selected = []
          }
        });
      }
      else {
        if (this.sameLengthRows == true) {
          this.errorMessage = "Please select only one row to edit"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows == false) {
          this.errorMessage = "Please select a row to edit"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }
  openViewDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(ManualAdjustmentRuleViewDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');

        });
      }
      else {
        this.errorMessage = "Please select a row to view"
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  openDeleteDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    if (this.roleAccess.includes('Delete')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        let arraydata = []
        this.selected.filter((res, index, arr) => {
          if ((res.rule_status == 'X-NOT_APPLIED') || (res.rule_status == 'N-NEW')) {
            arraydata.push(res);
          }
        })
        if (arraydata.length > 0) {
          console.log(arraydata, arraydata.length)
          const dialogRef = this.dialog.open(ManualAdjustmentRuleDeleteDialogComponent, {
            disableClose: true,
            width: '50%',
            data: this.dialogData
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result == 'success') {
              // this.resetAll("add");
              this.getGridData("add")
              this.successMessage = "Record deleted successfully";
              this.selected = []
            }
          });
        } else {
          this.errorMessage = "Please select only 'N - NEW' or 'X - NOT_APPLIED' rule to delete"
          this.globalrole.goToTop();
        }
      }
      else {
        this.errorMessage = "Please select a row to delete"
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'delete' operation";
      this.globalrole.goToTop();
    }
  }


  openUploadDialog(): void {
    this.successMessage = ''
    this.errorMessage = ''
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(ManualAdjustmentRuleUploadDialogComponent, {
        disableClose: true,
        // width: '50%'

      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if (result == 'success') {
          this.getGridData("add");
          this.successMessage = 'Upload Process Started, Uploaded Information will be share via Mail'
        }
        else if (result == 'cancel') {
          this.successMessage = ''
          this.errorMessage = ''
        }
        else {
          this.errorMessage = result
          this.globalrole.goToTop();
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  exportFile(): void {

    this.rows.forEach(row => {
      let obj = {
        "Rule Type": row.man_adjstmnt_rule_typ_nm,
        "Version Name": row.version_name,
        "BOM Effective From": row.bom_eff_dt_from,
        "BOM Effective To": row.bom_eff_dt_to,
        "NAMC": row.namc_cd,
        "Family": row.fam_cd,
        "S/P": row.base_optn_typ_cd,
        "Net Spec": row.net_spec_cd_comb_txt,
        "Katashiki/Part": row.katashiki_cd,
        "GRPI": row.grpi_cd,
        "Dest": row.dest_ctry_id_comb_txt,
        "Part Number": row.child_part_no,
        "Qty Per Parent": row.per_veh_qty,
        "Complete Routing": row.cmplt_rtg_cd,
        "ACT Unit Cost": row.actl_unit_cost_amt,
        "ACT Currency Code": row.actl_cost_crncy_cd,
        "Rule Status": row.rule_status,
        "Status Description": row.man_adjstmnt_rule_stat_desc,
        "Manual Adjustment Rule Id": row.man_adjstmnt_rule_typ_id,
        "Comments": row.rmrk_txt,
        "Create Date": row.create_ts,
        "Last Rule Process Date": row.last_exec_ts,
        "Create By": row.create_by_id
      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }
  resetAll(data): void {
    // this.selected=[]
    this.ruleType = '';
    this.versionName = '';
    this.namc = '';
    this.family = '';
    this.katasPart = '';
    this.katasPartLst = [];
    this.grpi = '';
    this.partNo = '';
    this.ruleStatus = '';
    this.createFromDt = '';
    this.createToDt = '';
    this.errorMessage = '';
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }


  getkatashiki() {
    this.manualAdjRuleService.getkatashikiMar(this.namc, this.family).subscribe(data => {
      console.log(data);
      this.katasPartLst = data.payload;
    });
  }

  //on change
  getVerNameByRulType(): void {
    if (this.ruleType == undefined) {
      this.ruleType = ""
    }
    this.SpinnerService.show();
    this.manualAdjRuleService.getVersionNameBpMar(this.ruleType).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.versionNameLst = data.payload;
      this.versionName = ""
      this.namc = ""
      this.family = ""
      this.katasPart = ""
      this.grpi = ""
      this.partNo = ""
      this.ruleStatus = ""
    });
  }

  getNamcByVerName(): void {
    if (this.ruleType == undefined) {
      this.ruleType = ""
    }
    if (this.versionName == undefined) {
      this.versionName = ""
    }
    this.manualAdjRuleService.getNamcBpMar(this.ruleType, this.versionName).subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
      this.namc = ""
      this.family = ""
      this.katasPart = ""
      this.grpi = ""
      this.partNo = ""
    });
  }

  getFamCdByNamc(): void {
    if (this.ruleType == undefined) {
      this.ruleType = ""
    }
    if (this.versionName == undefined) {
      this.versionName = ""
    }
    if (this.namc == undefined) {
      this.namc = ""
    }

    this.manualAdjRuleService.getFamilyBpMar(this.ruleType, this.versionName, this.namc).subscribe(data => {
      console.log(data);
      this.famLst = data.payload;
      this.family = ""
    });
  }
  getKatashikiByFamCd(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.family == undefined) {
      this.family = ""
    }
    if (this.family != "") {
      this.manualAdjRuleService.getkatashikiMar(this.namc, this.family).subscribe(data => {
        console.log(data);
        this.katasPartLst = data.payload;
        this.katasPart = ""
      });
    } else {
      this.katasPartLst = []
      this.katasPart = ""
    }
  }

  getGrpiByKatas(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.family == undefined) {
      this.family = ""
    }
    if (this.katasPart == undefined) {
      this.katasPart = ""
    }
    this.manualAdjRuleService.getGrpiBpMar(this.namc, this.family, this.katasPart).subscribe(data => {
      console.log(data);
      this.grpiLst = data.payload;
      this.grpi = ""
    });
  }

  getPartNoByGrpi(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.family == undefined) {
      this.family = ""
    }
    if (this.katasPart == undefined) {
      this.katasPart = ""
    }
    if (this.grpi == undefined) {
      this.grpi = ""
    }
    this.manualAdjRuleService.getPartNoMar(this.namc, this.family, this.katasPart, this.grpi).subscribe(data => {
      console.log(data);
      this.partNumberLst = data.payload;
      this.partNo = ""
    });
  }

  getRuleStatByPartNo(): void {
    if (this.namc == undefined) {
      this.namc = ""
    }
    if (this.family == undefined) {
      this.family = ""
    }
    if (this.katasPart == undefined) {
      this.katasPart = ""
    }
    if (this.grpi == undefined) {
      this.grpi = ""
    }
    if (this.partNo == undefined) {
      this.partNo = ""
    }
    this.manualAdjRuleService.getRuleStatusMar(this.namc, this.family, this.katasPart, this.grpi, this.partNo).subscribe(data => {
      console.log(data);
      this.ruleStatusLst = data.payload;
      this.ruleStatus = ""
    });
  }

  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.man_adjstmnt_rule_def_id = row.man_adjstmnt_rule_def_id
      this.dialogData.ruleType = row.man_adjstmnt_rule_typ_nm
      this.dialogData.versionName = row.version_name
      this.dialogData.namc = row.namc_cd
      this.dialogData.family = row.fam_cd
      this.dialogData.katasPart = row.katashiki_cd
      this.dialogData.grpi = row.grpi_cd
      this.dialogData.partNo = row.child_part_no
      this.dialogData.ruleStatus = row.rule_status
      this.dialogData.createFromDt = this.pipe.transform(row.create_ts, 'yyyy-MM-dd');
      this.dialogData.createToDt = this.pipe.transform(row.last_exec_ts, 'yyyy-MM-dd');
      this.dialogData.bomEffFrom = row.bom_eff_dt_from
      this.dialogData.bomEffTo = row.bom_eff_dt_to
      this.dialogData.sp = row.base_optn_typ_cd
      this.dialogData.netSpec = row.net_spec_cd_comb_txt
      this.dialogData.dest = row.dest_ctry_id_comb_txt
      this.dialogData.qtyPerPar = row.per_veh_qty
      this.dialogData.cmpRouting = row.cmplt_rtg_cd
      this.dialogData.actUnitCst = row.actl_unit_cost_amt
      this.dialogData.statusDesc = row.man_adjstmnt_rule_stat_desc
      this.dialogData.createdBy = row.create_by_id
      this.dialogData.manAdjRuleId = row.manAdjRuleId
      this.dialogData.comments = row.rmrk_txt
      this.dialogData.actCurrencyCd = row.actCurrencyCd
      this.dialogData.partDesc = row.partDesc


    });

  }

  search() {
    this.errorMessage = "";
    this.successMessage = "";
    this.getGridData("initial");
  }

  getGridData(data) {
    this.selected=[]
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.ruleType == undefined) {
        this.ruleType = ""
      }
      if (this.versionName == undefined) {
        this.versionName = ""
      }
      if (this.namc == undefined) {
        this.namc = ""
      }
      if (this.family == undefined) {
        this.family = ""
      }
      if (this.katasPart == undefined) {
        this.katasPart = ""
      }
      if (this.grpi == undefined) {
        this.grpi = ""
      }
      if (this.partNo == undefined) {
        this.partNo = ""
      }
      if (this.ruleStatus == undefined) {
        this.ruleStatus = ""
      }
      if (this.createFromDt != "") {
        this.createFromDt = this.pipe.transform(this.createFromDt, 'yyyy-MM-dd')
      }
      if (this.createToDt != "") {
        this.createToDt = this.pipe.transform(this.createToDt, 'yyyy-MM-dd')
      }
      let ruletypNm = '';
      for (let i = 0; i < this.ruleTypeLst.length; i++) {
        if (this.ruleType == this.ruleTypeLst[i].man_adjstmnt_rule_typ_id) {
          ruletypNm = this.ruleTypeLst[i].man_adjstmnt_rule_typ_nm
          break;
        }
      }
      let newDate = new Date();
      let date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
      let filename = 'Manual-adj-rule/manAdjRulSrc' + date + '.json'
      let gridData
      let cmts;
      if (this.comments!= undefined) {
        cmts = this.comments.replaceAll("'", "''");
      } else {
        cmts = this.comments
      }
      if (data == "initial") {
        gridData =
        {
          ruleType: ruletypNm,
          versionName: this.versionName,
          bomEffTo: this.bomEffTo,
          bomEffFrom: this.bomEffFrom,
          actUnitCst: this.actUnitCst,
          cmpRouting: this.cmpRouting,
          qtyPerPar: this.qtyPerPar,
          dest: this.dest,
          netSpec: this.netSpec,
          sp: this.sp,
          namc: this.namc,
          family: this.family,
          katasPart: this.katasPart,
          grpi: this.grpi,
          partNo: this.partNo,
          ruleStatus: this.ruleStatus,
          createFromDt: this.createFromDt,
          createToDt: this.createToDt,
          comments: cmts,
          manAdjRuleId: this.manAdjRuleId,
          createdBy: this.createdBy,
          statusDesc: this.statusDesc,
          option: data,
          fileName: filename
        }
      }
      else {
        gridData =
        {
          ruleType:"",
          versionName: "",
          bomEffTo: "",
          bomEffFrom:"",
          actUnitCst: "",
          cmpRouting: "",
          qtyPerPar: "",
          dest: "",
          netSpec: "",
          sp: "",
          namc: "",
          family: "",
          katasPart: "",
          grpi: "",
          partNo: "",
          ruleStatus:"",
          createFromDt: "",
          createToDt: "",
          comments: "",
          manAdjRuleId: "",
          createdBy: "",
          statusDesc: "",
          option: data,
          fileName: filename
        }
      }
      this.SpinnerService.show();
      this.manualAdjRuleService.getGridData(gridData).subscribe(data => {
        console.log(data);
        // this.SpinnerService.hide();
        this.showdata = true;
        let datarow = data.payload;
        if (data.payload[0].RecordCnt > 1000) {
          setTimeout(() => {
            this.getFileFromS3(filename);
          }, 2000);
        } else {
          this.SpinnerService.hide();
          this.rows = datarow.map(row => ({
            man_adjstmnt_rule_typ_nm: row['man_adjstmnt_rule_typ_nm'],
            rep_bom_ver_id: row['rep_bom_ver_id'],
            version_name: row['version_name'],
            namc_cd: row['namc_cd'],
            fam_cd: row['fam_cd'],
            katashiki_cd: row['katashiki_cd'],
            grpi_cd: row['grpi_cd'],
            child_part_no: row['child_part_no'],
            rule_status: row['rule_status'],
            last_exec_ts: (row['last_exec_ts'] == null) ? '' : row['last_exec_ts'].split('T')[0],
            create_ts: (row['create_ts'] == null) ? '' : row['create_ts'].split('T')[0],
            bom_eff_dt_from: (row['bom_eff_dt_from'] == null) ? '' : row['bom_eff_dt_from'].split('T')[0],
            bom_eff_dt_to: (row['bom_eff_dt_to'] == null) ? '' : row['bom_eff_dt_to'].split('T')[0],
            base_optn_typ_cd: row['base_optn_typ_cd'],
            net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
            dest_ctry_id_comb_txt: row['dest_ctry_id_comb_txt'],
            per_veh_qty: row['per_veh_qty'],
            cmplt_rtg_cd: row['cmplt_rtg_cd'],
            actl_unit_cost_amt: row['actl_unit_cost_amt'],
            actl_cost_crncy_cd: row['actl_cost_crncy_cd'],
            man_adjstmnt_rule_stat_desc: row['man_adjstmnt_rule_stat_desc'],
            man_adjstmnt_rule_typ_id: row['man_adjstmnt_rule_typ_id'],
            rmrk_txt: row['rmrk_txt'],
            create_by_id: row['create_by_id'],
            man_adjstmnt_rule_def_id: row['man_adjstmnt_rule_def_id']
          }));
        }
      }, err => {
        console.log(err);
        this.showdata = false;
        this.successMessage = "";
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  sendToCorrectBom(selected): void {
    selected.forEach(row => {
      // this.dialogData.manAdjRuleId.push(row.manAdjRuleId);
      this.dialogData.manAdjRuleId = row.man_adjstmnt_rule_def_id
    });
    console.log(this.dialogData.manAdjRuleId)
  }
  backendToCorrectBom(dataFromBackend): void {
    dataFromBackend.forEach(row => {
      this.backendData.totCountOfAffProdBom = row.totCountOfAffProdBom
      this.backendData.noOfVinOrSerAff = row.noOfVinOrSerAff
      this.backendData.noOfBomAff = row.noOfBomAff
    });
  }

  closeAlert() {
    this.errorMessage = '';
    this.successMessage = '';


  }

  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "Rule Type": row.ruleType,
        "Version Name": row.versionName,
        "BOM Effective From": row.bomEffFrom,
        "BOM Effective To": row.bomEffTo,
        "NAMC": row.namc,
        "Family": row.family,
        "S/P": row.sp,
        "Net Spec": row.netSpec,
        "Katashiki/Part": row.katasPart,
        "GRPI": row.grpi,
        "Dest": row.dest,
        "Part Number": row.partNo,
        "Qty Per Parent": row.qtyPerPar,
        "Complete Routing": row.cmpRouting,
        "ACT Unit Cost": row.actUnitCst,
        "Rule Status": row.ruleStatus,
        "Status Description": row.statusDesc,
        "Manual Adjustment Rule Id": row.manAdjRuleId,
        "Comments": row.comments,
        "Create Date": row.createFromDt,
        "Last Rule Process Date": row.createToDt,
        "Create By": row.createdBy,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.log(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'createFromDt') {
      this.createFromDt = "";
    } else if (name == 'createToDt') {
      this.createToDt = "";
    }
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_10122') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }
        else {
          console.log(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.log(this.roleAccess);
    });
  }

  ////get from s3 Grid search data
  async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    let a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.log(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.log('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.log(data)

          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.log("res and reult", res, result)
          if (res.length > 0) {
            this.rows = res.map(row => ({
              man_adjstmnt_rule_typ_nm: row['man_adjstmnt_rule_typ_nm'],
              rep_bom_ver_id: row['rep_bom_ver_id'],
              version_name: row['version_name'],
              namc_cd: row['namc_cd'],
              fam_cd: row['fam_cd'],
              katashiki_cd: row['katashiki_cd'],
              grpi_cd: row['grpi_cd'],
              child_part_no: row['child_part_no'],
              rule_status: row['rule_status'],
              last_exec_ts: (row['last_exec_ts'] == null) ? '' : row['last_exec_ts'].replace('Z', '').replace('T', ' '),
              create_ts: (row['create_ts'] == null) ? '' : row['create_ts'].replace('Z', '').replace('T', ' '),
              bom_eff_dt_from: (row['bom_eff_dt_from'] == null) ? '' : row['bom_eff_dt_from'].split('T')[0],
              bom_eff_dt_to: (row['bom_eff_dt_to'] == null) ? '' : row['bom_eff_dt_to'].split('T')[0],
              base_optn_typ_cd: row['base_optn_typ_cd'],
              net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
              dest_ctry_id_comb_txt: row['dest_ctry_id_comb_txt'],
              per_veh_qty: row['per_veh_qty'],
              cmplt_rtg_cd: row['cmplt_rtg_cd'],
              actl_unit_cost_amt: row['actl_unit_cost_amt'],
              actl_cost_crncy_cd: row['actl_cost_crncy_cd'],
              man_adjstmnt_rule_stat_desc: row['man_adjstmnt_rule_stat_desc'],
              man_adjstmnt_rule_typ_id: row['man_adjstmnt_rule_typ_id'],
              rmrk_txt: row['rmrk_txt'],
              create_by_id: row['create_by_id'],
              man_adjstmnt_rule_def_id: row['man_adjstmnt_rule_def_id']
            }));
            console.log(this.rows);
            this.SpinnerService.hide();
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.manualAdjRuleService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data�Storage�Disconnected.');
          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable�to�get�keys�and�token', 'Failed');
      });
    })
  }
}
//man adj
