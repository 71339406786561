import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { DialogData } from '../dialog-data/dialog-data';
import { PcspecToMfgspecService } from './pcspec-to-mfgspec.service';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';
import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-pcspec-to-mfgspec',
  templateUrl: './pcspec-to-mfgspec.component.html',
  styleUrls: ['./pcspec-to-mfgspec.component.css']
})
export class PcspecToMfgspecComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //export
  fileName = "C11pcSpecToMfgSpec";
  exportData = [];
  sheetName = [];

  //alertMessage
  errorMessage: string = '';

  //ngModel values for search
  step = 0;
  ra: string = "";
  namc: string = "";
  vinKatashiki: string = "";
  startDate: any = "";
  endDate: any = "";
  family: string = "";
  katashikiPart: string = "";
  mfgSpecCode: string = "";
  prodDefId: string = "";
  prodBomId: string = "";
  spinnerMessage = "Please Wait...";
  accessKey: any
  secretKey: any
  sessionToken: any
  fileFormat: any

  //list
  raLst: string[] = [];
  namcLst: string[] = [];
  startDateLst: string[] = [];
  endDateLst: string[] = [];

  //mat-dialog
  data: any = {};

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showGrid: boolean = false;
  showButtons: boolean = false;
  showdata: boolean = true;

  columns = [

    { name: 'ra', prop: 'bom_typ_cd' }, { name: 'vinKatashki', prop: 'vin_katashiki_cd' },
    { name: 'namc', prop: 'namc_cd' }, { name: 'pcSpec', prop: 'pc_spec' },
    { name: 'bomEffectivityDate', prop: 'prod_trnfr_dt' }, { name: 'suspensionDate', prop: 'excp_dt' },
    { name: 'prodDefId', prop: 'prod_def_id' }, { name: 'prodBomId', prop: 'prod_bom_id' }

  ];
  ColumnMode = ColumnMode;
  recordcount: any;
  accessEnable: boolean;
  roleAccess: any = []
  Roledata: any = []

  constructor(private pcspecToMfgspecService: PcspecToMfgspecService, public dialog: MatDialog, private dialogData: DialogData,
    private exportService: ExportToExcelService, private readonly SpinnerService: NgxSpinnerService, public globalrole: GlobalService,) {

  }

  async ngOnInit(): Promise<void> {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    this.SpinnerService.hide();
    if (a) {
      console.log('a', a)
      //grid
      this.onloadfn();
    }
  }

  onloadfn() {
    //to get namc
    this.pcspecToMfgspecService.getNamc().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });
  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }
  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  //search func
  generateReport(): void {
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      this.showGrid = true;
      this.showButtons = true;
      this.errorMessage = ''
      this.getGridData();
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  exportFile(): void {
    if (this.rows.length != 0) {
      this.rows.forEach(row => {
        let obj =
        {
          "R/A": row.bom_typ_cd,
          "VIN/Katashki": row.vin_katashiki_cd,
          "NAMC": row.namc_cd,
          "Pc Spec": row.pc_spec,
          "Bom Effectivity Date": row.prod_trnfr_dt,
          "Suspension Date": row.excp_dt,
          "Prod Def Id": row.prod_def_id,
          "Prom Bom Id": row.prod_bom_id
        }
        this.exportData.push(obj);
      });
    } else {
      let obj =
      {
        "R/A": "",
        "VIN/Katashki": "",
        "NAMC": "",
        "Pc Spec": "",
        "Bom Effectivity Date": "",
        "Suspension Date": "",
        "Prod Def Id": "",
        "Prom Bom Id": ""
      }
      this.exportData.push(obj);
    }


    this.exportService.exportAsExcelFileForReport(this.exportData, this.fileName);
    this.exportData = []

  }

  getGridData() {
    if (this.namc == undefined) {
      this.namc = "";
    }
    if (this.ra == undefined) {
      this.ra = "";
    }
    if (this.vinKatashiki == undefined) {
      this.vinKatashiki = "";
    }
    // if (this.endDate != "") {
    //   this.endDate = this.pipe.transform(this.endDate, 'yyyy-MM-dd')
    // }
    // if (this.startDate != "") {
    //   this.startDate = this.pipe.transform(this.startDate, 'yyyy-MM-dd')
    // }
    let indateFormat = ''; let outdateFormat = '';
    if (this.startDate != "") {
      let d1 = moment(this.startDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.startDate = date
    }
    if (this.endDate != "") {
      let d1 = moment(this.endDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.endDate = date
    }
    let newDate = new Date();
    let date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
    let fileName = 'c11-pcSpec_to_mfg_spec/c11GrdSrc' + date + '.json';
    let gridData =
    {
      ra: this.ra,
      vinKatashiki: this.vinKatashiki,
      namc: this.namc,
      suspensionDate: outdateFormat,
      startDate: indateFormat,
      fileName: fileName
    }

    this.SpinnerService.show();
    this.pcspecToMfgspecService.getPcSpecToMfgSpecConversionGrid(gridData).subscribe(data => {
      console.log(data);
      this.showdata = true;
      console.log(this.showdata);
      this.recordcount = data.pagination.totalRecords;
      let datarow = data.payload;
      if (data.payload[0].RecordCnt > 1000) {
        setTimeout(() => {
          this.getFileFromS3(fileName);
        }, 2000);
      } else {
      this.SpinnerService.hide();
      this.rows = data.payload;
      this.errorMessage = "";
      }
    }, err => {
      console.log(err);
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      if (err == 0) {
        this.errorMessage = "Request timed out. Please try again.";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      } else {
        this.errorMessage = "No Records";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      }
    });

  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'startDate') {
      this.startDate = "";
    } else if (name == 'endDate') {
      this.endDate = "";
    }
  }
  closeAlert() {
    this.errorMessage = "";

  }
   ////get from s3 Grid search data
   async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    let a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.log(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.log('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.log(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.log("res and reult", res, result)
          if (res.length > 0) {
            this.rows=res;
            console.log(this.rows);
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.pcspecToMfgspecService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data?Storage?Disconnected.');
          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable?to?get?keys?and?token', 'Failed');
      });
    })
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_7910') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }
        else {
          console.log(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.log(this.roleAccess);
    })
  }
}


