import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductWithinVersion } from '../dialog-data/product-within-version';
import { ProdVersionActionsService } from './prod-version-actions.service';

@Component({
  selector: 'app-prod-version-actions',
  templateUrl: './prod-version-actions.component.html',
  styleUrls: ['./prod-version-actions.component.css']
})
export class ProdVersionActionsComponent implements OnInit {


  response: any = "";
  rep_prod_id_Lst: any;
  prod_bom_id_lst: any;
  failedresponse: any;
  resetresponse: any;
  cntresponse: any;

  constructor(public prodVerActionService: ProdVersionActionsService,
    @Inject(MAT_DIALOG_DATA) public data: ProductWithinVersion,
    public dialogRef: MatDialogRef<ProdVersionActionsComponent>,
    private readonly SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    console.log(this.data);
    this.prod_bom_id_lst = this.data.prod_bom_id
    this.SpinnerService.show();
    // this.getRepProdIdApprFn();
    this.getProcStatus();
  }
  getProcStatus() {
    let fileName= localStorage.getItem('Filename')
    let body;
    if (this.data.buttonName == 'VersionRemove' || this.data.buttonName == 'CopyStructure' ||
      this.data.buttonName == 'IdenDepProdVer' || this.data.buttonName == 'genvervalRpt' || this.data.buttonName == 'resetBomProc' || this.data.buttonName == 'resetCostProc'
      || this.data.buttonName == 'IdenDepProd' || this.data.buttonName == 'addtoversion') {
      if (this.data.buttonName == 'VersionRemove') {
        body = {
          'proc_nm': 'REMOVE_FROM_VERSION',
          "screenNo":"NBPC_10110",
          "fileName" :fileName
        }
      } else if (this.data.buttonName == 'CopyStructure') {
        body = {
          'proc_nm': 'COPY_STRUCTURE',
          "screenNo":"NBPC_10110",
          "fileName" :fileName
        }
      } else if (this.data.buttonName == 'IdenDepProdVer') {
        body = {
          'proc_nm': 'IDENTIFY_DEPENDANT_PRODUCT',
          "screenNo":"NBPC_10110",
          "fileName" :fileName
        }
      } else if (this.data.buttonName == 'resetBomProc') {
        body = {
          'proc_nm': 'RESET_BOM_PROCESS',
          "screenNo":"NBPC_10110",
          "fileName" :fileName
        }
      } else if (this.data.buttonName == 'resetCostProc') {
        body = {
          'proc_nm': 'RESET_COST_PROCESS',
          "screenNo":"NBPC_10110",
          "fileName" :fileName
        }
      } else if (this.data.buttonName == 'IdenDepProd') {
        body = {
          'proc_nm': 'IDENTIFY_DEPENDANT_PRODUCT',
          "screenNo":"NBPC_10110",
          "fileName" :fileName
        }
      } else if (this.data.buttonName == 'genvervalRpt') {
        body = {
          'proc_nm': 'GENERATE_VALIDATION_REPORT',
          "screenNo":"NBPC_10110",
          "fileName" :fileName
        }
      } else if (this.data.buttonName == 'addtoversion') {
        body = {
          'proc_nm': 'ADD_TO_TMP_ADD_REP_PROD_VER',
          "screenNo":"NBPC_10110",
          "fileName" :fileName
        }
      }

      this.prodVerActionService.getProcStatus(body).subscribe(data => {
        console.log(data);
        this.response = data.payload;
        if (this.response[0].proc_status != 'INITIATED' && this.response[0].proc_status != 'STARTED') {
          this.updatetProcProcessStatus()
        }
        else if (this.response[0].proc_status == 'INITIATED' || this.response[0].proc_status == 'STARTED') {
          this.SpinnerService.hide()
          this.dialogRef.close("Already is in progress. Please Work Later");
        }
      }, err => {
        this.SpinnerService.hide();
        this.dialogRef.close("Function Failed");
      })
    } else {
      let arrayvalue: any;
      arrayvalue = this.data.rep_prod_id;
      console.log(arrayvalue.length, arrayvalue);
      let str = '';
      for (let i = 0; i < arrayvalue.length; i++) {
        if (str == '') {
          str = arrayvalue[i]
        } else {
          str += ',' + arrayvalue[i];
        }
        this.rep_prod_id_Lst = str;
      }
      ////for approve and verify func in prodwithin version
      this.getRepProdVerIdApprFn(this.rep_prod_id_Lst)
    }
  }

  getRepProdIdApprFn() {
    if (this.data.buttonName == 'VersionRemove' || this.data.buttonName == 'CopyStructure' ||
      this.data.buttonName == 'IdenDepProdVer' || this.data.buttonName == 'genvervalRpt') {
      let arrayvalue: any;
      arrayvalue = this.data.rep_prod_id;
      console.log(arrayvalue.length, arrayvalue);
      let str = '';
      for (let i = 0; i < arrayvalue.length; i++) {
        if (str == '') {
          str = arrayvalue[i]
        } else {
          str += ',' + arrayvalue[i];
        }
        this.rep_prod_id_Lst = str;
      }
    }
    console.log(this.rep_prod_id_Lst);
    if (this.data.buttonName == 'VersionRemove') {
      this.FetchtruncTempRepProd('EBOM_ADM.TMP_REMOVE_REP_PROD');
    } else if (this.data.buttonName == 'CopyStructure') {
      this.FetchtruncTempRepProd('EBOM_ADM.TMP_COPY_STRUCTURE');
    } else if (this.data.buttonName == 'IdenDepProdVer') {
      this.FetchtruncTempRepProd('EBOM_ADM.TMP_IDENT_DPDNT_PRODUCTS');
    } else if (this.data.buttonName == 'resetBomProc') {
      this.FetchtruncTempRepProd('EBOM_ADM.TMP_RESET_BOM_PROCESS');
    } else if (this.data.buttonName == 'resetCostProc') {
      this.FetchtruncTempRepProd('EBOM_ADM.TMP_RESET_COST_PROCESS');
    } else if (this.data.buttonName == 'genvervalRpt') {
      this.Fetchtrunctbl('EBOM_ADM.TMP_VER_VAL_REPORT');
    }
    else if (this.data.buttonName == 'IdenDepProd') {
      this.FetchtruncTempRepProd('EBOM_ADM.TMP_IDENT_DPDNT_PRODUCTS');
    }
    else if (this.data.buttonName == 'addtoversion') {
      this.FetchtruncTempRepProd('EBOM_ADM.TMP_ADD_REP_PROD_VER');
    }
    else {
      let arrayvalue: any;
      arrayvalue = this.data.rep_prod_id;
      console.log(arrayvalue.length, arrayvalue);
      let str = '';
      for (let i = 0; i < arrayvalue.length; i++) {
        if (str == '') {
          str = arrayvalue[i]
        } else {
          str += ',' + arrayvalue[i];
        }
        this.rep_prod_id_Lst = str;
      }
      ////for approve and verify func in prodwithin version
      this.getRepProdVerIdApprFn(this.rep_prod_id_Lst)
    }
  }


  getRepProdVerIdApprFn(rep_prod_id_lst) {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "rep_prod_id": rep_prod_id_lst,
      "screenNo":"NBPC_10110",
          "fileName" :fileName
    }
    this.prodVerActionService.getRepProdVerIdAppr(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        if (this.data.buttonName == 'Approve') {
          this.getStatCheckApprFn(rep_prod_id_lst);
        } else if (this.data.buttonName == 'Verify') {
          this.getStatCheckVerifyFn(rep_prod_id_lst);
        }

      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  ////////////////approve functions//////////////////
  getStatCheckApprFn(rep_prod_id_lst) {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "rep_prod_id": rep_prod_id_lst,
      "screenNo":"NBPC_10110",
          "fileName" :fileName

    }
    this.prodVerActionService.getStatCheckAppr(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.getSapCheckApprFn(rep_prod_id_lst);
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
  getSapCheckApprFn(rep_prod_id_lst) {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "rep_prod_id": rep_prod_id_lst,
      "screenNo":"NBPC_10110",
          "fileName" :fileName
    }
    this.prodVerActionService.getSapCheckAppr(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.editApproveProdsFn(rep_prod_id_lst);
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
  editApproveProdsFn(rep_prod_id_lst) {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let body = {

      "rep_prod_id": rep_prod_id_lst,
      "screenNo":"NBPC_10110",
          "fileName" :fileName

    }
    this.prodVerActionService.editApproveProds(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.editApproveProdBomFn(rep_prod_id_lst);
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
  editApproveProdBomFn(rep_prod_id_lst) {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let body = {      
      "rep_prod_id": rep_prod_id_lst,
      "screenNo":"NBPC_10110",
      "fileName" :fileName

    }
    this.prodVerActionService.editApproveProdBom(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      this.SpinnerService.hide();
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  /////////////////////Verify Functions //////////////////
  getStatCheckVerifyFn(rep_prod_id_Lst) {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "rep_prod_id": rep_prod_id_Lst,
      "screenNo":"NBPC_10110",
          "fileName" :fileName
    }
    this.prodVerActionService.getStatCheckVerify(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.UpdateRepProdVerifyFn(rep_prod_id_Lst);
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  UpdateRepProdVerifyFn(rep_prod_id_Lst) {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let body = {
      "screenNo":"NBPC_10110",
          "fileName" :fileName,
      "rep_prod_id": rep_prod_id_Lst
    }
    this.prodVerActionService.UpdateRepProdVerify(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.UpdateProdBomVerifyFn(rep_prod_id_Lst);
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
  UpdateProdBomVerifyFn(rep_prod_id_Lst) {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let body = {
      
      "rep_prod_id": rep_prod_id_Lst,
      "screenNo":"NBPC_10110",
          "fileName" :fileName

    }
    this.prodVerActionService.UpdateProdBomVerify(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }



  /////////////Remove Version or copy struc or idendify or reset cost and bom ///////////////////////////
  Fetchtrunctbl(Tablename) {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "table_name": Tablename,
      "screenNo":"NBPC_10110",
          "fileName" :fileName
    }

    this.prodVerActionService.truncTempRepProd(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        if (Tablename == 'EBOM_ADM.TMP_VER_VAL_REPORT') {
          this.FetchtruncTempRepProd('EBOM_ADM.TMP_REP_PROD_GVVR')
        }
        else {

        }
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }


  FetchtruncTempRepProd(Tablename) {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "table_name": Tablename,
      "screenNo":"NBPC_10110",
      "fileName" :fileName
    }

    this.prodVerActionService.truncTempRepProd(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.FetchaddTempRepProd(Tablename)
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  FetchaddTempRepProd(Tablename) {
    let fileName= localStorage.getItem('Filename')
    console.log(this.rep_prod_id_Lst);
    let body;
    if (this.data.buttonName == 'VersionRemove') {
      body = {
        "rep_prod_id": this.rep_prod_id_Lst,
        "table_name": Tablename,
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'CopyStructure') {
      body = {
        "rep_prod_id": this.rep_prod_id_Lst,
        "table_name": Tablename,
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProdVer') {
      body = {
        "rep_prod_id": this.rep_prod_id_Lst,
        "table_name": Tablename,
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetBomProc') {
      body = {
        "prod_bom_id": this.prod_bom_id_lst,
        "table_name": Tablename,
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetCostProc') {
      body = {
        "prod_bom_id": this.prod_bom_id_lst,
        "table_name": Tablename,
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProd') {
      body = {
        "prod_bom_id": this.prod_bom_id_lst,
        "table_name": Tablename,
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    else if (this.data.buttonName == 'genvervalRpt') {
      body = {
        "rep_prod_id": this.rep_prod_id_Lst,
        "table_name": Tablename,
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    else if (this.data.buttonName == 'addtoversion') {
      body = {
        "rep_prod_def_id": this.data.rep_prod_def_id,
        'rep_bom_ver_id': this.data.rep_bom_ver_id,
        "table_name": Tablename,
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
        "fileName" :fileName
      }
    }
    this.prodVerActionService.addTempRepProd(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        // if (this.data.buttonName == 'addtoversion') {
        this.updatestartedfn();
        // } else {
        //   this.FetchSpCall();
        // }

      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
  updatestartedfn() {
    let fileName= localStorage.getItem('Filename')
    let mail = localStorage.getItem('emailId');
    let user = localStorage.getItem('workDayId');
    let body;
    // if (this.data.buttonName == 'VersionRemove' || this.data.buttonName == 'CopyStructure' ||
    //   this.data.buttonName == 'IdenDepProdVer' || this.data.buttonName == 'genvervalRpt' || this.data.buttonName == 'resetBomProc' || this.data.buttonName == 'resetCostProc'
    //   || this.data.buttonName == 'IdenDepProd' || this.data.buttonName == 'addtoversion') {
    if (this.data.buttonName == 'VersionRemove') {
      body = {
        'mailId': mail,
        
        'proc_status': 'STARTED',
        'proc_nm': 'REMOVE_FROM_VERSION',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'CopyStructure') {
      body = {
        'mailId': mail,
        
        'proc_status': 'STARTED',
        'proc_nm': 'COPY_STRUCTURE',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProdVer') {
      body = {
        'mailId': mail,
        
        'proc_status': 'STARTED',
        'proc_nm': 'IDENTIFY_DEPENDANT_PRODUCT',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetBomProc') {
      body = {
        'mailId': mail,
        
        'proc_status': 'STARTED',
        'proc_nm': 'RESET_BOM_PROCESS',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetCostProc') {
      body = {
        'mailId': mail,
        
        'proc_status': 'STARTED',
        'proc_nm': 'RESET_COST_PROCESS',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProd') {
      body = {
        'mailId': mail,
        
        'proc_status': 'STARTED',
        'proc_nm': 'IDENTIFY_DEPENDANT_PRODUCT',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'genvervalRpt') {
      body = {
        'mailId': mail,
        
        'proc_status': 'STARTED',
        'proc_nm': 'GENERATE_VALIDATION_REPORT',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'addtoversion') {
      body = {
        'mailId': mail,
        
        'proc_status': 'STARTED',
        'proc_nm': 'ADD_TO_TMP_ADD_REP_PROD_VER',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    this.prodVerActionService.editProcProcessStatus(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.FetchProcStatus();
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
    // }
  }

  updatetProcProcessStatus() {
    let fileName= localStorage.getItem('Filename')
    let mail = localStorage.getItem('emailId');
    let user = localStorage.getItem('workDayId');
    let body;
    if (this.data.buttonName == 'VersionRemove') {
      body = {
        'mailId': mail,
        
        'proc_nm': 'REMOVE_FROM_VERSION',
        'proc_status': 'INITIATED',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'CopyStructure') {
      body = {
        'mailId': mail,
        
        'proc_nm': 'COPY_STRUCTURE',
        'proc_status': 'INITIATED',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProdVer') {
      body = {
        'mailId': mail,
        
        'proc_nm': 'IDENTIFY_DEPENDANT_PRODUCT',
        'proc_status': 'INITIATED',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetBomProc') {
      body = {
        'mailId': mail,
        
        'proc_nm': 'RESET_BOM_PROCESS',
        'proc_status': 'INITIATED',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetCostProc') {
      body = {
        'mailId': mail,
        
        'proc_nm': 'RESET_COST_PROCESS',
        'proc_status': 'INITIATED',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProd') {
      body = {
        'mailId': mail,
        
        'proc_nm': 'IDENTIFY_DEPENDANT_PRODUCT',
        'proc_status': 'INITIATED',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    else if (this.data.buttonName == 'genvervalRpt') {
      body = {
        'mailId': mail,
        
        'proc_nm': 'GENERATE_VALIDATION_REPORT',
        'proc_status': 'INITIATED',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    else if (this.data.buttonName == 'addtoversion') {
      body = {
        'mailId': mail,
        
        'proc_nm': 'ADD_TO_TMP_ADD_REP_PROD_VER',
        'proc_status': 'INITIATED',
        "screenNo":"NBPC_10110",
          "fileName" :fileName

      }
    }
    this.prodVerActionService.editProcProcessStatus(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.getRepProdIdApprFn();
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  // FetchSpCall() {
  //   let data;
  //   if (this.data.buttonName == 'VersionRemove') {
  //     data = 'EBOM_ADM.sp_ebom_remove_from_version'
  //   } else if (this.data.buttonName == 'CopyStructure') {
  //     data = 'EBOM_ADM.sp_ebom_copy_structure'
  //   } else if (this.data.buttonName == 'IdenDepProdVer') {
  //     data = 'EBOM_ADM.sp_ebom_identify_dependant_product' /// prodwithinversion should change the name
  //   } else if (this.data.buttonName == 'resetBomProc') {
  //     data = 'EBOM_ADM.sp_ebom_reset_bom_processing'
  //   }
  //   else if (this.data.buttonName == 'resetCostProc') {
  //     data = 'EBOM_ADM.sp_ebom_reset_cost_processing'
  //   } else if (this.data.buttonName == 'IdenDepProd') {
  //     data = 'EBOM_ADM.sp_ebom_identify_dependant_product'
  //   } else if (this.data.buttonName == 'genvervalRpt') {
  //     data = 'EBOM_ADM.sp_ebom_generate_validation_report'
  //   } else if (this.data.buttonName == 'addtoversion') {
  //     data = 'EBOM_ADM.sp_ebom_add_to_tmp_add_rep_prod_ver'
  //   }
  //   this.prodVerActionService.getSpCall(data).subscribe(data => {
  //     console.log(data);
  //     // this.response = data.payload;
  //     this.FetchProcStatus();
  //   }, err => {
  //     this.FetchProcStatus();
  //     this.dialogRef.close("Function Failed");
  //   });
  // }

  FetchProcStatus() {
    let fileName= localStorage.getItem('Filename')
    let body;
    if (this.data.buttonName == 'VersionRemove') {
      body = {
        'proc_nm': 'REMOVE_FROM_VERSION',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'CopyStructure') {
      body = {
        'proc_nm': 'COPY_STRUCTURE',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProdVer') {
      body = {
        'proc_nm': 'IDENTIFY_DEPENDANT_PRODUCT',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetBomProc') {
      body = {
        'proc_nm': 'RESET_BOM_PROCESS',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetCostProc') {
      body = {
        'proc_nm': 'RESET_COST_PROCESS',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProd') {
      body = {
        'proc_nm': 'IDENTIFY_DEPENDANT_PRODUCT',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'genvervalRpt') {
      body = {
        'proc_nm': 'GENERATE_VALIDATION_REPORT',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'addtoversion') {
      body = {
        'proc_nm': 'ADD_TO_TMP_ADD_REP_PROD_VER',
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }

    this.prodVerActionService.getProcStatus(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].proc_status == 'COMPLETED') {
        // if (this.data.buttonName == 'addtoversion') {
        //   this.addversionProcApi();
        // } else {
        this.FetchCntTempRepProd();
        // }
      } else if (this.response[0].proc_status == 'INITIATED' || this.response[0].proc_status == 'INPROGRESS' || this.response[0].proc_status == 'IN_PROGRESS' || this.response[0].proc_status == 'STARTED') {
        this.FetchProcStatus();
      }
      else if (this.response[0].proc_status == 'ERROR') {
        this.SpinnerService.hide()
        this.dialogRef.close("Error returned in Procedure");
        console.log("error returned in procedure");
      }
      else {

      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  addversionProcApi() {
    let fileName= localStorage.getItem('Filename')
    let body = {
      'proc_nm': 'PROD_ADD_TO_VERSION',
      "screenNo":"NBPC_10110",
          "fileName" :fileName
    }
    this.prodVerActionService.getProcStatus(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].proc_status == 'COMPLETED') {
        this.FetchCntTempRepProd();
      } else if (this.response[0].proc_status == 'INITIATED' || this.response[0].proc_status == 'INPROGRESS' || this.response[0].proc_status == 'IN_PROGRESS') {
        this.addversionProcApi();
      }
      else if (this.response[0].proc_status == 'ERROR') {
        this.SpinnerService.hide()
        this.dialogRef.close(this.response[0].proc_status);
        console.log("error returned in procedure");
      }
      else {

      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  FetchCntTempRepProd() {
    let fileName= localStorage.getItem('Filename')
    let body;
    if (this.data.buttonName == 'VersionRemove') {
      body = {
        "table_name": 'EBOM_ADM.TMP_REMOVE_REP_PROD',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'CopyStructure') {
      body = {
        "table_name": 'EBOM_ADM.TMP_COPY_STRUCTURE',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProdVer') {
      body = {
        "table_name": 'EBOM_ADM.TMP_IDENT_DPDNT_PRODUCTS',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetBomProc') {
      body = {
        "table_name": 'EBOM_ADM.TMP_EXCEL_RPT',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetCostProc') {
      body = {
        "table_name": 'EBOM_ADM.TMP_EXCEL_RPT_COST',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProd') {
      body = {
        "table_name": 'EBOM_ADM.TMP_IDENT_DPDNT_PRODUCTS',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    else if (this.data.buttonName == 'genvervalRpt') {
      body = {
        "table_name": 'EBOM_ADM.TMP_VER_VAL_REPORT',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    else if (this.data.buttonName == 'addtoversion') {
      body = {
        "table_name": 'EBOM_ADM.TMP_ADD_REP_PROD_VER',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    this.prodVerActionService.getCntTempRepProd(body).subscribe(data => {
      console.log(data);
      this.cntresponse = data.payload[0];
      this.getFailedRecordsfn();
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  getFailedRecordsfn() {
    let fileName= localStorage.getItem('Filename')
    let body;
    if (this.data.buttonName == 'VersionRemove') {
      body = {
        "table_name": 'EBOM_ADM.TMP_REMOVE_REP_PROD',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'CopyStructure') {
      body = {
        "table_name": 'EBOM_ADM.TMP_COPY_STRUCTURE',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProdVer') {
      body = {
        "table_name": 'EBOM_ADM.TMP_IDENT_DPDNT_PRODUCTS',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetBomProc') {
      body = {
        "table_name": 'EBOM_ADM.TMP_EXCEL_RPT',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetCostProc') {
      body = {
        "table_name": 'EBOM_ADM.TMP_EXCEL_RPT_COST',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProd') {
      body = {
        "table_name": 'EBOM_ADM.TMP_IDENT_DPDNT_PRODUCTS',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    else if (this.data.buttonName == 'genvervalRpt') {
      body = {
        "table_name": 'EBOM_ADM.TMP_VER_VAL_REPORT',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    else if (this.data.buttonName == 'addtoversion') {
      body = {
        "table_name": 'EBOM_ADM.TMP_ADD_REP_PROD_VER',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    this.prodVerActionService.getFailedRecords(body).subscribe(data => {
      console.log(data);
      this.failedresponse = data.payload[0];
      this.getResetRecordsfn();
      // if(this.response[0] != undefined){
      // if (this.data.buttonName == 'VersionRemove') {
      //   this.SpinnerService.hide();
      //   this.dialogRef.close(this.response[0]);
      // } else if (this.data.buttonName == 'CopyStructure') {
      //   this.SpinnerService.hide();
      //   this.dialogRef.close(this.response[0]);
      // } else if (this.data.buttonName == 'IdenDepProdVer') {
      //   this.SpinnerService.hide();
      //   this.dialogRef.close(this.response[0]);
      // } else if (this.data.buttonName == 'resetBomProc') {
      //   this.SpinnerService.hide();
      //   this.dialogRef.close(this.response[0]);
      // } else if (this.data.buttonName == 'resetCostProc') {
      //   this.SpinnerService.hide();
      //   this.dialogRef.close(this.response[0]);
      // } else if (this.data.buttonName == 'IdenDepProd') {
      //   this.SpinnerService.hide();
      //   this.dialogRef.close(this.response[0]);
      // } else if (this.data.buttonName == 'genvervalRpt') {
      //   this.SpinnerService.hide();
      //   this.dialogRef.close(this.response[0]);
      // }
      // else if (this.data.buttonName == 'addtoversion') {
      //   // this.SpinnerService.hide();
      //   // this.dialogRef.close(this.response[0]);
      // }
      // }
      // else{
      //     console.log("error msg is returned");
      //     this.SpinnerService.hide();
      //     this.dialogRef.close("error message is returned")
      // }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  getResetRecordsfn() {
    let fileName= localStorage.getItem('Filename')
    let body;
    if (this.data.buttonName == 'VersionRemove') {
      body = {
        "table_name": 'EBOM_ADM.TMP_REMOVE_REP_PROD',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'CopyStructure') {
      body = {
        "table_name": 'EBOM_ADM.TMP_COPY_STRUCTURE',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProdVer') {
      body = {
        "table_name": 'EBOM_ADM.TMP_IDENT_DPDNT_PRODUCTS',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetBomProc') {
      body = {
        "table_name": 'EBOM_ADM.TMP_EXCEL_RPT',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'resetCostProc') {
      body = {
        "table_name": 'EBOM_ADM.TMP_EXCEL_RPT_COST',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    } else if (this.data.buttonName == 'IdenDepProd') {
      body = {
        "table_name": 'EBOM_ADM.TMP_IDENT_DPDNT_PRODUCTS',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    else if (this.data.buttonName == 'genvervalRpt') {
      body = {
        "table_name": 'EBOM_ADM.TMP_VER_VAL_REPORT',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    else if (this.data.buttonName == 'addtoversion') {
      body = {
        "table_name": 'EBOM_ADM.TMP_ADD_REP_PROD_VER',
        "buttonName": this.data.buttonName,
        "screenNo":"NBPC_10110",
          "fileName" :fileName
      }
    }
    this.prodVerActionService.getResetRecords(body).subscribe(data => {
      console.log(data);
      this.resetresponse = data.payload[0];
      let datares = []
      datares.push(this.cntresponse, this.failedresponse, this.resetresponse)
      console.log(datares)
      this.SpinnerService.hide();
      this.dialogRef.close(datares);     
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
}
