<span *ngIf="accessEnable">
  <div class="row margin">
    <div class="col-md-1">
    </div>
    <div class="col-md-10">
      <ngx-datatable #prodStat id="prodStat" class="material striped" [rows]="rows" [columnMode]="ColumnMode.force"
        [headerHeight]="50" rowHeight="30" [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">

        <ngx-datatable-column class="w-50" name="Sr.No." [width]="10">
          <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
            {{rowIndex+1}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Type Of Validation" prop="validation"></ngx-datatable-column>
        <ngx-datatable-column name="Count" prop="cnt"> </ngx-datatable-column>
        <ngx-datatable-column name="Last Run TS" prop="last"></ngx-datatable-column>


      </ngx-datatable>



    </div>

    <div class="col-md-1">

    </div>
  </div>

  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>