import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { VersionAddDialogService } from './version-add-dialog.service';


@Component({
  selector: 'app-version-add-dialog',
  templateUrl: './version-add-dialog.component.html',
  styleUrls: ['./version-add-dialog.component.css']
})
export class VersionAddDialogComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //errormessage
  errorMessage: string = "";

  //ngModel
  verAddApprovalStat: any = "Open";
  verAddVerType: any = '';
  verAddVerQual: any = '';
  verAddVerRev: any = '';
  verAddPriority: any = "2";
  verAddVolCntStatusDesc: any = '';
  verAddVolCntStatusId: any = '';
  verAddBomEffDate: any = '';
  verAddCostEffDate: any = '';
  verAddEffInDate: any = '';
  verAddEffOutDate: any = '';
  verAddComments: any = '';

  //list
  versionTypArray: any = []
  versionQualiArray: any = []
  versionReviArray: any = []
  priorityArray: any = []
  voulCountArray: any = []
  //to main page
  message = "Record Added Successfully";

  spinnerMessage = "Please Wait...";

  constructor(private VersionAddDialogService: VersionAddDialogService,
    public dialogRef: MatDialogRef<VersionAddDialogComponent>,
    private readonly SpinnerService: NgxSpinnerService) { }

  ngOnInit() {
    this.getversionTypData();
    //   this.getversionqualiData();
    //  this.getversionReviData();
    this.getpriorityData();
    // this.getvoluCountData();
  }

  getversionTypData() {
    this.VersionAddDialogService.getVersTypdropdown().subscribe(res => {
      console.log("res from getvertyp", res.payload);
      this.versionTypArray = res.payload
    })
  }

  getversionqualiData() {
    this.VersionAddDialogService.getVersqualidropdown(this.verAddVerType).subscribe(res => {
      console.log("res from getverquali", res.payload);
      this.versionQualiArray = res.payload;
      this.verAddVerQual = ""
      this.verAddVerRev = ""
    })
  }

  getversionReviData() {
    this.VersionAddDialogService.getVersrevisdropdown(this.verAddVerType, this.verAddVerQual).subscribe(res => {
      console.log("res from getverrevi", res.payload);
      this.versionReviArray = res.payload
      if (this.versionReviArray != null) {
        let data = this.versionReviArray[0].bom_ver_rev_nbr
        let numberver = Number(data) + 1
        this.verAddVerRev = '0' + numberver
        console.log(this.verAddVerRev)
      }
      else {
        this.verAddVerRev = '00'
      }
    })
  }

  getpriorityData() {
    this.VersionAddDialogService.getPrioritydropdown().subscribe(res => {
      console.log("res from getverpriority", res.payload);
      this.priorityArray = res.payload
    })
  }

  getvoluCountData() {
    console.log(this.verAddVerType)
    if (this.verAddVerType == '') {
      this.verAddVolCntStatusDesc = ''
      this.verAddVolCntStatusId =''
    } else {
      this.VersionAddDialogService.getVolcountdropdown().subscribe(res => {
        console.log("res from getvolcount", res.payload);
        this.voulCountArray = res.payload
        if (this.verAddVerType === '2' || this.verAddVerType === '3') {
          this.verAddVolCntStatusDesc = this.voulCountArray[0].vol_cnt_calc_stat_typ_desc
          this.verAddVolCntStatusId = '1'
        }
        else {
          this.verAddVolCntStatusDesc = 'NA'
          this.verAddVolCntStatusId = '2'
        }
      })
    }
  }

  //save
  submitVersion(): void {

    if (!this.verAddVerType || !this.verAddVerQual || !this.verAddPriority || !this.verAddBomEffDate
      || !this.verAddCostEffDate || !this.verAddEffInDate || !this.verAddEffOutDate) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.verAddEffInDate && this.verAddEffOutDate &&
      this.pipe.transform(this.verAddEffInDate, 'yyyy-MM-dd') > this.pipe.transform(this.verAddEffOutDate, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
    }
    else {
      this.errorMessage = "";
      let previouverrevcheck = this.verAddVerRev - 1;
      let checkprevdata
      if (previouverrevcheck == -1) {
        checkprevdata = 0;
      } else {
        checkprevdata = previouverrevcheck
      }
      this.VersionAddDialogService.getVerRev(this.verAddVerQual, this.verAddApprovalStat, checkprevdata).subscribe(res => {
        console.log("res from prevverquali", res.payload); 2
        let data = res.payload
        console.log('data', data, data.result)
        if (data[0].result == 'success') {
          console.log('1')
          this.addversion();
        } else {
          console.log('2')
          this.errorMessage = "Previous Version is not closed. New Revision cannot be created."
        }
      })

    }

  }

  addversion() {
    let fileName= localStorage.getItem('Filename')
    if (this.verAddVerRev == '') {
      this.verAddVerRev = null;
    }
    let indateFormat = ''; let outdateFormat = ''; let indatebomFormat = ''; let outdatecostFormat = ''
    if (this.verAddBomEffDate != "") {
      let d1 = moment(this.verAddBomEffDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indatebomFormat = moment(date).format('YYYY-MM-DD')
      this.verAddBomEffDate = date
    }
    if (this.verAddCostEffDate != "") {
      let d1 = moment(this.verAddCostEffDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdatecostFormat = moment(date).format('YYYY-MM-DD')
      this.verAddCostEffDate = date
    }
    if (this.verAddEffInDate != "") {
      let d1 = moment(this.verAddEffInDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.verAddEffInDate = date
    }
    if (this.verAddEffOutDate != "") {
      let d1 = moment(this.verAddEffOutDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.verAddEffOutDate = date
    }
    let cmts;
    if (this.verAddComments!= undefined) {
      cmts = this.verAddComments.replaceAll("'", "''");
    } else {
      cmts = this.verAddComments
    }
    let user = localStorage.getItem('workDayId')
    let body = {
      "bom_ver_qual_id": this.verAddVerQual,
      "bom_ver_rev_nbr": this.verAddVerRev,
      "ver_priority_typ_id": this.verAddPriority,
      "bom_eff_dt": indatebomFormat,
      "cost_eff_dt": outdatecostFormat,
      "eff_dt": indateFormat,
      "expr_dt": outdateFormat,
      "rmrk_txt": cmts,
      "bom_ver_apprvl_stat_typ": this.verAddApprovalStat,
      "vol_cnt_calc_stat_typ_id": this.verAddVolCntStatusId,
      "screenNo":"NBPC_10105",
      "fileName" :fileName
    }
    this.SpinnerService.show();
    ////send vernum +1 as value
    this.VersionAddDialogService.addVersions(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
        this.SpinnerService.hide()
      } else if (this.response[0].result == 'Partnum_Invalid') {
        this.errorMessage = 'Part Number Invalid';
        this.SpinnerService.hide()
      } else if (this.response[0].result == 'AlreadyExists') {
        this.dialogRef.close(this.response[0].result);
        this.SpinnerService.hide()
      }
      else {
        this.dialogRef.close("Function Failed with Error");
        this.SpinnerService.hide()
      }
    }, err => {
      this.dialogRef.close("Function Failed with Error");
      this.SpinnerService.hide()

    });
  }

  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }
  closeAlert() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'verAddBomEffDate') {
      this.verAddBomEffDate = "";
    } else if (name == 'verAddCostEffDate') {
      this.verAddCostEffDate = "";
    }else if (name == 'verAddEffInDate') {
      this.verAddEffInDate = "";
    }else if (name == 'verAddEffOutDate') {
      this.verAddEffOutDate = "";
    }
  }


}
