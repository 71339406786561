<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>


  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Monthly Representative:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="eMonthlyRep"></div>
          </div>
        </div>
      </div>

    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>Family</label></div>
          <div class=col-8>
            <select class="dateadd" [(ngModel)]="eFamily" aria-label="Default select example"
              (change)="getHikiateByDest()">
              <mat-option [value]=""></mat-option>
              <option *ngFor="let family of familyLst" [value]=family.fam_cd>{{family.fam_cd}}</option>

              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
          </div>
        </div>
      </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Hikiate Status:</label></div>
          <div class=col-2><select id="hikiSelect" [(ngModel)]="dummyHikiateLst" class="multiple" multiple
              aria-label="Default select example">
              <option value={{hikiate.hikiate}} *ngFor="let hikiate of hikiateLstFromDB">
                {{hikiate.hikiate}}</option>
            </select></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <button class="btn" (click)="moveHikiForward()">>></button><br>
            <button class="btn" (click)="moveHikiBackward()">
              << </button>
          </div>
          <div class=col-2><select class="multiple" multiple [(ngModel)]="dummyHikiateLstToDB"
              aria-label="Default select example">
              <option value={{selectedHikiate}} *ngFor="let selectedHikiate of selectedHikiateLst">{{selectedHikiate}}
              </option>
            </select></div>
        </div>
      </div>
    </div>


    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Extraction Time In:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="inPicker" [(ngModel)]="eExtractionTimeIn">
              <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
              <mat-datepicker #inPicker></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'eExtractionTimeIn')">clear
              </mat-icon>
            </mat-form-field>
         
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Extraction Time Out:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="outPicker" [(ngModel)]="eExtractionTimeOut">
              <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
              <mat-datepicker #outPicker></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'eExtractionTimeOut')">clear
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-2"> <label>Comments:</label></div>
      <div class=col-10><textarea class="w-100" name="w3review" rows="4" cols="50" [(ngModel)]="eComments">
          </textarea>
      </div>
    </div>

  </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button (click)="updateSapDataLoad()" cdkFocusInitial>Save</button>
    <button mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>