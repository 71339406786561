import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { UnresolvedSlctvtyService } from './unresolved-slctvty.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { GlobalService } from '../global.service';
import * as S3 from 'aws-sdk/clients/s3';


@Component({
  selector: 'app-unresolved-slctvty',
  templateUrl: './unresolved-slctvty.component.html',
  styleUrls: ['./unresolved-slctvty.component.css']
})
export class UnresolvedSlctvtyComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //alertMessage
  errorMessage: string = '';


  //export to excel
  fileName = 'c1UnresolvedSelectivity';
  exportData = [];
  sheetName = ['C1_UNRES_SEL'];

  //mat-dialog
  data: any = {};

  //ngModel values for searcH
  step = 0;
  namc: any = "";
  family: any = "";
  katashiki: any = "";
  mfgSpecCode: any = "";
  grpi: any = "";
  parentPart: any = "";
  partNumber: any = "";
  startDate: any = "";
  endDate: any = "";
  spinnerMessage = "Please Wait...";
  accessKey: any
  secretKey: any
  sessionToken: any
  fileFormat: any

  //list
  namcLst = [];
  familyLst = [];
  katashikiLst = [];
  accessEnable: boolean;
  roleAccess: any = []
  Roledata: any = []



  getGridData() {

    if (this.family == undefined) {
      this.family = "";
    }
    if (this.namc == undefined) {
      this.namc = "";
    }
    if (this.katashiki == undefined) {
      this.katashiki = "";
    }
    if (this.grpi == undefined) {
      this.grpi = "";
    }
    if (this.partNumber == undefined) {
      this.partNumber = "";
    }
    if (this.parentPart == undefined) {
      this.parentPart = "";
    }
    if (this.mfgSpecCode == undefined) {
      this.mfgSpecCode = "";
    }
    // if (this.endDate != "") {
    //   this.endDate = this.pipe.transform(this.endDate, 'yyyy-MM-dd')
    // }
    // if (this.startDate != "") {
    //   this.startDate = this.pipe.transform(this.startDate, 'yyyy-MM-dd')
    // }
    let indateFormat = ''; let outdateFormat = '';
    if (this.startDate != "") {
      let d1 = moment(this.startDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.startDate = date
    }
    if (this.endDate != "") {
      let d1 = moment(this.endDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.endDate = date
    }
    let newDate = new Date();
    let date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
    let fileName = 'c1-unresolved_slctvty/clgridsrc' + date + '.json';
    let gridData =
    {
      namc: this.namc,
      family: this.family,
      mfgSpecCode: this.mfgSpecCode,
      katashiki: this.katashiki,
      grpi: this.grpi,
      partNumber: this.partNumber,
      bomEffectivityDate: indateFormat,
      parentPart: this.parentPart,
      suspensionDate: outdateFormat,
      fileName: fileName
    }
    this.SpinnerService.show();
    this.unresolvedSlctvtyService.getGridData(gridData).subscribe(data => {
      console.log(data)
      this.showdata = true;
      console.log(this.showdata);
      this.recordcount = data.pagination.totalRecords;
      let datarow = data.payload;
      if (data.payload[0].RecordCnt > 1000) {
        setTimeout(() => {
          this.getFileFromS3(fileName);
        }, 2000);
      } else {
        this.rows = data.payload;
        this.SpinnerService.hide();
        this.errorMessage = "";
      }
    }, err => {
      this.showdata = false;
      this.successMessage = "";
      console.log(this.showdata);
      this.rows = [];
      if (err == 0) {
        this.errorMessage = "Request timed out. Please try again.";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      } else {
        this.errorMessage = "No Records";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      }
    });


  }

  //ngx-datatable
  showGrid: boolean = false;
  showButtons: boolean = false;
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;

  showdata: boolean = true;
  columns = [

    { name: 'namc', prop: 'namc_cd' }, { name: 'modelNumber', prop: 'modelNumber' },
    { name: 'mfgSpecCode', prop: 'mfg_spec_cd' }, { name: 'sapMatId', prop: 'sapMatId' },
    { name: 'family', prop: 'fam_cd' }, { name: 'katashiki', prop: 'katashiki_cd' },
    { name: 'parentPart', prop: 'par_part_no' }, { name: 'grpi', prop: 'grpi_cd' },
    { name: 'partNumber', prop: 'part_no' }, { name: 'partDescription', prop: 'part_desc' },
    { name: 'selectivityCode', prop: 'selectivity_cd' }, { name: 'completePartRouting', prop: 'complete_routing' },
    { name: 'vinn', prop: 'vin' }, { name: 'bomEffectivityDate', prop: 'prod_trnfr_dt' },
    { name: 'suspensionDate', prop: 'excp_dt' },
    { name: 'prodDefId', prop: 'prod_def_id' }, { name: 'prodBomId', prop: 'prod_bom_id' }
  ];
  ColumnMode = ColumnMode;
  recordcount: any;
  constructor(private readonly SpinnerService: NgxSpinnerService, private unresolvedSlctvtyService: UnresolvedSlctvtyService, private exportService: ExportToExcelService, public globalrole: GlobalService,) { }

  async ngOnInit(): Promise<void> {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    this.SpinnerService.hide();
    if (a) {
      console.log('a', a)
      //grid
      this.onloadfn();
    }
  }

  onloadfn() {
    //to get namc
    this.unresolvedSlctvtyService.getNamc().subscribe(data => {
      console.log(data);
      this.namcLst = data.payload;
    });


    //to get family
    this.unresolvedSlctvtyService.getFamily(this.namc).subscribe(data => {
      console.log(data);
      this.familyLst = data.payload;
    });

    //to get katashiki
    this.unresolvedSlctvtyService.getKatashiki(this.family).subscribe(data => {
      console.log(data);
      this.katashikiLst = data.payload;
    });
  }

  getFamCdByNamc() {
    if (this.namc == undefined) {
      this.namc = "";
    }
    //to get family
    this.unresolvedSlctvtyService.getFamily(this.namc).subscribe(data => {
      console.log(data);
      this.familyLst = data.payload;
      this.family = "";
    });
  }

  getKatashikiByFamCd() {
    if (this.family == undefined) {
      this.family = "";
    }
    this.unresolvedSlctvtyService.getKatashiki(this.family).subscribe(data => {
      console.log(data);
      this.katashikiLst = data.payload;
      this.katashiki = "";
    });
  }
  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  //ngx-table
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }

  //export function
  exportFile(): void {

    this.exportData = [];
    if (this.rows.length != 0) {
      this.rows.forEach(row => {
        let obj = {
          "NAMC": row.namc_cd,
          "Family": row.fam_cd,
          "Katashiki": row.katashiki_cd,
          "Mfg Spec Code": row.mfg_spec_cd,
          "GRPI": row.grpi_cd,
          "Parent Part": row.par_part_no,
          "Part Number": row.part_no,
          "Part Description": row.part_desc,
          "Selectivity Code": row.selectivity_cd,
          "Complete Part Routing": row.complete_routing,
          "VIN": row.vin,
          "BoM Effectivity Date": row.prod_trnfr_dt,
          "Suspension Date": row.excp_dt,
          "Prod Def ID": row.prod_def_id,
          "Prod BoM ID": row.prod_bom_id
        }
        this.exportData.push(obj);
      });
    } else {
      let obj =
      {
        "NAMC": "",
        "Family": "",
        "Katashiki": "",
        "Mfg Spec Code": "",
        "GRPI": "",
        "Parent Part": "",
        "Part Number": "",
        "Part Description": "",
        "Selectivity Code": "",
        "Complete Part Routing": "",
        "VIN": "",
        "BoM Effectivity Date": "",
        "Suspension Date": "",
        "Prod Def ID": "",
        "Prod BoM ID": "",
      }
      this.exportData.push(obj)
    }
    this.exportService.exportAsExcelFileForReport(this.exportData, this.fileName);
    this.exportData = []
  }

  //generate func
  generateReport(): void {
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      this.showGrid = true;
      this.showButtons = true;
      this.errorMessage = "";
      this.getGridData();
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  //alert close
  closeAlert(): void {
    this.errorMessage = '';
  }


  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'startDate') {
      this.startDate = "";
    } else if (name == 'endDate') {
      this.endDate = "";
    }
  }
  ////get from s3 Grid search data
  async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    let a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.log(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.log('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.log(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.log("res and reult", res, result)
          if (res.length > 0) {
            this.rows = res;
            console.log(this.rows);
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.unresolvedSlctvtyService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data?Storage?Disconnected.');
          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable?to?get?keys?and?token', 'Failed');
      });
    })
  }
  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_7215.05') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.accessEnable = true;
            resolve(true);
          }
        else {
          console.log(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.log(this.roleAccess);
    })
  }
}
