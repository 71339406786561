<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>NAMC<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select name="w3review" rows="2" cols="30" [(ngModel)]="namc"
                (change)="getFamilyByNamcSpr();getcompByNamc()">
                <option [value]=""></option>
                <option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</option>
              </select></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Family<span class="text-danger">*</span>:</label></div>
            <div class=col-8><select name="w3review" rows="2" cols="30" [(ngModel)]="family"
                aria-label="Default select example" (change)="grpiEnable();getSelectivityByFam()">
                <option value=""></option>
                <option *ngFor="let family of familyLst" [value]="family.fam_cd">{{family.fam_cd}}</option>
              </select></div>
          </div>
        </div>
      </div>

    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>GRPI<span class="text-danger">*</span>:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="grpi" [maxLength]="8" [disabled]="hidedata"
              class="inputtext"> </div>
        </div>
      </div>


      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Selectivity<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select name="w3review" rows="2" cols="30" [(ngModel)]="selectivity"
              aria-label="Default select example">
              <option value=""></option>
              <option *ngFor="let selectivity of selectivityLst" [value]="selectivity.slctv_typ">
                {{selectivity.slctv_typ}}</option>
            </select></div>
        </div>
      </div>
    </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>Part Number<span class="text-danger">*</span>:</label></div>
          <div class=col-8><input type="text" oninput="this.value = this.value.toUpperCase()" [maxLength]="10"
              [(ngModel)]="partNumber" class="inputtext"> </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Parent Part:</label></div>
          <div class=col-8><input type="text" oninput="this.value = this.value.toUpperCase()" [maxLength]="10"
              class="inputtext" [(ngModel)]="parentPart"> </div>
        </div>
      </div>
    </div>

    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>Complete Part Routing<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select [(ngModel)]="completePart" class="dateadd" aria-label="Default select example">
              <option value=""></option>
              <option *ngFor="let completePartRouting of completePartRoutingLst"
                [value]="completePartRouting.part_src_rtg_id">{{completePartRouting.cmplt_rtg_cd}} </option>
            </select></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">

        </div>
      </div>
    </div>


    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Selectivity Rule VIN<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select name="w3review" rows="2" cols="30" [(ngModel)]="selectivityRuleVIn"
              aria-label="Default select example">
              <option value=""></option>
              <option *ngFor="let selectivityRuleVin of selectivityRuleVinLst"
                [value]="selectivityRuleVin.vin_slctv_rule_typ_id ">{{selectivityRuleVin.slctv_rule_nm}}</option>
            </select></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Selectivity Rule Rep<span class="text-danger">*</span>:</label></div>
          <div class=col-8><select name="w3review" rows="2" cols="30" [(ngModel)]="selectivityRuleRep"
              aria-label="Default select example">
              <option value=""></option>
              <option *ngFor="let selectivityRuleRep of selectivityRuleRepLst"
                [value]="selectivityRuleRep.rep_slctv_rule_typ_id ">{{selectivityRuleRep.slctv_rule_nm}}</option>
            </select></div>
        </div>
      </div>
    </div>


    <!-- row6 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Selectivity Rule Time In<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input class="pt-1" matInput [matDatepicker]="inPicker" [(ngModel)]="extTimeIn">
              <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
              <mat-datepicker #inPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'extTimeIn')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Selectivity Rule Time Out:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input class="pt-1" matInput [matDatepicker]="outPicker" [(ngModel)]="extTimeOut">
              <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
              <mat-datepicker #outPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'extTimeOut')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- row7 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
          [(ngModel)]="comments">
          </textarea>
      </div>
    </div>
  </div>


  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="updateSelPartRule()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>

<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>