import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('Ebom_accessToken'),
      'x-api-key':localStorage.getItem('Filename')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class HunderedSpecificationsService {

  namc = '/getNamcHps';
  family = '/getFamilyByNamcHps';
  hikiate = '/getHikiateStatusHps';
  destination = '/getDestByKatashikiHps';
  katashiki = "/getKatashikiHps";
  pcSpec = "/getPcSpecHps";
  gridData = '/getHunPerSpecGrid';

  constructor(private http: HttpClient) { }

  //grid data
  getGridData(gridData): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = environment.MastergridapiUrl + this.gridData + '?namc=' + gridData.namc
      + '&family=' + gridData.family + '&katashiki=' + gridData.katashiki + '&destination=' + gridData.dest
      + '&hikiate=' + gridData.hikiate + '&pcSpec=' + gridData.pcSpec + '&comment=' + gridData.comments
      + '&hunSpecId=' + gridData.hundPercent + '&vdwEffIn=' + gridData.vdwEffIn + '&vdwEffOut=' + gridData.vdwEffOut
      + '&timeInDt=' + gridData.timeInDt + '&timeOutDt=' + gridData.timeOutDt + '&option=' + gridData.option+'&screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
  }

  //namc
  getNamcHps(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = environment.Mastersearchdropdown2apiUrl + this.namc+'?screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //family
  getFamilyHps(namc): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let famParams = new HttpParams().set('namcId', namc)
    let API_URL = environment.Mastersearchdropdown2apiUrl + this.family + '?namcId=' + namc+'&screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //hikiate
  getHikiateStatusHps(namc, family, katashiki, destination): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }

    let API_URL = environment.Mastersearchdropdown2apiUrl + this.hikiate + '?namcId=' + namc +
      '&famCd=' + family + '&katashikiCd=' + katashiki + '&destId=' + destination+'&screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //destination
  getDestHps(katashiki): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let destParams = new HttpParams().set('katashikiCd', katashiki)
    let API_URL = environment.Mastersearchdropdown2apiUrl + this.destination + '?katashikiCd=' + katashiki+'&screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //katashiki
  getKatashikiHps(namc, family): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let katasParams = new HttpParams().set('namcId', namc).append('famCd', family)
    let API_URL = environment.Mastersearchdropdown2apiUrl + this.katashiki + '?namcId=' + namc +
      '&famCd=' + family+'&screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //psSpec
  getPcSpecHps(namc, family, katashiki, destination, hikiate): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let API_URL = environment.Mastersearchdropdown2apiUrl + this.pcSpec + '?namcId=' + namc +
      '&famCd=' + family + '&katashikiCd=' + katashiki + '&destId=' + destination + '&hikiateStatus=' + hikiate+'&screenNo=NBPC_9178'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage: any = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
