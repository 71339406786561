import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";
import { RepUnitEditDialogService } from "./rep-unit-edit-dialog.service";

@Component({
  selector: 'app-rep-units-edit-dialog',
  templateUrl: './rep-units-edit-dialog.component.html',
  styleUrls: ['./rep-units-edit-dialog.component.css']
})
export class RepUnitsEditDialogComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //errormessage
  errorMessage: string = "";

  //ngModel Values
  editMonthlyRep: any;
  editSapMtrlId: any;
  editNamc: any;
  editFamily: any;
  editPart: any;
  editPartDesc: any;
  editRelKatashiki: any;
  editProdType: any;
  editGrpi: any;
  editExtTimeIn: any;
  editExtTimeOut: any;
  editComments: any;
  editrepproddefId: any;
  //response from backend after insert/update records
  response: any;

  //to main page
  message = "Record Updated Successfully";

  spinnerMessage = "Please Wait...";
 
  constructor(public dialogRef: MatDialogRef<RepUnitsEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private readonly SpinnerService: NgxSpinnerService,
    private repUnitEditService: RepUnitEditDialogService) { }

  ngOnInit(): void {
    this.editMonthlyRep = this.data.monthlyRep;
    this.editSapMtrlId = this.data.sapMatId;
    this.editNamc = this.data.namc;
    this.editFamily = this.data.family;
    this.editPart = this.data.part;
    this.editPartDesc = this.data.partDesc;
    this.editRelKatashiki = this.data.katashiki;
    this.editProdType = this.data.prodType;
    this.editExtTimeIn = this.data.extractionTimeIn;
    this.editExtTimeOut = this.data.extractionTimeOut;
    this.editComments = this.data.comments;
    this.editrepproddefId = this.data.repProdDefId;
    this.editGrpi = this.data.grpi
  }

  //save
  updateRepUnit(): void {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');

    if (!this.editExtTimeIn) {
      this.errorMessage = "Please enter all mandatory field";
    }
  
    else if (this.editExtTimeIn && this.editExtTimeOut &&
      this.pipe.transform(this.editExtTimeIn, 'yyyy-MM-dd') > this.pipe.transform(this.editExtTimeOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    }
    else {
      this.errorMessage = "";
      let indateFormat = ''; let outdateFormat = '';
      if (this.editExtTimeIn != "") {
        let d1 = moment(this.editExtTimeIn).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.editExtTimeIn = date
      }
      if (this.editExtTimeOut != "") {
        let d1 = moment(this.editExtTimeOut).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.editExtTimeOut = date
      }
      if (this.editExtTimeOut == "") {
        this.editExtTimeOut = "9999-12-31"
      }
      let monthlyrep = "";
      if (this.editMonthlyRep == true) {
        monthlyrep = 'Y'
      } else if (this.editMonthlyRep == false) {
        monthlyrep = 'N'
      }
      if (this.editSapMtrlId == "") {
        this.editSapMtrlId = " "
      }
      let cmts;
      if (this.editComments != undefined) {
        cmts = this.editComments.replaceAll("'", "''");
      } else {
        cmts = this.editComments
      }
      let body =
      {
        'repProdDefid': this.editrepproddefId,
        'monRepFlg': monthlyrep,
        'sapMtrlId': this.editSapMtrlId,
        'extTimeIn': indateFormat,
        'extTimeOut': outdateFormat,
        'comment': cmts,
        "screenNo":"NBPC_9355",
        "fileName" :fileName
        
      }
      this.SpinnerService.show();
      this.repUnitEditService.updateRepUnit(body).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });

    }

  }

  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'editExtTimeIn') {
      this.editExtTimeIn = "";
    } else if (name == 'editExtTimeOut') {
      this.editExtTimeOut = "";
    }
  }
}
