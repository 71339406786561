import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { PcSpecsExclusionListService } from './pc-specs-exclusion-list.service';
import { PcSpecsExclusionListEditDialogComponent } from '../pc-specs-exclusion-list-edit-dialog/pc-specs-exclusion-list-edit-dialog.component';
import { PcSpecsExclusionListAddDialogComponent } from '../pc-specs-exclusion-list-add-dialog/pc-specs-exclusion-list-add-dialog.component';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';


@Component({
  selector: 'app-pc-specs-exclusion-list',
  templateUrl: './pc-specs-exclusion-list.component.html',
  styleUrls: ['./pc-specs-exclusion-list.component.css']
})
export class PcSpecsExclusionListComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //export to excel
  fileName = 'PCSpecsExclusionList';
  exportData = [];
  sheetName = [];

  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';

  //ngModel values for search
  step = 0;
  pcSpecPosition: any = "";
  comments: any = "";
  hikiateStatus: any = "";
  effectiveFrom: any = "";
  effectiveTo: any = "";
  spinnerMessage = "Please Wait...";

  //list
  pcSpecPositionLst: string[] = ['004', '005', '006', '007', '008'];
  commentsLst: string[] = ['A', 'B', 'C'];
  hikiateStatusLst: string[] = ['Automatic', 'Manual'];
  effectiveFromLst: string[] = ['08A', '000'];
  effectiveToLst: string[] = ['A', 'B', 'C'];

  //mat-dialog
  data: any = {};
  roleAccess: any = []
  Roledata: any = [];


  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showdata: boolean = true;
  columns = [

    { name: 'pcSpecPosition', prop: 'pc_spec_posn_cd' }, { name: 'hikiateStatus', prop: 'hikiate_stat_flg' },
    { name: 'effectiveFrom', prop: 'eff_dt' }, { name: 'effectiveTo', prop: 'expr_dt' },
    { name: 'comments', prop: 'rmrk_txt' }

  ];
  ColumnMode = ColumnMode;
  recordcount: any = 0;
  sameLengthRows: boolean = false;
  accessEnable: boolean;

  constructor(private PcSpecsExclusionList: PcSpecsExclusionListService, public dialog: MatDialog,
    private dialogData: DialogData, private exportService: ExportToExcelService, private readonly SpinnerService: NgxSpinnerService,
    public globalrole: GlobalService, ) {

  }

  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }

   this.onloadfn();

  }

  onloadfn(){
     //to get pc spec position
     this.PcSpecsExclusionList.getPcSpecPosition().subscribe(data => {
      console.log(data);
      this.pcSpecPositionLst = data.payload;

    });


  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length == selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length == 0) {
      this.sameLengthRows = false
    }
    console.log('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  closeAlert() {
    this.errorMessage = "";
    this.successMessage = "";
  }
  exportFile(): void {

    this.rows.forEach(row => {
      let obj = {
        "Pc Spec Position": row.pc_spec_posn_cd,
        "Hikiate Status": row.hikiate_stat_flg,
        "Effective From": row.eff_dt,
        "Effective To": row.expr_dt,
        "Comments": row.rmrk_txt,
      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = [];
  }
  openAddDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(PcSpecsExclusionListAddDialogComponent, { disableClose: true });


      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result.data);
        if (result == 'success') {
         this.getGridData("add")
          this.successMessage = "Data Added Successfully"
          this.selected = []
          
        }
        else if (result == 'AlreadyExists') {
          this.resetAll("initial");
          this.errorMessage = 'Data Entered already Exists!';
          this.globalrole.goToTop();
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }

  openEditDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(PcSpecsExclusionListEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');

          if (result == 'success') {
            this.getGridData("add");
            this.successMessage = "Records Updated Successfully"
            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            // this.resetAll("initial");
            this.errorMessage = 'Data Entered already Exists!';
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }

        });

      }
      else {
        if (this.sameLengthRows == true) {
          this.errorMessage = "please select only one row to edit"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows == false) {
          this.errorMessage = "please select a row to edit"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }

  sendToDialog(selected): void {

    selected.forEach(row => {
      this.dialogData.pcSpecPosition = row.pc_spec_posn_cd;
      this.dialogData.hikiateStatus = row.hikiate_stat_flg;
      this.dialogData.effectiveFrom = this.pipe.transform(row.eff_dt, 'yyyy-MM-dd');
      this.dialogData.effectiveTo = this.pipe.transform(row.expr_dt, 'yyyy-MM-dd');
      this.dialogData.comments = row.rmrk_txt;
      this.dialogData.pc_spec_excl_set_id = row.pc_spec_excl_set_id;
    });



  }
  resetAll(data): void {
    this.selected=[]
    this.pcSpecPosition = '';
    this.comments = '';
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();


  }

  search() {
    this.errorMessage = '';
    this.successMessage = ""
    this.getGridData("initial");
  }

  getGridData(data) {
    this.selected=[]
    console.log(this.roleAccess.includes('Read'));
    this.errorMessage='';
    this.successMessage='';
    if (this.roleAccess.includes('Read')) {
      if (this.pcSpecPosition == undefined) {
        this.pcSpecPosition = ""
      }
      if (this.effectiveFrom != "") {
        this.effectiveFrom = this.pipe.transform(this.effectiveFrom, 'yyyy-MM-dd')
      }
      if (this.effectiveTo != "") {
        this.effectiveTo = this.pipe.transform(this.effectiveTo, 'yyyy-MM-dd')
      }
      let gridData 
      let cmts;
    if (this.comments!= undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
      if(data=="initial"){
      gridData =
      {
        pcSpecPosition: this.pcSpecPosition,
        hikiateStatus: this.hikiateStatus,
        effectiveFrom: this.effectiveFrom,
        effectiveTo: this.effectiveTo,
          comments: cmts,
        option: data,

        }
      }
      else {
        gridData =
      {
        pcSpecPosition: "",
        hikiateStatus: "",
        effectiveFrom: "",
        effectiveTo: "",
        comments: "",
        option: data,

      }
      }
      this.SpinnerService.show();
      this.PcSpecsExclusionList.getPcSpecsExclusionListGrid(gridData).subscribe(data => {
        console.log(data);
        this.showdata = true;
        this.recordcount = data.pagination.totalRecords;
        console.log(this.showdata);
        this.SpinnerService.hide();
        let datarow = data.payload;
        this.rows = datarow.map(row => ({
          pc_spec_posn_cd: row['pc_spec_posn_cd'],
          hikiate_stat_flg: row['hikiate_stat_flg'],
          eff_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
          expr_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],
          rmrk_txt: row['rmrk_txt'],
          pc_spec_excl_set_id: row['pc_spec_excl_set_id']
        }));
        console.log(this.rows);
      }, err => {
        console.log(err);
        this.showdata = false;
        this.successMessage = "";
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_2271') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true)
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true)
          }
        else {
          console.log(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.log(this.roleAccess);
    });
  }

}
