import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import * as XLSX from 'xlsx';
import * as S3 from 'aws-sdk/clients/s3';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { RepVehicleUploadDialogService } from './rep-vehicle-upload-dialog.service';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../global.service';
@Component({
  selector: 'app-rep-vehicle-upload-dialog',
  templateUrl: './rep-vehicle-upload-dialog.component.html',
  styleUrls: ['./rep-vehicle-upload-dialog.component.css']
})
export class RepVehicleUploadDialogComponent implements OnInit {
  [x: string]: any;
  fileName: string = '';
  fileUploaded: any;
  jsonData: any;
  storeData: any;
  worksheet: any;

  //excel details
  responseLst = [];
  cnt: any = 0;
  fileTyp: any;
  insertedAttachmentData: any;
  accessKey: any
  secretKey: any
  sessionToken: any
  fileFormat: any
  //to main page
  message = "Records Updated Successfully";
  index: any;
  spinnerMessage = "Please Wait...";
  fileundefined: boolean = false;
  constructor(public dialogRef: MatDialogRef<RepVehicleUploadDialogComponent>, public globalService: GlobalService,
    private readonly SpinnerService: NgxSpinnerService, private repUploadService: RepVehicleUploadDialogService,
    private exportService: ExportToExcelService) { }

  ngOnInit(): void {

  }

  uploadFile($event: any) {
    this.SpinnerService.show();
    let self = this;
    const files = $event.target.files;
    self.showAttachErrorMsg = false
    self.exeFile = false;
    self.fileFormat = false;
    if (files.length) {
      const file = files[0];
      let filechange = file.name
      this.fileName = filechange
      console.log(this.fileName)
      this.fileTyp = file.type
      console.log(this.fileTyp)
      let chkfilename = this.fileName.split('.').pop()
      console.log(chkfilename)
      let reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        let csv: any = reader.result;
        let allTextLines = [];
        allTextLines = csv.split(/\r|\n|\r/);
        if (((chkfilename == 'xlsx' || chkfilename == 'xls' || chkfilename == 'csv') && (!allTextLines[0].startsWith('MZ')))) {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            const wb = XLSX.read(event.target.result, { dateNF: 'YYYY-MM-DD' });
            const sheets = wb.SheetNames;
            if (sheets.length) {
              const data = XLSX.utils.sheet_to_csv(wb.Sheets[sheets[0]]);
              console.log('data: ', '\ufeff' + data);
              let blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });

              console.log(blob)
              // FileSaver.saveAs(blob, 'test.csv');
              this.fileUploaded = blob
              this.changefilename = filechange.split('.')[0] + '.csv'
              console.log(this.changefilename)
            }
            if (files.item(0).size > 2 * 1024 * 1024) {
              self.showAttachErrorMsg = true
              self.exeFile = false;
            } else {
              self.showAttachErrorMsg = false
              self.exeFile = false;
              self.fileFormat = false;
            }
          }
          reader.readAsArrayBuffer(file);
          this.SpinnerService.hide();
        } else {
          self.fileFormat = true;
          self.showAttachErrorMsg = false
          this.SpinnerService.hide();
        }
      }
    }
    this.SpinnerService.hide();
  }

  getProcStatus() {
    let fileName= localStorage.getItem('Filename')
    console.log(this.fileUploaded)
    if (this.fileUploaded != undefined) {
      this.SpinnerService.show()
      let body = {
        'proc_nm': 'REP_VEH_UPLOAD',
        "screenNo":"NBPC_9350",
        "fileName" :fileName

      }
      this.globalService.getProcStatus(body).subscribe(data => {
        console.log(data);
        this.response = data.payload;

        if (this.response[0].proc_status != 'INITIATED') {
          this.updatetProcProcessStatus()
        }
        else if (this.response[0].proc_status == 'INITIATED') {
          this.dialogRef.close("Previous Upload already is Inprogress. Please upload later");
          this.SpinnerService.hide()
        }
      }, err => {
        this.SpinnerService.hide();
        this.dialogRef.close("Function Failed");
      });
    }
    else {
      this.fileundefined = true
    }
  }

  trunctmptbl(Tablename) {
    let fileName= localStorage.getItem('Filename')
    let body = {
      "table_name": Tablename,
      "screenNo":"NBPC_9350",
      "fileName" :fileName

    }

    this.globalService.truncTempRepProd(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.uploadRepVeh()
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }

  updatetProcProcessStatus() {
    let fileName= localStorage.getItem('Filename')
    let mail = localStorage.getItem('emailId');
    let user = localStorage.getItem('workDayId');
    let body = {
      'mailId': mail,
      
      'proc_nm': 'REP_VEH_UPLOAD',
      'proc_status': 'INITIATED',
      "screenNo":"NBPC_9350",
      "fileName" :fileName
    }
    this.globalService.editProcProcessStatus(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        // this.uploadRepVeh()
        this.trunctmptbl('EBOM_ADM.TMP_UPD_REP_VEH');
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }


  async uploadRepVeh() {
    this.SpinnerService.show()
    let x = await this.insertAttachments()
    if (x) {
      console.log("x", x);
      this.SpinnerService.hide()
      this.dialogRef.close('success');
    }
    else {
      this.SpinnerService.hide()
      // this.dialogRef.close('Failed');
    }

  }

  async insertAttachments() {
    return new Promise(async resolve => {
      let a = await this.getS3Info();
      if (a) {
        const bucket = new S3(
          {
            accessKeyId: this.accessKey,
            secretAccessKey: this.secretKey,
            sessionToken: this.sessionToken,
            region: environment.region
          }
        );
        console.log("this.fileUpload", this.fileUploaded);
        if (this.fileUploaded == undefined) {
          this.SpinnerService.hide();
          resolve(false)
        }
        if (this.exeFile == true) {
          this.fileFormat = true;
          this.SpinnerService.hide();
          resolve(false)
        }
        if (this.fileUploaded != undefined && this.exeFile == false) {
          this.fileFormat = false;
          var fileName = this.changefilename.replace(/[&\/\\#, +()$~%@'":*?<>{}]/g, '_');

          const params1 = {
            Bucket: environment.uploadBucketName + '/RepVehiclesScreen',
            Key: fileName,
            Body: this.fileUploaded,
            ServerSideEncryption: "AES256"
          };
          console.log('bucket', bucket.endpoint, 'params', params1)
          bucket.upload(params1, function (err, data) {
            if (err) {
              console.log('There was an error uploading your file: ', err);
              return err;
            }
            console.log('Successfully uploaded file.', data);
            this.insertedAttachmentData = data
            resolve(data)

          });
        }
      }
    })

  }

  getS3Info() {
    return new Promise(resolve => {
      this.repUploadService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data�Storage�Disconnected.');

          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable�to�get�keys�and�token', 'Failed');
      });
    })
  }



  cancel() {
    this.message = "cancel";
    this.dialogRef.close(this.message);
  }


}
