import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { DialogData } from '../dialog-data/dialog-data';
import { FamilyEditDialogService } from './family-edit-dialog.service';

@Component({
  selector: 'app-family-edit-dialog',
  templateUrl: './family-edit-dialog.component.html',
  styleUrls: ['./family-edit-dialog.component.css']
})
export class FamilyEditDialogComponent implements OnInit {
  [x: string]: any;
   //Date transform
   pipe = new DatePipe('en-US');
   currentDate = moment().format('DD/MM/YYYY');
  //to main page
  message = "Records Edited Successfully";
  errorMessage = "";
  index: any;

  //ngModelValues
  eFamily: any;
  eFamilyDescription: any;
  eSeries: any;
  eGeneration: any;
  eCarFamilyFlag: any;
  eEffectiveInDate: any;
  eEffectiveOutDate: any;



  //respone after inserting a record
  response: any;

  constructor(private FamilyEditDialogService: FamilyEditDialogService, public dialogRef: MatDialogRef<FamilyEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,public SpinnerService:NgxSpinnerService) { }

  ngOnInit(): void {
    this.eFamily = this.data.family;
    this.eFamilyDescription = this.data.familyDescription;
    this.eSeries = this.data.series;
    this.eGeneration = this.data.generation;
    if (this.data.carFamilyFlag == 'Y') {
      this.eCarFamilyFlag = true
    }
    if (this.data.carFamilyFlag == 'N') {
      this.eCarFamilyFlag = false
    }

    this.eEffectiveInDate = this.data.effectiveInDate;
    this.eEffectiveOutDate = this.data.effectiveOutDate;
  }


  //closealert
  closeAlert() {
    this.errorMessage = "";
  }

  uploadFam() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');

    console.log( this.pipe.transform(this.eEffectiveInDate, 'yyyy-MM-dd') ,  this.pipe.transform(this.eEffectiveOutDate, 'yyyy-MM-dd'));

    if (!this.eEffectiveInDate || !this.eEffectiveOutDate) {

      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.eEffectiveInDate && this.eEffectiveOutDate &&
      this.pipe.transform(this.eEffectiveInDate, 'yyyy-MM-dd') > this.pipe.transform(this.eEffectiveOutDate, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
    }
    
    else {

      let indateFormat = ''; let outdateFormat = '';
      if (this.eEffectiveInDate != "") {
        let d1 = moment(this.eEffectiveInDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.eEffectiveInDate = date
      }
      if (this.eEffectiveOutDate != "") {
        let d1 = moment(this.eEffectiveOutDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.eEffectiveOutDate = date
      }
      this.errorMessage = "";
      let body = {
        'eff_dt': indateFormat,
        'expr_dt': outdateFormat,
        'carFamFlg': this.eCarFamilyFlag== true ? 'Y' : 'N',
        'famCd': this.eFamily,
        "screenNo":"NBPC_9995",
        "fileName" :fileName
        
      }
      this.SpinnerService.show();
      this.FamilyEditDialogService.editFam(body).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });

  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'eEffectiveInDate') {
      this.eEffectiveInDate = "";
    } else if (name == 'eEffectiveOutDate') {
      this.eEffectiveOutDate = "";
    }
  }

}
