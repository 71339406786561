import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogData } from '../dialog-data/dialog-data';
import { HunderedSpecificationsMultieditDialogService } from './hundered-specifications-multiedit-dialog.service';

@Component({
  selector: 'app-hundered-specifications-multiedit-dialog',
  templateUrl: './hundered-specifications-multiedit-dialog.component.html',
  styleUrls: ['./hundered-specifications-multiedit-dialog.component.css']
})
export class HunderedSpecificationsMultieditDialogComponent implements OnInit {
  //ngmodel
  edittimeInDt: any = "";
  edittimeOutDt: any = "";
  eComments: any = "";
  eHikiateStatus: any = "";

  spinnerMessage = "Please Wait...";
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  [x: string]: any;
  //to main page
  message = "Records Edited Successfully";
  index: any;
  errorMessage = "";
  ereqd_100pct_pc_spec_optn_id: any;
  constructor(private readonly SpinnerService: NgxSpinnerService, public dialogRef: MatDialogRef<HunderedSpecificationsMultieditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public HundredSpecMultiService: HunderedSpecificationsMultieditDialogService) { }

  ngOnInit(): void {
    console.log(this.data);
    let arrayvalue: any;
    arrayvalue = this.data;
    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = "'" + arrayvalue[i].reqd_100pct_pc_spec_optn_id + "'"
      } else {
        str += ',' + "'" + arrayvalue[i].reqd_100pct_pc_spec_optn_id + "'";
      }
      this.ereqd_100pct_pc_spec_optn_id = str;
      console.log(this.ereqd_100pct_pc_spec_optn_id)
    }
  }
  monthly: any = '';
  save() {
    console.log(this.monthly)
  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }


  multiEditHundPer() {
    console.log(this.data);
    let arrayvalues: any;
    let timedata: any = []
    arrayvalues = this.data;
    if (!this.edittimeInDt && !this.edittimeOutDt) {
      console.log('1')
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.edittimeInDt && this.edittimeOutDt &&
      this.pipe.transform(this.edittimeInDt, 'yyyy-MM-dd') > this.pipe.transform(this.edittimeOutDt, 'yyyy-MM-dd')) {
      console.log('2')
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    } else if (arrayvalues.length > 0) {
      console.log('3')
      for (let i = 0; i < arrayvalues.length; i++) {
        if (this.pipe.transform(arrayvalues[i].eff_dt, 'yyyy-MM-dd') > this.pipe.transform(this.edittimeOutDt, 'yyyy-MM-dd')) {
          this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
          timedata.push(arrayvalues[i].reqd_100pct_pc_spec_optn_id)
        } else if (this.edittimeInDt && this.edittimeOutDt &&
          this.pipe.transform(this.edittimeInDt, 'yyyy-MM-dd') > this.pipe.transform(this.edittimeOutDt, 'yyyy-MM-dd')) {
          console.log('2')
          this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
          timedata.push(arrayvalues[i].reqd_100pct_pc_spec_optn_id)
        }
      }
      if (timedata.length == '0') {
        this.finalCheck();
      }
    }
    else {
      // this.finalCheck();
    }
  }
  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  finalCheck() {
    let fileName= localStorage.getItem('Filename')
    let text = "This action may impact multiple Family Codes. Do you want to proceed?";
    if (confirm(text) == true) {
      let user = localStorage.getItem('workDayId');
      this.errorMessage = "";
      if (this.edittimeOutDt == "") {
        this.edittimeOutDt = "9999-12-31"
      }
      let effInformat = ''; let effOutFormat = ""
      if (this.edittimeInDt != "") {
        let d1 = moment(this.edittimeInDt).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        effInformat = moment(date).format('YYYY-MM-DD')
        this.edittimeInDt = date
      }
      if (this.edittimeOutDt != "") {
        let d1 = moment(this.edittimeOutDt).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        effOutFormat = moment(date).format('YYYY-MM-DD')
        this.edittimeOutDt = date
        // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
      }
      let cmts;
    if (this.eComments!= undefined) {
      cmts =this.eComments.replaceAll("'", "''");
    } else {
      cmts = this.eComments
    }
      let body = {
        "hikistat": this.eHikiateStatus,
        "effIn": effInformat,
        "exprOut": effOutFormat,
        "comment": cmts,
        "req100Pcid": this.ereqd_100pct_pc_spec_optn_id,
        'screenNo':'NBPC_9178',
        'fileName': fileName
        
      }
      this.SpinnerService.show();
      this.HundredSpecMultiService.updateMultihundspec(body).subscribe(data => {
        this.response = data.payload;
        this.SpinnerService.hide();
        if (this.response[0].result == 'success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result == 'AlreadyExists') {
          // this.dialogRef.close(this.response[0].result);
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });
    } else {
      console.log("cancel")
    }
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'edittimeInDt') {
      this.edittimeInDt = "";
    } else if (name == 'edittimeOutDt') {
      this.edittimeOutDt = "";
    }
  }
}
