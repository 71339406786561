import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogData } from '../dialog-data/dialog-data';
import { VersionTypesDeleteDialogService } from './version-types-delete-dialog.service';

@Component({
  selector: 'app-version-types-delete-dailog',
  templateUrl: './version-types-delete-dailog.component.html',
  styleUrls: ['./version-types-delete-dailog.component.css']
})
export class VersionTypesDeleteDailogComponent implements OnInit {
  [x: string]: any;


  //to main page
  message = "Record Deleted Successfully";

  spinnerMessage = "Please Wait...";

  verTypIdvalue: any;

  //response from backend after insert/update records
  response: any;

  constructor(public dialogRef: MatDialogRef<VersionTypesDeleteDailogComponent>,
    private readonly SpinnerService: NgxSpinnerService, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private verTypDeleteService: VersionTypesDeleteDialogService) { }

  ngOnInit(): void {
    console.log(this.data.verTypId)
    let arrayvalue: any;
    arrayvalue = this.data;

    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = arrayvalue[i].bom_ver_typ_id
      } else {
        str += ',' + arrayvalue[i].bom_ver_typ_id
      }

      this.verTypIdvalue = str;
    }
    console.log(this.ebomvertypid)
    //this.verTypIdvalue = this.data.verTypId;

  }

  //save
  deleteVerTyp(): void {
    let fileName= localStorage.getItem('Filename')
    let body = {
      'bomvertypid': this.verTypIdvalue,
      'screenNo':"NBPC_10105",
      'fileName': fileName
    }
    this.SpinnerService.show();
    this.verTypDeleteService.deleteVersionType(body).subscribe(data => {
      this.response = data.payload;
      this.SpinnerService.hide();
      console.log(data);
      this.dialogRef.close(this.response[0].result);
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Representative product version cannot be deleted");
    });

  }

  cancel(): void {
    this.message = "";
    this.dialogRef.close('cancel');
  }

}
