<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Add <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>

  </div>

  <mat-dialog-content class="mat-typography">
    <div class="container">
      <span class="fst-italic">Field marked with asterisk '<span class="text-danger">*</span>' are mandatory</span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Pc Spec Position<span class="text-danger">*</span>:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="ePcSpecPosition" maxlength="3" class="inputtext"></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Hikiate Status:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="eHikiateStatus" class="inputtext"></div>
          </div>
        </div>
      </div>

      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Effective From<span class="text-danger">*</span>:</label></div>
            <div class=col-8>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="inPicker" [(ngModel)]="eEffectiveFrom">
                <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
                <mat-datepicker #inPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'eEffectiveFrom')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Effective To<span class="text-danger">*</span>:</label></div>
            <div class=col-8>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="outPicker" [(ngModel)]="eEffectiveTo">
                <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
                <mat-datepicker #outPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'eEffectiveTo')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>


    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="2" cols="50"
          [(ngModel)]="eComments">
          </textarea>
      </div>
    </div>

  </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button cdkFocusInitial (click)="save()">Save</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>