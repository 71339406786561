<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>View <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <!-- row1 -->
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Monthly Representative:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="vMonthlyRep" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Comparitive BOM:</label></div>
            <div class=col-2><input type="checkbox" [(ngModel)]="vCompBom" disabled></div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Model Number:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vModelNumber" class="inputtext" disabled></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Option:</label></div>
            <div class=col-8><input type="text" [(ngModel)]="vOption" class="inputtext" disabled></div>
          </div>
        </div>
      </div>

    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row ">
          <div class="col-4"> <label>SAP Material ID:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vSapMatId" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">

        </div>
      </div>
    </div>

    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>NAMC:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vNamc" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Family:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vFamily" class="inputtext" disabled></div>
        </div>
      </div>
    </div>

    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Katashiki:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vKatashiki" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Customer:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vCustomerId" class="inputtext" disabled></div>
        </div>
      </div>
    </div>

    <!-- row6 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Dest:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vDest" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Hikiate Status:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vHikiate" class="inputtext" disabled></div>
        </div>
      </div>
    </div>

    <!-- row7 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>S/P:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vSP" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>PC Spec:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vPcSPec" class="inputtext" disabled></div>
        </div>
      </div>
    </div>

    <!-- row8 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Comp PC Spec:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vCompPcSpec" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Net Spec:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vNetSpec" class="inputtext" disabled></div>
        </div>
      </div>
    </div>



    <!-- row9 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>100% Spec:</label></div>
          <div class=col-2><input type="checkbox" [(ngModel)]="vHundPercentSpec" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Comp 100% Spec:</label></div>
          <div class=col-2><input type="checkbox" [(ngModel)]="vCompHundPercentSpec" disabled></div>
        </div>
      </div>
    </div>

    <!-- row10 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Extraction Time In:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vExtractionTimeIn" class="inputtext" disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Extraction Time Out:</label></div>
          <div class=col-8><input type="text" [(ngModel)]="vExtractionTimeOut" class="inputtext" disabled></div>
        </div>
      </div>
    </div>

    <!-- row11 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" [(ngModel)]="vComments" disabled
          name="w3review" rows="4" cols="50">
          </textarea>
      </div>
    </div>

    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-raised-button mat-dialog-close>OK</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>