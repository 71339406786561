<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Please select the fields to generate the reports</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>R/A</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="ra" name="ra">
        <mat-option value="">ALL</mat-option>
        <mat-option value="R">R</mat-option>
        <mat-option value="A">A</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>NAMC</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="namc" name="namc">
        <mat-option value="">ALL</mat-option>
        <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>VIN/Katashiki</mat-label>
      <input matInput placeholder="" [(ngModel)]="vinKatashiki">
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="startDate" >
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker ></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'startDate')">clear</mat-icon>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="endDate" >
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker ></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'endDate')">clear</mat-icon>
    </mat-form-field>
    <button mat-button style="float: right;" (click)="generateReport()">Generate Report</button>
  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex" *ngIf="showButtons">
  <button mat-raised-button (click)="exportFile()" class="ms-auto"> Export To Excel </button>
</div>

<!-- <mat-card> -->
<ngx-datatable #myTable *ngIf="showGrid" class="material striped" [rows]="rows" [columnMode]="ColumnMode.force"
  [headerHeight]="45" [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">

  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">

      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

        </div>
      </div>
    </ng-template>
  </ngx-datatable-footer>

    <ngx-datatable-column name="R/A" prop="bom_typ_cd"></ngx-datatable-column>
    <ngx-datatable-column name="VIN/Katashiki" prop="vin_katashiki_cd"></ngx-datatable-column>
    <ngx-datatable-column name="NAMC" prop="namc_cd" width=200></ngx-datatable-column>
    <ngx-datatable-column name="Pc Spec" prop="pc_spec"></ngx-datatable-column>
    <ngx-datatable-column name="BOM Effectivity Date" prop="prod_trnfr_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Suspension Date" prop="excp_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Prod Def Id" prop="prod_def_id"></ngx-datatable-column>
    <ngx-datatable-column name="Prod Bom Id" prop="prod_bom_id"></ngx-datatable-column>
  </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>