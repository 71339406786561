<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>


<div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
  {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>
<mat-accordion class="example-headers-align">
  <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Search</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>NAMC</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="namc" name="namc"  >
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Family</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="fam" name="family">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let fam of famLst" [value]="fam.fam_cd">{{fam.fam_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Product Type</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="productType" name="productType">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let productType of productTypeLst" [value]="productType.prod_typ_desc">
          {{productType.prod_typ_desc}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Active Flag</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="activeFlag" name="activeFlag">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let activeFlag of activeFlagLst" [value]="activeFlag.incl_flg">{{activeFlag.incl_flg}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>User Code</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="userCode" name="userCode">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let userCode of userCodeLst" [value]="userCode.serl_no">{{userCode.serl_no}}</mat-option>
      </mat-select>
    </mat-form-field>



    <mat-form-field class="picker">
      <mat-label>Effective In Date</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="effDateIn" >
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker ></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effDateIn')">clear</mat-icon>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>Effective Out Date</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="effDateOut" >
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker ></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'effDateOut')">clear</mat-icon>
    </mat-form-field>




    <button mat-button style="float: right;" (click)="resetAll('initial')">Reset</button>
    <button mat-button style="float: right; " (click)="search()">Search</button>
  </mat-expansion-panel>
</mat-accordion>




<div class="d-flex">
  <button mat-raised-button (click)="openAddDialog()">Add </button>
  <button mat-raised-button (click)="openEditDialog()">Edit </button>
  <button mat-raised-button (click)="openDeleteDialog()">Delete </button>
  <button mat-raised-button (click)="exportFile()" class="ms-auto"> Export To Excel </button>
</div>

<!-- <mat-card> -->
<ngx-datatable #myTable class="material striped" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="45"
  [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
    </ngx-datatable-column>

    <ngx-datatable-column class="w-50" name="NAMC" prop="namc_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Product Type" prop="prod_typ_desc"></ngx-datatable-column>
    <ngx-datatable-column name="Active Flag">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="incl_flg">
        <input type="checkbox" [checked]="row.incl_flg=='Y'" disabled />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="User Code" prop="serl_no"></ngx-datatable-column>
    <ngx-datatable-column name="GRPI Code" prop="grpi_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Effective In Date" prop="eff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Effective Out Date" prop="expr_dt"></ngx-datatable-column>
  </ngx-datatable>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>


<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>