<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

<div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
  {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
</div>

  <mat-dialog-content class="mat-typography" >
    <div class="container">
      <span class="fst-italic">Field marked with asterisk ' <span class="text-danger">*</span> ' are mandatory</span>

    <!-- row1 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><label>Monthly Representative:</label></div>
          <div class="col-2"><input type="checkbox" value="editMonthlyRep" [(ngModel)]="editMonthlyRep"></div>
        </div>
      </div>
    </div>
    <!-- row2 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>SAP Material ID:</label></div>
          <div class=col-8><input type="text" value="editSapMtrlId" [maxLength]="20"
              oninput="this.value = this.value.toUpperCase()" [(ngModel)]="editSapMtrlId" class="inputtext"></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>NAMC:</label></div>
          <div class=col-8><input type="text" value="editNamc" [(ngModel)]="editNamc" class="inputtext" disabled></div>
        </div>
      </div>
    </div>
    <!-- row3 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Family:</label></div>
          <div class=col-8><input type="text" value="editFamily" [(ngModel)]="editFamily" class="inputtext" disabled>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Part:</label></div>
          <div class=col-8><input type="text" value="editPart" [(ngModel)]="editPart" class="inputtext" disabled></div>
        </div>
      </div>
    </div>
    <!-- row4 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Part Description:</label></div>
          <div class=col-8><input type="text" value="editPartDesc" [(ngModel)]="editPartDesc" class="inputtext"
              disabled></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Related Katashiki:</label></div>
          <div class=col-8><input type="text" value="editRelKatashiki" [(ngModel)]="editRelKatashiki" class="inputtext"
              disabled></div>
        </div>
      </div>
    </div>
    <!-- row5 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Product Type:</label></div>
          <div class=col-8><input type="text" value="editProdType" [(ngModel)]="editProdType" class="inputtext"
              disabled></div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-4"> <label>Grpi Code:</label></div>
          <div class=col-8>
            <input type="text" class="inputtext" [(ngModel)]="editGrpi" disabled>
          </div>
        </div>
      </div>
    </div>
    <!-- row6 -->
    <div class="row rowClass">
      <div class="col-md-6">
        <div class="row" style="margin-top: 5px">
          <div class="col-4"> <label>Extraction Time In<span class="text-danger">*</span>:</label></div>
          <div class=col-8>
            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="inPicker" [(ngModel)]="editExtTimeIn">
              <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
              <mat-datepicker #inPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'editExtTimeIn')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row" style="margin-top: 5px">
          <div class="col-4"> <label>Extraction Time Out:</label></div>
          <div class=col-8>

            <mat-form-field class="picker" appearance="outline">
              <input matInput [matDatepicker]="outPicker" [(ngModel)]="editExtTimeOut">
              <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
              <mat-datepicker #outPicker ></mat-datepicker>
              <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'editExtTimeOut')">clear</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <!-- row7 -->
    <div class="row rowClass">
      <div class="col-md-2 col-xl-2 col-lg-2 col-sm-4"> <label>Comments:</label></div>
      <div class="col-md-10 col-xl-10 col-lg-10 col-sm-8"><textarea class="w-100" name="w3review" rows="4" cols="50"
          [(ngModel)]="editComments">
        </textarea>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" >
    <button mat-button cdkFocusInitial (click)="updateRepUnit()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>