import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { DialogData } from '../dialog-data/dialog-data';
import { FamilyViewDialogComponent } from '../family-view-dialog/family-view-dialog.component';
import { FamilyEditDialogComponent } from '../family-edit-dialog/family-edit-dialog.component';
import { ExportToExcelService } from '../common/export-to-excel.service'
import { FamilyMultieditDialogComponent } from '../family-multiedit-dialog/family-multiedit-dialog.component';
import { FamilyServiceService } from './family-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';


@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.css']
})
export class FamilyComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //export to excel
  fileName = 'Family';
  exportData = [];
  sheetName = [];
  roleAccess: any = []
  Roledata: any = [];

  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';

  //ngModel values for search
  step = 0;
  family: string = "";
  effectiveInDate: any = "";
  effectiveOutDate: any = "";
  carFamilyFlag: any = "";

  spinnerMessage = "Please Wait...";
  recordcount: any = 0;
  //list
  familyLst: string[] = [];
  carFamilyFlagLst: string[] = [];

  //ngx-datatable
  rows = [];
  private _SelectionType = SelectionType;
  public get SelectionType() {
    return this._SelectionType;
  }
  public set SelectionType(value) {
    this._SelectionType = value;
  }
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showdata: boolean = true;
  columns = [
    { name: 'family', prop: 'fam_cd' }, { name: 'series', prop: 'series_nm' }, { name: 'generation', prop: 'gnrt_nm ' }, { name: 'familyDescription', prop: 'fam_desc' },
    { name: 'carFamilyFlag', prop: 'car_fam_flag' }, { name: 'effectiveInDate', prop: ' eff_dt' }, { name: 'effectiveOutDate', prop: 'expr_dt ' }
  ];

  ColumnMode = ColumnMode;
  sameLengthRows: boolean = false
  constructor(private readonly SpinnerService: NgxSpinnerService, private familyServiceService: FamilyServiceService, public dialog: MatDialog, private exportService: ExportToExcelService,
    public globalrole: GlobalService, 
    private dialogData: DialogData) { }


  async ngOnInit() {
    this.SpinnerService.show();
    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();
  }

  onloadfn() {
    // to get familyF
    this.familyServiceService.getgFamilyF().subscribe(data => {
      console.log(data);
      this.familyLst = data.payload;
    });

    // to get carFamilyFlag
    this.familyServiceService.getCarFamilyFlag().subscribe(data => {
      console.log(data);
      this.carFamilyFlagLst = data.payload;
    });
  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length == selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length == 0) {
      this.sameLengthRows = false
    }
    console.log('Select Event', selected, this.selected);

  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  openEditDialog(): void {

    console.log(this.selected, this.selected.length)
    this.successMessage = '';
    this.errorMessage = '';
    let count = this.selected.length
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(FamilyEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          if (result == 'success') {
            this.getGridData("add");
            this.successMessage = "Record Updated Successfully"
            // this.getGridData();

            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!';
            this.globalrole.goToTop();
          }
          else {
            // th
          }

        });
      }
      else if (this.selected.length > 1) {
        const dialogRef = this.dialog.open(FamilyMultieditDialogComponent, {
          disableClose: true,
          data: this.selected
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');

          if (result == 'success') {
            this.getGridData("add");
            this.successMessage = +count + " Record (s) edited successfully"
            // this.getGridData();

            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!';
            this.globalrole.goToTop();
          }
          else {
            // th
          }

        });
      }

      else {
        this.errorMessage = "Please select a row to edit";
        this.globalrole.goToTop();
      }
    }
    else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }
  openViewDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(FamilyViewDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');

        });
      }
      else {
        if (this.sameLengthRows == true) {
          this.errorMessage = "Please select only one row to view"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows == false) {
          this.errorMessage = "Please select a row to view"
          this.globalrole.goToTop();
        }
      }
    }
    else {
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  exportFile(): void {
    this.successMessage = '';
    this.errorMessage = '';

    this.rows.forEach(row => {
      let obj = {
        "Family": row.fam_cd,
        "Series": row.series_nm,
        "Generation": row.gnrt_nm,
        "Family Description": row.fam_desc,
        "Car Family Flag": row.car_fam_flag,
        "Effective In Date": row.eff_dt,
        "Effective Out Date": row.expr_dt,
      }
      this.exportData.push(obj);
    });
    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }
  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }
  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.family = row.fam_cd
      this.dialogData.series = row.series_nm;
      this.dialogData.generation = row.gnrt_nm;
      this.dialogData.familyDescription = row.fam_desc;
      this.dialogData.carFamilyFlag = row.car_fam_flag;
      this.dialogData.effectiveInDate = this.pipe.transform(row.eff_dt, 'yyyy-MM-dd');
      this.dialogData.effectiveOutDate = this.pipe.transform(row.expr_dt, 'yyyy-MM-dd');
    });
    console.log(this.dialogData.monthlyRep)
  }
  resetAll(data): void {
    this.selected = [];
    this.family = '';
    this.effectiveInDate = '';
    this.effectiveOutDate = '';
    this.carFamilyFlag = '';
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }


  search() {

    if (this.effectiveInDate && this.effectiveOutDate && this.pipe.transform(this.effectiveInDate, 'yyyy-MM-dd') > this.pipe.transform(this.effectiveOutDate, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
      this.globalrole.goToTop();
    }
    else {
      this.successMessage = "";
      this.errorMessage = '';
      this.getGridData("initial");
    }

  }

  getGridData(data) {
    this.selected = [];
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.family == undefined) {
        this.family = ""
      }
      if (this.carFamilyFlag == undefined) {
        this.carFamilyFlag = ""
      }

      let indateFormat = ''; let outdateFormat = '';
      if (this.effectiveInDate != "") {
        let d1 = moment(this.effectiveInDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.effectiveInDate = date
      }
      if (this.effectiveOutDate != "") {
        let d1 = moment(this.effectiveOutDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.effectiveOutDate = date
      }
      let gridData
      if (data == 'initial') {
        gridData =
        {
          family: this.family,
          series: this.series,
          generation: this.generation,
          familyDescription: this.familyDescription,
          carFamilyFlag: this.carFamilyFlag,
          effectiveInDate: indateFormat,
          effectiveOutDate: outdateFormat,
          option: data
        }
      } else {
        gridData =
        {
          family: "",
          series: "",
          generation: "",
          familyDescription: "",
          carFamilyFlag: "",
          effectiveInDate: "",
          effectiveOutDate: "",
          option: data
        }
      }
      this.SpinnerService.show();
      this.familyServiceService.getFamilyGrid(gridData).subscribe(data => {
        console.log(data);
        this.showdata = true;
        console.log(this.showdata);
        this.SpinnerService.hide();
        this.recordcount = data.pagination.totalRecords;
        let datarow = data.payload;
        this.rows = datarow.map(row => ({
          series_nm: row['series_nm'],
          fam_cd: row['fam_cd'],
          gnrt_nm: row['gnrt_nm'],
          fam_desc: row['fam_desc'],
          car_fam_flag: row['car_fam_flag'],
          eff_dt: (row['eff_dt'] == null) ? '' : row['eff_dt'].split('T')[0],
          expr_dt: (row['expr_dt'] == null) ? '' : row['expr_dt'].split('T')[0],
        })
        )
      }, err => {
        console.log(err);
        this.showdata = false;
        this.successMessage = "";
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "Family": row.family,
        "Series": row.series,
        "Generation": row.generation,
        "Family Description": row.familyDescription,
        "Car Family Flag": row.carFamilyFlag,
        "Effective In Date": row.effectiveInDate,
        "Effective Out Date": row.effectiveOutDate,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.log(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }


  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'effectiveInDate') {
      this.effectiveInDate = "";
    } else if (name == 'effectiveOutDate') {
      this.effectiveOutDate = "";
    }
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_9995') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true)
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true)
          }
         else {
          console.log(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        };
      }
      console.log(this.roleAccess);
    })
  }
}
