<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title cdkDragHandle>Edit <mat-icon class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  </h2>

  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="container">
      <span class="fst-italic"> <span class="text-danger"># - </span>Please fill in at least one of the fields </span>
      <!-- row1 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Prod Time In<span class="text-danger">#</span>:</label></div>
            <div class=col-2>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="inProdPicker" [(ngModel)]="fProdDateIn">
                <mat-datepicker-toggle matSuffix [for]="inProdPicker"></mat-datepicker-toggle>
                <mat-datepicker #inProdPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'fProdDateIn')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Prod Time Out<span class="text-danger">#</span>:</label></div>
            <div class=col-2>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="outProdPicker" [(ngModel)]="fProdDateOut">
                <mat-datepicker-toggle matSuffix [for]="outProdPicker"></mat-datepicker-toggle>
                <mat-datepicker #outProdPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'fProdDateOut')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- row2 -->
      <div class="row rowClass">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-4"> <label>Extraction Time In<span class="text-danger">#</span>:</label></div>
            <div class=col-2>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="inPicker" [(ngModel)]="fEffDateIn">
                <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
                <mat-datepicker #inPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'fEffDateIn')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4"> <label>Extraction Time Out<span class="text-danger">#</span>:</label></div>
            <div class=col-2>
              <mat-form-field class="picker" appearance="outline">
                <input matInput [matDatepicker]="outPicker" [(ngModel)]="fEffDateOut">
                <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
                <mat-datepicker #outPicker></mat-datepicker>
                <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'fEffDateOut')">clear
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- row3 -->
      <div class="row rowClass">
        <div class="col-2"> <label>Comments:</label></div>
        <div class=col-10><textarea class="w-100" name="w3review" [(ngModel)]="fComments" rows="4" cols="50">
            </textarea>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button cdkFocusInitial (click)="updateFamProNamc()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</ng-container>


<ngx-spinner bdColor="transparent" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>