<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-danger alert-dismissible " *ngIf="cpyErrMsg != ''" role="alert">
    {{cpyErrMsg}}
    <u><a data-toggle="modal" data-target="#failedPopup" style="cursor: pointer;">Click here to see the failed
        IDs</a></u>
    <mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-danger alert-dismissible " *ngIf="addtoversionErrMsg != ''" role="alert">
    {{addtoversionErrMsg}}
    <u><a data-toggle="modal" data-target="#failedPopup2" style="cursor: pointer;">Click here to see the failed
        IDs</a></u>
    <mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-danger alert-dismissible " *ngIf="errorExportData.length!=0" role="alert">
    0 Products added successfully to Version and {{errorExportData.length}} Product(s) failed
    <u><a (click)="downloadExcel()" style="cursor: pointer;">Click here to see the failed IDs</a></u>
    <mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <div class="alert alert-success alert-dismissible " *ngIf="successMessage!=''" role="alert">
    {{successMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <mat-card>
    <mat-form-field>
      <mat-label>Online View of Products</mat-label>
      <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="products" name="products">
        <mat-option value="All">All</mat-option>
        <mat-option value="onHold">On Hold</mat-option>
      </mat-select>
    </mat-form-field>

  <mat-form-field class="verNamePad">
    <mat-label>Version Name</mat-label>
    <mat-select [typeaheadDebounceInterval]= "500" #matRef [(ngModel)]="versionNm" name="versionNm" [multiple]="true"
      (selectionChange)="checkedState($event, checkBox)">
      <span *ngFor="let versionNm of versionNmLst">
        <mat-option *ngIf="versionNm.version_name != ''" [value]="versionNm.rep_bom_ver_id" #matOption
          [disabled]="matOption.selected != true && limitexceed" >
          {{versionNm.version_name}}
        </mat-option>
      </span>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button class="mobileaspect" [disabled]="versionNm==''" (click)="generateRepVerSummary()">Generate
    Rep Version
    Summary</button>
  <button mat-raised-button class="mobileaspect" (click)="generateActProdStatus()">Generate Actual Prod
    Status</button>
</mat-card>

<mat-accordion class="example-headers-align">
  <mat-expansion-panel class="search" [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Search</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Model Number</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="modelNumber" name="modelNumber">
        <mat-option [value]=""></mat-option>
        <span *ngFor="let mdlNo of modelNumberLst">
          <span *ngIf="mdlNo.mdl_no != null">
            <mat-option *ngIf="mdlNo.mdl_no != ''" [value]="mdlNo.mdl_no">{{mdlNo.mdl_no}}</mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Option</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="option" name="option">
        <mat-option [value]=""></mat-option>
        <span *ngFor="let optn of optionLst">
          <span *ngIf="optn.optn_cd != null">
            <mat-option *ngIf="optn.optn_cd != ''" [value]="optn.optn_cd">{{optn.optn_cd}}</mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>

      <mat-form-field>
        <mat-label>SAP Material ID</mat-label>
        <input matInput placeholder="" [(ngModel)]="sapMatId" oninput="this.value = this.value.toUpperCase()"
          maxlength="20">
      </mat-form-field>

    <mat-form-field>
      <mat-label>Processed in SAP Status</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="sapStatus" name="sapStatus">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let sapStatus of sapStatusLst" [value]="sapStatus.sap_proc_stat_typ_id">
          {{sapStatus.sap_proc_stat_typ_desc}}</mat-option>
      </mat-select>
    </mat-form-field>

      <mat-form-field>
        <mat-label>Version Type</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="versionType" name="versionType"
          (selectionChange)="getVersionQual()">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let verType of versionTypeLst" [value]="verType.bom_ver_typ_id">{{verType.bom_ver_typ_cd}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Version Qualifier</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="versionQual" name="versionQual"
          (selectionChange)="getVersionRev();getDestProdWthnVersion()">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let verQual of versionQualLst"
            [value]="verQual.bom_ver_qual_id">{{verQual.bom_ver_qual_cd}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Version Revision</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="versionRev" name="versionRev">
          <mat-option [value]="" (click)="approvalstsfn('')"></mat-option>
          <mat-option *ngFor="let versionRev of versionRevLst,let i= index" [value]="versionRev.bom_ver_rev_nbr"
            (click)="approvalstsfn(versionRev.rep_bom_ver_id)">
            {{versionRev.version_revision}}</mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Approval Status</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="approvalStatus" name="approvalStatus">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let approvalStatus of approvalStatusLst" [value]="approvalStatus.bom_ver_apprvl_stat_typ">
          {{approvalStatus.bom_ver_apprvl_stat_typ}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>NAMC</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="namc" name="namc" (selectionChange)="getFamilyFpn()">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let namc of namcLst" [value]="namc.namc_lgl_entity_id">{{namc.namc_cd}}</mat-option>
      </mat-select>
    </mat-form-field>

      <mat-form-field>
        <mat-label>Family</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="family" name="family"
          (selectionChange)='getKatashikiByFamCd()'>
          <mat-option [value]=""></mat-option>
          <span *ngFor="let family of famcdLst">
            <span *ngIf="family.fam_cd != null">
              <mat-option *ngIf="family.fam_cd != ''" [value]="family.fam_cd">{{family.fam_cd}}</mat-option>
            </span>
          </span>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Katashiki</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="katashiki" name="katashiki">
          <mat-option [value]=""></mat-option>
          <span *ngFor="let katashiki of katashikiLst">
            <span *ngIf="katashiki.katashiki_cd != null">
              <mat-option *ngIf="katashiki.katashiki_cd != ''"
                [value]="katashiki.katashiki_cd">{{katashiki.katashiki_cd}}
              </mat-option>
            </span>
          </span>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="status" name="status">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let status of statusLst" [value]="status.prod_stat_typ_cd">{{status.prod_stat_typ_cd}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Part Number (Product)</mat-label>
      <input matInput placeholder="" [(ngModel)]="partNo">
    </mat-form-field>

      <mat-form-field>
        <mat-label>Customer</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="customer" name="customer">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let cust of customerLst"
            [value]="cust.buyoff_cust_id">{{cust.buyoff_cust_nm}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Dest</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="dest" name="dest"
          (selectionChange)='getHikiateByDest()'>
          <mat-option [value]=""></mat-option>
          <mat-option value="ALL">ALL</mat-option>
          <mat-option *ngFor="let dest of destLst" [value]="dest.dest_ctry_id">{{dest.dest_ctry_id}}</mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Hikiate Status</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="hikiate" name="hikiate">
        <mat-option></mat-option>
        <span *ngFor="let hiki of hikiateLst">
          <span *ngIf="hiki.hikiate_stat_comb_cd != null">
            <mat-option *ngIf="hiki.hikiate_stat_comb_cd != ''" [value]="hiki.hikiate_stat_comb_cd">
              {{hiki.hikiate_stat_comb_cd}}
            </mat-option>
          </span>
        </span>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>S/P</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="sp" name="sp">
        <mat-option></mat-option>
        <mat-option value="P">P</mat-option>
        <mat-option value="S">S</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Net Spec</mat-label>
      <input matInput placeholder="" [(ngModel)]="netSpec">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Net Structure</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="netStructure" name="netStructure">
        <mat-option [value]=""></mat-option>
        <mat-option value="Y">Y</mat-option>
        <mat-option value="N">N</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>On-Hold Status</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="onHoldStatus" name="onHoldStatus">
        <mat-option></mat-option>
        <mat-option value="Y">Y</mat-option>
        <mat-option value="N">N</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Copy Struc</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="copyStruc" name="copyStruc">
        <mat-option></mat-option>
        <mat-option value="Y">Y</mat-option>
        <mat-option value="N">N</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <button mat-button style="float: right;" (click)="resetAll('')">Reset</button>
    <button mat-button style="float: right;" (click)="search()">Search</button>

  </mat-expansion-panel>
</mat-accordion>

<div class="d-flex" *ngIf="showButtons">
  <button mat-raised-button (click)="openAddDialog()"> Add to version </button>
  <button mat-raised-button (click)="openverRemoveDialog()">Remove from version </button>
  <button mat-raised-button (click)="openVerifyDialog()">Verify product within version </button>
  <button mat-raised-button (click)="openApproveDialog()">Approve product within version </button>
  <button mat-raised-button (click)="openEditDialog()">Edit </button>
  <button mat-raised-button (click)="openViewDialog()">View </button>
  <button mat-raised-button (click)="generateVerValRpt()">Generate Version Validation Report </button>
</div>
<div class="d-flex" *ngIf="showButtons">
  <button mat-raised-button (click)="IdenDepProd()" class="button">Identify Dependent Products </button>
  <button mat-raised-button (click)="clickCpyStruc()">Copy Structure </button>
  <button mat-raised-button (click)="resetReadyforReview()">Reset to Ready for Review </button>
  <button mat-raised-button (click)="sendtoSap()">Send To SAP </button>
  <span class="ms-auto">
    <button mat-raised-button (click)="uploadExcel()">Upload From Excel </button>
    <button mat-raised-button (click)="exportFile()">Export to Excel </button>
  </span>
</div>

<!-- <mat-card> -->
<ngx-datatable #myTable *ngIf="showGrid" class="material striped" [rows]="rows" [columnMode]="ColumnMode.force"
  [headerHeight]="45" [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
  [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
  (activate)="onActivate($event)" (select)="onSelect($event)" [scrollbarH]="true"
  [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
      <div class="page-count">
        {{selectedCount}} selected / {{rowCount}} total
      </div>
      <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
        (change)="table.onFooterPage($event)">
      </datatable-pager>
      <div class="test">
        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
    </ngx-datatable-column>
    <ngx-datatable-column name="NAMC" prop="namc_cd"></ngx-datatable-column>
    <ngx-datatable-column class="w-50" name="Model Number" prop="mdl_no"></ngx-datatable-column>
    <ngx-datatable-column name="Option" prop="optn_cd"></ngx-datatable-column>
    <ngx-datatable-column name="SAP Material ID" prop="sap_mtrl_id" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Family" prop="fam_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Katashiki" prop="katashiki_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Dest" prop="dest_ctry_id"></ngx-datatable-column>
    <ngx-datatable-column name="Cust" prop="buyoff_cust_nm"></ngx-datatable-column>
    <ngx-datatable-column name="S/P" prop="base_optn_typ_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Net Structure" prop="net_struc_flg"></ngx-datatable-column>
    <ngx-datatable-column name="Net Spec" prop="net_spec_cd_comb_txt"></ngx-datatable-column>
    <ngx-datatable-column name="PC Spec" prop="pc_spec_cd_comb_txt"></ngx-datatable-column>
    <ngx-datatable-column name="Comp PC Spec" prop="compar_pc_spec_cd_comb_txt"></ngx-datatable-column>
    <ngx-datatable-column name="Part No.(Product)" prop="prod_part_no"></ngx-datatable-column>
    <ngx-datatable-column name="Copy Structure">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="reuse_struc_flg">
        <input type="checkbox" [checked]="row.reuse_struc_flg=='Y'" disabled />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Status" prop="prod_stat_typ_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Version Type" prop="bom_ver_typ_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Version Qualifier" prop="bom_ver_qual_cd"></ngx-datatable-column>
    <ngx-datatable-column name="Version Revision" prop="bom_ver_rev_nbr"></ngx-datatable-column>
    <ngx-datatable-column name="Rep Prod Def Id" prop="rep_prod_def_id"></ngx-datatable-column>
    <ngx-datatable-column name="Rep Prod Id" prop="rep_prod_id"></ngx-datatable-column>
    <ngx-datatable-column name="Hikiate Status" prop="hikiate_stat_comb_cd"></ngx-datatable-column>
    <ngx-datatable-column name="100% Spec">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="hundred_pct_optn_flg">
        <input type="checkbox" [checked]="row.hundred_pct_optn_flg=='Y'" disabled />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Comp 100% Spec">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="compar_100_pct_optn_flg">
        <input type="checkbox" [checked]="row.compar_100_pct_optn_flg=='Y'" disabled />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Comments" prop="rmrk_txt"></ngx-datatable-column>
    <ngx-datatable-column name="BoM Effectivity Date" prop="bom_eff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="Cost Effectivity Date" prop="cost_eff_dt"></ngx-datatable-column>
    <ngx-datatable-column name="On-Hold Status">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" prop="on_hold_flg">
        <input type="checkbox" [checked]="row.on_hold_flg=='Y'" disabled />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Processed to SAP" prop="sent_to_sap_ts"></ngx-datatable-column>
    <ngx-datatable-column name="SAP Processing Status" prop="sap_proc_stat_typ_nm"></ngx-datatable-column>
  </ngx-datatable>


<!-- //////////////////////////modal popup/////////////////// -->
<!-- openFailedRecords -->
<div class="modal" id="failedPopup">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Failed IDs</h4>
        <button type="button" class="close" data-dismiss="modal"><span>&times;</span></button>
      </div>
      <div class='modal-body'>
        <button type="button" class="exportbtn" (click)="exportFailedId('copystruc')">Export to Excel</button>
        <div class="col-md-10" style="margin: auto;">
          <table aria-describedby="table"class="errpop">
            <tr>
              <th scope="col">Reason</th>
              <th scope="col">Failed IDs</th>
            </tr>
            <tr *ngFor="let d of cpystruFailedRows">
              <td *ngIf="d.err_msg == 'Revision_Zero'">Please Select only records with Revision '01' or Higher</td>
              <td *ngIf="d.err_msg != 'Revision_Zero'">{{d.err_msg}}</td>
              <td>{{d.rep_prod_id}}</td>
            </tr>
          </table>
        </div>

      </div>
      <div class="modal-footer " style="margin: auto;">
        <div class='row w-100'>
          <div class='col-md-3'>
            <button type="button" class="btn btn-secondary btn-lg" data-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- addversion err msg-->
<div class="modal" id="failedPopup2">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Failed IDs</h4>
        <button type="button" class="close" data-dismiss="modal"><span>&times;</span></button>
      </div>
      <div class='modal-body'>
        <button type="button" class="exportbtn" (click)="exportFailedId('addtoversion')">Export to Excel</button>
        <div class="col-md-10" style="margin: auto;">
          <table aria-describedby="table"class="errpop">
            <tr>
              <th scope="col">Reason</th>
              <th scope="col">Failed IDs</th>
            </tr>
            <tr *ngFor="let d of addtoversionErrlist">
              <td>{{d.err_msg}}</td>
              <td>{{d.rep_prod_id}}</td>
            </tr>
          </table>
        </div>

        </div>
        <div class="modal-footer " style="margin: auto;">
          <div class='row w-100'>
            <div class='col-md-3'>
              <button type="button" class="btn btn-secondary btn-lg" data-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</span>
<div *ngIf="!accessEnable">
  <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">{{spinnerMessage}} </p>
</ngx-spinner>