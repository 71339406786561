import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { DialogData } from '../dialog-data/dialog-data';
import { IsSystemParameterEditDialogComponent } from '../is-system-parameter-edit-dialog/is-system-parameter-edit-dialog.component';
import { IsSystemParameterViewDialogComponent } from '../is-system-parameter-view-dialog/is-system-parameter-view-dialog.component';
import { IsSystemParameterServiceService } from './is-system-parameter-service.service';
import { NgxSpinnerService } from "ngx-spinner";

import { GlobalService } from '../global.service';

@Component({
  selector: 'app-is-system-parameter',
  templateUrl: './is-system-parameter.component.html',
  styleUrls: ['./is-system-parameter.component.css']
})
export class IsSystemParameterComponent implements OnInit {
  [x: string]: any;
  recordcount: any = 0;
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';

  //ngModel values for search
  step = 0;
  parameterName: any = "";
  parameterValue: any = "";
  sameLengthRows: boolean = false;
  spinnerMessage = "Please Wait...";

  //list
  parameterNamerLst: string[] = ['Actual To Sap', 'Actual To Prod', 'Add Prod'];
  parameterValueLst: string[] = ['N', '5', 'N', 'N', 'N'];

  //ngx-datatable
  rows = [];
  private _SelectionType = SelectionType;
  public get SelectionType() {
    return this._SelectionType;
  }
  public set SelectionType(value) {
    this._SelectionType = value;
  }
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  roleAccess: any = []
   accessEnable: boolean;
  Roledata: any = [];

  showdata: boolean = true;
  columns = [
    { name: 'job', prop: 'parm_nm' }, { name: 'processingState', prop: 'parm_val_txt' }, { name: 'description', prop: 'parm_desc' },
    { name: 'updateById', prop: 'update_by_id' }, { name: 'updateTs', prop: 'update_ts ' }
  ];
  ColumnMode = ColumnMode;


  constructor(private readonly SpinnerService: NgxSpinnerService, private isSystemParameterServiceService: IsSystemParameterServiceService, public dialog: MatDialog,
    private exportService: ExportToExcelService, private dialogData: DialogData, public globalrole: GlobalService,
    ) {

  }
  async ngOnInit() {

    this.SpinnerService.show();

    let a = await this.userroleAccessfn();
    if (a) {
      console.log('a', a)
      //grid
      this.getGridData("initial");
    }

    this.onloadfn();
  }

  onloadfn(){
    // to get systemParamName
    this.isSystemParameterServiceService.getSystemParamName().subscribe(data => {
      console.log(data);
      this.parameterNamerLst = data.payload;
    });


    // to get systemParamValue
    this.isSystemParameterServiceService.getSystemParamValue().subscribe(data => {
      console.log(data);
      this.parameterValueLst = data.payload;
    });

  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length == selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length == 0) {
      this.sameLengthRows = false
    }
    console.log('Select Event', selected, this.selected.length);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.log('Activate Event', event);
  }
  displayCheck(row) {
    return row;
  }

  singleSelectCheck(row: any) {
    return this.selected.indexOf(row) === -1;
  }
  openEditDialog(): void {
    console.log(this.selected, this.selected.length)
    this.errorMessage = "";
    this.successMessage = "";
    console.log(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length == 1) {
        this.errorMessage = '';
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(IsSystemParameterEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          if (result == 'success') {
            this.getGridData("add");
            this.successMessage = "Records Updated Successfully"
            // this.getGridData();
            this.selected = []
          }
          else if (result == 'AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!';
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }

      else {
        if (this.sameLengthRows == true) {
          this.errorMessage = "please select only one row to edit"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows == false) {
          this.errorMessage = "please select a row to edit"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }

  openViewDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.log(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length == 1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(IsSystemParameterViewDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');

        });
      }
      else {
        if (this.sameLengthRows == true) {
          this.errorMessage = "please select only one row to view"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows == false) {
          this.errorMessage = "please select a row to view"
          this.globalrole.goToTop();
        }
      }
    }
    else {
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }
  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.job = row.parm_nm;
      this.dialogData.processingState = row.parm_val_txt;
      this.dialogData.description = row.parm_desc;
      this.dialogData.updateById = row.update_by_id;
      this.dialogData.updateTs = row.update_ts;
      this.dialogData.createById = row.create_by_id;
      this.dialogData.createTs = row.create_ts;
    });
    console.log(this.dialogData.monthlyRep)
  }

  resetAll(data): void {
    this.selected=[]
    this.parameterName = '';
    this.parameterValue = '';
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }

  search() {
    this.errorMessage = '';
    this.successMessage = '';
    this.getGridData("initial");
  }

  getGridData(data) {
    this.selected=[]
    console.log(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.parameterName == undefined) {
        this.parameterName = ""
      }
      if (this.parameterValue == undefined) {
        this.parameterValue = ""
      }
      let gridData
      if (data == "initial") {
        gridData =
        {
          parameterName: this.parameterName,
          parameterValue: this.parameterValue,
          job: this.job,
          processingState: this.processingState,
          description: this.description,
          updateById: this.updateById,
          updateTs: this.updateTs,
          option: data
        }
      } else {
        gridData =
        {
          parameterName: "",
          parameterValue: "",
          job: "",
          processingState: "",
          description: "",
          updateById: "",
          updateTs: "",
          option: data
        }
      }
      this.SpinnerService.show();
      this.isSystemParameterServiceService.getIsSysParmGrid(gridData).subscribe(data => {
        console.log(data)
        this.showdata = true;
        console.log(this.showdata);
        this.SpinnerService.hide();
        let datarow = data.payload;
        this.rows = datarow.map(row => ({
          parm_nm: row['parm_nm'],
          parm_val_txt: row['parm_val_txt'],
          parm_desc: row['parm_desc'],
          update_by_id: row['update_by_id'],
          update_ts: (row['update_ts'] == null) ? '' : row['update_ts'].replace('Z', '').replace('T', ' '),
          create_ts: (row['create_ts'] == null) ? '' : row['create_ts'].replace('Z', '').replace('T', ' '),
          create_by_id: row['create_by_id'],
        }))
        // this.recordcount = data.pagination.totalRecords;
        // this.rows = data.payload;
      }, err => {
        console.log(err);
        this.showdata = false;
        this.successMessage = "";
        console.log(this.showdata);
        this.rows = [];
        if (err == 0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.log(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "Job": row.job,
        "Processing State": row.processingDate,
        "Description": row.description,
        "Update By Id": row.updateById,
        "Update Ts": row.updateTs,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.log(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.log(res)
        this.Roledata = res;
      })
      console.log(this.Roledata);
      if (this.Roledata.length != 0) {
        this.Roledata.filter((res) => {
          if (res.function_nm == 'NBPC_9994') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true)
      } else if (this.Roledata.length == 0) {
        let arrayvalue: any; let roleList: any;
        let data = localStorage.getItem('role');
        arrayvalue = data.split(',')
        console.log(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str == '') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        let body = {
          security_grp_nm: roleList
        }
        let userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true)
          }
        else {
          console.log(this.roleAccess)
          this.roleAccess = [];
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.log(this.roleAccess);
    })
  }

}



