import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { HunderedSpecificationsAddDialogService } from './hundered-specifications-add-dialog.service';

@Component({
  selector: 'app-hundered-specifications-add-dialog',
  templateUrl: './hundered-specifications-add-dialog.component.html',
  styleUrls: ['./hundered-specifications-add-dialog.component.css']
})
export class HunderedSpecificationsAddDialogComponent implements OnInit {

  spinnerMessage = "Please Wait...";
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');
  //ngmodel
  namc: any = "";
  family: any = "";
  katashiki: any = "";
  dest: any = "";
  inDate: any = "";
  outDate: any = "";
  hikiate: any = "";
  effIn: any = null;
  effOut: any = null;
  comments: any = "";
  hikiatevalue: any;
  PcSpecvalue: any;
  [x: string]: any;

  //List
  namcLst: string[];
  famcdLst: string[];
  katashikiLst: string[];
  destLst: string[] = [];
  hikiateLst: string[]
  pcSpecLst: string[]
  //to main page
  message = "Records Updated Successfully";
  index: any;
  errorMessage = "";
  //hikiate
  hikiateLstFromDB: any = [];
  selectedHikiateLst: any = [];
  dummyHikiateLst: any;
  dummyHikiateLstToDB: any = [];
  //pc spec
  pcSpecLstFromDB: any = [];
  selectedPcSpecLst: any = [];
  dummyPcSpecLst: any;
  dummyPcSpecLstToDB: any = [];
  //response after insert in a record
  response: any;
  constructor(private addHunPerService: HunderedSpecificationsAddDialogService, private readonly SpinnerService: NgxSpinnerService, public dialogRef: MatDialogRef<HunderedSpecificationsAddDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }


  ngOnInit(): void {
    //to get namc
    this.SpinnerService.show();
    this.addHunPerService.getNamcHps().subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.namcLst = data.payload;
    });

    //to get family
    // this.getFamily();

    //to get hikiate
   

    //to get destination
    this.getDestination();

  }


  moveHikiForward(): void {
    let hikiatelst = this.hikiateLstFromDB
    console.log(this.dummyHikiateLst);
    this.dummyHikiateLst.forEach(hiki => {
      this.selectedHikiateLst.push(hiki);
      for (let i = 0; i < hikiatelst.length; i++) {
        console.log(i);
        console.log(hiki, hikiatelst[i].hikiate_stat_cd);
        console.log(hiki === hikiatelst[i].hikiate_stat_cd);
        console.log(i);
        if (hiki == hikiatelst[i].hikiate_stat_cd) {
          hikiatelst.splice(i, 1);
        }
      }
    });
    this.hikiateLstFromDB = hikiatelst;
    console.log(this.hikiateLstFromDB);
    console.log(this.selectedHikiateLst);
    this.dummyHikiateLst = ""
    this.dummyHikiateLstToDB = this.selectedHikiateLst;

  }
  moveHikiBackward(): void {
    this.dummyHikiateLstToDB.forEach(hiki => {
      let body = {
        "hikiate_stat_cd": hiki
      }
      this.hikiateLstFromDB.push(body);
      this.index = this.selectedHikiateLst.indexOf(hiki);
      this.selectedHikiateLst.splice(this.index, 1);
    });
    console.log(this.hikiateLstFromDB)
    console.log(this.selectedHikiateLst);
    if (this.dummyHikiateLstToDB.length >= 1) {
      this.hikiateLstFromDB = this.hikiateLstFromDB.sort((a, b) => a.hikiate_stat_cd > b.hikiate_stat_cd ? 1 : -1);
      this.dummyHikiateLstToDB = [];
    }
  }




  //pcSpec
  movePcSPecForward(): void {
    let pcSpecLst = this.pcSpecLstFromDB
    console.log(this.dummyPcSpecLst);
    this.dummyPcSpecLst.forEach(pcSPec => {
      this.selectedPcSpecLst.push(pcSPec);
      for (let i = 0; i < pcSpecLst.length; i++) {
        console.log(i);
        console.log(pcSPec, pcSpecLst[i].pc_spec_cd);
        console.log(pcSPec === pcSpecLst[i].pc_spec_cd);
        console.log(i);
        if (pcSPec == pcSpecLst[i].pc_spec_cd) {
          pcSpecLst.splice(i, 1);
        }
      }
    });
    this.pcSpecLstFromDB = pcSpecLst;
    console.log(this.pcSpecLstFromDB);
    console.log(this.selectedPcSpecLst);
    this.dummyPcSpecLstToDB = this.selectedPcSpecLst;
    this.dummyPcSpecLst = ""
  }
  movePcSpecBackward(): void {

    this.dummyPcSpecLstToDB.forEach(pcSPec => {
      let body = {
        "pc_spec_cd": pcSPec
      }
      this.pcSpecLstFromDB.push(body);
      this.index = this.selectedPcSpecLst.indexOf(pcSPec);
      this.selectedPcSpecLst.splice(this.index, 1);
    });
    console.log(this.pcSpecLstFromDB)
    console.log(this.selectedPcSpecLst);
    if (this.dummyPcSpecLstToDB.length >= 1) {
      this.pcSpecLstFromDB = this.pcSpecLstFromDB.sort((a, b) => a.pc_spec_cd > b.pc_spec_cd ? 1 : -1);
      this.dummyPcSpecLstToDB = [];
    }
  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }



  updateHundredSpec() {
    let fileName= localStorage.getItem('Filename')
    console.log(this.namc, this.family, this.katashiki, this.dest, this.dummyPcSpecLstToDB, this.inDate);

    if (!this.namc || !this.family || !this.katashiki || !this.dest || !this.dummyPcSpecLstToDB || !this.inDate) {

      this.errorMessage = "Please enter all mandatory field";

    }
    else if (this.inDate && this.outDate &&
      this.pipe.transform(this.inDate, 'yyyy-MM-dd') > this.pipe.transform(this.outDate, 'yyyy-MM-dd')) {

      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";

    }
    else if (this.selectedPcSpecLst.length > 1) {
      let word, digits;
      let word1, digits1;
      let spclarray = [];
      let str1; let str2;
      let datafinal
      for (let i = 0; i < this.selectedPcSpecLst.length; i++) {
        for (let j = i + 1; j < this.selectedPcSpecLst.length; j++) {
          str1 = this.selectedPcSpecLst[i];
          [word, digits] = str1.match(/\D+|\d+/g);
          console.log(word, digits)
          str2 = this.selectedPcSpecLst[j];
          [word1, digits1] = str2.match(/\D+|\d+/g);
          console.log(word, digits)
          if (word == word1) {
            spclarray.push(str1, str2)
            datafinal = [...new Set(spclarray)]
          }

        }
      }
      console.log("digits", word);
      console.log("spclarray", spclarray);
      console.log("spclarray", datafinal);
      if (spclarray.length > 0) {
        this.errorMessage = "PcSpecs " + datafinal + " are Incompatible";
      }
      else {

        let indateFormat = ''; let outdateFormat = '';
        if (this.inDate != "") {
          let d1 = moment(this.inDate).format('YYYY-MM-DD')
          let b = d1.split('T')[0]
          let parts = []
          parts = b.split('-');
          let date = new Date(parts[0], parts[1] - 1, parts[2]);
          indateFormat = moment(date).format('YYYY-MM-DD')
          this.inDate = date
        }
        if (this.outDate != "") {
          let d1 = moment(this.outDate).format('YYYY-MM-DD')
          let b = d1.split('T')[0]
          let parts = []
          parts = b.split('-');
          let date = new Date(parts[0], parts[1] - 1, parts[2]);
          outdateFormat = moment(date).format('YYYY-MM-DD')
          this.outDate = date
          // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
        }
        let hikiatelstArray: any;
        hikiatelstArray = this.dummyHikiateLstToDB;
        console.log(hikiatelstArray.length, hikiatelstArray);
        let str = '';
        for (let i = 0; i < hikiatelstArray.length; i++) {
          if (str == '') {
            str = hikiatelstArray[i]
          } else {
            str += ',' + hikiatelstArray[i]
          }
          this.hikiatevalue = str;
        }
        let PcSpecLstArray: any;
        PcSpecLstArray = this.dummyPcSpecLstToDB;
        console.log(PcSpecLstArray.length, PcSpecLstArray);
        let str1 = '';
        for (let i = 0; i < PcSpecLstArray.length; i++) {
          if (str1 == '') {
            str1 = PcSpecLstArray[i]
          } else {
            str1 += ',' + PcSpecLstArray[i]
          }
          this.PcSpecvalue = str1;
        }
        if (this.outDate == "") {
          this.outDate = "9999-12-31"
        }
        console.log(this.hikiatevalue, this.PcSpecvalue)
        this.errorMessage = "";
        let body = {
          'namc': this.namc,
          'fam': this.family,
          'katashiki': this.katashiki,
          'hikistcombo': this.hikiatevalue,
          'timeIn': indateFormat,
          'timeOut': outdateFormat,
          'destCry': this.dest,
          'screenNo':'NBPC_9178',
          'fileName': fileName
        }

        this.SpinnerService.show()
        this.addHunPerService.existscheck100Spec(body).subscribe(data => {
          console.log(data);
          this.SpinnerService.hide();
          this.response = data.payload;
          if (this.response[0].result == 'success') {
            this.Add100Spec()
          } else if (this.response[0].result == 'Data Already Exists') {
            this.errorMessage = 'Record already Exists!';
          } else if (this.response[0].result == 'Invalid') {
            this.errorMessage = "Data Invalid ";
            this.SpinnerService.hide();
          }
        }, err => {
          this.SpinnerService.hide();
        });
      }
    }
    else {
      // if (this.outDate == "") {
      //   this.outDate = "9999-12-31"
      // }
      let indateFormat = ''; let outdateFormat = '';
      if (this.inDate != "") {
        let d1 = moment(this.inDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.inDate = date
      }
      if (this.outDate != "") {
        let d1 = moment(this.outDate).format('YYYY-MM-DD')
        let b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.outDate = date
        // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
      }
      let hikiatelstArray: any;
      hikiatelstArray = this.dummyHikiateLstToDB;
      console.log(hikiatelstArray.length, hikiatelstArray);
      let str = '';
      for (let i = 0; i < hikiatelstArray.length; i++) {
        if (str == '') {
          str = hikiatelstArray[i]
        } else {
          str += ',' + hikiatelstArray[i]
        }
        this.hikiatevalue = str;
      }
      let PcSpecLstArray: any;
      PcSpecLstArray = this.dummyPcSpecLstToDB;
      console.log(PcSpecLstArray.length, PcSpecLstArray);
      let str1 = '';
      for (let i = 0; i < PcSpecLstArray.length; i++) {
        if (str1 == '') {
          str1 = PcSpecLstArray[i]
        } else {
          str1 += ',' + PcSpecLstArray[i]
        }
        this.PcSpecvalue = str1;
      }
      if (this.outDate == "") {
        this.outDate = "9999-12-31"
      }
      console.log(this.hikiatevalue, this.PcSpecvalue)
      this.errorMessage = "";
      let body = {
        'namc': this.namc,
        'fam': this.family,
        'katashiki': this.katashiki,
        'hikistcombo': this.hikiatevalue,
        'timeIn': indateFormat,
        'timeOut': outdateFormat,
        'destCry': this.dest,
        'screenNo':'NBPC_9178',
        'fileName': fileName
      }

      this.SpinnerService.show()
      this.addHunPerService.existscheck100Spec(body).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result == 'success') {
          this.Add100Spec()
        } else if (this.response[0].result == 'Data Already Exists') {
          this.errorMessage = 'Record already Exists!';
        } else if (this.response[0].result == 'Invalid') {
          this.errorMessage = "Data Invalid";
          this.SpinnerService.hide();
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }

  Add100Spec() {
    let fileName= localStorage.getItem('Filename')
    let indateFormat = ''; let outdateFormat = ''; let effInformat = ''; let effOutOutFormat = ""
    let hikiatelstArray: any;
    hikiatelstArray = this.dummyHikiateLstToDB;
    console.log(hikiatelstArray.length, hikiatelstArray);
    let str = '';
    for (let i = 0; i < hikiatelstArray.length; i++) {
      if (str == '') {
        str = hikiatelstArray[i]
      } else {
        str += ',' + hikiatelstArray[i]
      }
      this.hikiatevalue = str;
    }
    let PcSpecLstArray: any;
    PcSpecLstArray = this.dummyPcSpecLstToDB;
    console.log(PcSpecLstArray.length, PcSpecLstArray);
    let str1 = '';
    for (let i = 0; i < PcSpecLstArray.length; i++) {
      if (str1 == '') {
        str1 = PcSpecLstArray[i]
      } else {
        str1 += ',' + PcSpecLstArray[i]
      }
      this.PcSpecvalue = str1;
    }
    if (this.inDate != "") {
      let d1 = moment(this.inDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.inDate = date
    }
    if (this.outDate != "") {
      let d1 = moment(this.outDate).format('YYYY-MM-DD')
      let b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      let date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.outDate = date
      // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
    }
   

    console.log(this.hikiatevalue, this.PcSpecvalue)
    let user = localStorage.getItem('workDayId');
    this.errorMessage = "";
    let cmts;
    if (this.comments!= undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
    let body = {
      'namc': this.namc,
      'fam': this.family,
      'katashiki': this.katashiki,
      'hikistcombo': this.hikiatevalue,
      'timeIn': indateFormat,
      'timeOut': outdateFormat,
      'vdweffDt': this.effIn,
      'vdwexprOut': this.effOut,
      'pcSpec': this.PcSpecvalue,
      'comment': cmts,
      'dest': this.dest,
      'screenNo':'NBPC_9178',
      'fileName': fileName
      
    }
    console.log(body);
    this.SpinnerService.show()
    this.addHunPerService.addhundPer(body).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.errorMessage = "Data not Added";
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  //onChange  
  getFamily(): void {
    if (this.namc != "") {

      this.SpinnerService.show();
      this.addHunPerService.getFamilyHps(this.namc).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.famcdLst = data.payload;
        this.hikiateLstFromDB = [];
        this.pcSpecLstFromDB = [];
        this.katashikiLst = [];
      });
    }
    else {
      this.famcdLst = [];
      this.hikiateLstFromDB = [];
      this.pcSpecLstFromDB = [];
      this.katashikiLst = [];
    }
  }
  getHikiate(): void {
    if (this.family == undefined) {
      this.family = ''
    }
    if (this.family != '') {
      this.SpinnerService.show();
      this.addHunPerService.getHikiateStatusHps(this.family).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.hikiateLstFromDB = data.payload;
        this.dummyHikiateLstToDB = ""
      });
    } else {
      this.dummyHikiateLstToDB = ""
      this.hikiateLstFromDB = []
    }
  }


  getDestination(): void {
    if (this.katashiki == undefined) {
      this.katashiki = ''
    }
    this.SpinnerService.show();
    this.addHunPerService.getDestHps(this.katashiki).subscribe(data => {
      console.log(data);
      this.SpinnerService.hide();
      this.destLst = data.payload;
      this.destination = ""
    });
  }

  getKatashikiHps(): void {
    if (this.namc == undefined) {
      this.namc = ''
    }
    if (this.family != "") {
      this.SpinnerService.show();
      this.addHunPerService.getKatashikiHps(this.namc, this.family).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.katashikiLst = data.payload;
        this.katashiki = "";
        // this.getHikiate();
      });
    }
    else {
      this.katashikiLst = [];
    }
  }
  getPcSpecHpsByFam(): void {
    if (this.family == undefined) {
      this.family = ''
    }
    if (this.family != '') {
      this.SpinnerService.show();
      this.addHunPerService.getPcSpecHps(this.family).subscribe(data => {
        console.log(data);
        this.SpinnerService.hide();
        this.pcSpecLstFromDB = data.payload;
        this.dummyPcSpecLstToDB = ""
      });
    } else {
      this.dummyPcSpecLstToDB = ""
      this.pcSpecLstFromDB = [];
    }
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name == 'inDate') {
      this.inDate = "";
    } else if (name == 'outDate') {
      this.outDate = "";
    }
  }

}
