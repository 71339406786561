import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";
import { MajorPartlstEditDialogService } from "./major-partlst-edit-dialog.service";

@Component({
  selector: 'app-major-partlst-edit-dialog',
  templateUrl: './major-partlst-edit-dialog.component.html',
  styleUrls: ['./major-partlst-edit-dialog.component.css']
})
export class MajorPartlstEditDialogComponent implements OnInit {
  [x: string]: any;
  //errormessage
  errorMessage: string = "";

  //ngModel values
  majorPartEditFamily: any;
  majorPartEditPartType: any;
  majorPartEditExpPartQty: any;
  majorPartEditProdType: any;
  majorPartEditPartCode: any;
  majorPartEditComments: any;
  majorPartEditActive: any;
  maj_part_lst_id: any;
  majorPartEditNAMC: any;
  majorPartEditKatashiki:any;
  //to main page
  message = "Record Updated Successfully";
  spinnerMessage = "Please Wait...";

  //response from backend after insert/update records
  response: any;

  constructor(public dialogRef: MatDialogRef<MajorPartlstEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private readonly SpinnerService: NgxSpinnerService,
    private majPartLstEditService: MajorPartlstEditDialogService) { }

  ngOnInit() {
    console.log("data", this.data);
    if (this.data.majorPartStatus == 'In Active') {
      this.majorPartEditActive = false
    }
    if (this.data.majorPartStatus == 'Active') {
      this.majorPartEditActive = true
    }

    this.majorPartEditFamily = this.data.majorPartFamily;
    this.majorPartEditPartType = this.data.majorPartType;
    this.majorPartEditExpPartQty = this.data.expectedMajorPartTypeQty;
    this.majorPartEditProdType = this.data.majorPartProdType;
    this.majorPartEditPartCode = this.data.majorPartPCode;
    this.majorPartEditComments = this.data.majorPartComments;
    this.majorPartEditNAMC = this.data.majorPartEditNAMC;
    // this.majorPartpartno = this.data.majorPartpartno;
    if (this.data.majorPartEditKatashiki != null) {
      this.majorPartEditKatashiki = this.data.majorPartEditKatashiki;
    }
    else if (this.data.majorPartpartno != null) {
      this.majorPartEditKatashiki = this.data.majorPartpartno;
    }
    console.log(this.majorPartEditKatashiki)
    this.maj_part_lst_id = this.data.maj_part_lst_id;
  }

  //save
  updateMajLst(): void {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let cmts
    if (this.majorPartEditComments != undefined) {
      cmts = this.majorPartEditComments.replaceAll("'", "''");
    } else {
      cmts = this.majorPartEditComments
    }
    this.errorMessage = "";
    let body = {
      'rmrk_txt': cmts,
      'maj_part_lst_id': this.maj_part_lst_id,
      "screenNo":"NBPC_2220",
      "fileName" :fileName
      
    }
    this.SpinnerService.show();
    this.majPartLstEditService.updateMajPartLst(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.updateMajFlg();
      } else if (this.response[0].result == 'AlreadyExists') {
        this.errorMessage = 'Data Entered already Exists!';
      }
    }, err => {
      this.SpinnerService.hide();
    });

  }

  updateMajFlg() {
    let fileName= localStorage.getItem('Filename')
    let user = localStorage.getItem('workDayId');
    let body = {
      'inactv_flg': this.majorPartEditActive == false ? 'Y' : 'N',
      'maj_part_lst_id': this.maj_part_lst_id,
      "screenNo":"NBPC_2220",
      "fileName" :fileName,
      
    }
    this.majPartLstEditService.updateMajPartFlgLst(body).subscribe(data => {
      console.log(data);
      this.response = data.payload;
      if (this.response[0].result == 'success') {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result)
      } else if (this.response[0].result == 'AlreadyExists') {
        this.SpinnerService.hide();
        this.errorMessage = 'Data Entered already Exists!';
      }
    }, err => {
      this.SpinnerService.hide();
    });


  }

  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  closeAlert() {
    this.errorMessage = '';
  }


}
