import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from './global.service';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import * as S3 from 'aws-sdk/clients/s3';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  public now: Date = new Date();
  title = 'Tema-eBoM';
  spinnerMessage = "Please Wait...";
  currentUrl: any;
  user_name: any;
  last_name: any;
  roleeligible: any;
  roleshown;
  rolecheckdet: any
  manJobShow;
  manJobcheck:any
  masterMap: any = new Map([
    ["repVehicles", "Maintain Representative Vehicles"],
    ["repUnits", "Maintain Representative Units"],
    ["sapDataLd", "Maintain Representative Vehicles/Units "],
    ["mngDataTrnsfrSap", "Manage Data Transfer to SAP"],
    ["versionTypes", "Maintain Version Types"],
    ["verQualifiers", "Maintain Version Qualifiers"],
    ["majorPartLst", "Maintain Major Part List"],
    ["majorPartTyp", "Maintain Major Part Type"],
    ["partSource", "View Part Source"],
    ["partSourceRtg", "Maintain Part Source Routing"],
    ["exchangeRate", "Maintain Exchange Rate "],
    ["slctvyPartRule", "Maintain Selectivity Part Rule "],
    ["100perSpec", "Maintain 100 % Specifications  "],
    ["famProNamc", "Maintain Family, Project and NAMC  "],
    ["extPartLst", "Maintain Extraneous Parts List  "],
    ["pcSpecExclLst", "Maintain PC Specs Exclusion List "],
    ["family", "Family "],
    ["hikiateStatus", "Hikiate Status"],
    ["plntUsrCd", "Maintain Plant User Code"],
    ["isSystemParm", "IS System Parm"],
    ["procStatus", "Procedure Status"],

  ]);
  businessMap: any = new Map([
    ["versions", "Maintain Versions"],
    ["prodWithinVersion", "Maintain Product within Version"],
    ["prodProcStatus", "Product Processing Status"],
    ["manAdjRule", "Maintain Manual Adjustment Rule"],
    ["partSrcAdj", "Part Source Adjustment"],
  ]);
  screenName: string = "";
  hidden: boolean;
  show: boolean;
  invalidUser = false;
  loginUser: boolean
  currentUser: any;
  workDayId: any;
  emailId: any;
  role: any;
  roleList: any;
  EbomaccessDetailResponse: any;
  codeResponse = '';
  id_token: any = '';
  access_token: any = '';
  refresh_token: any = '';
  currentUserRole: any;
  // idle variables
  idleState = `Not Started`;
  idleStates: any
  timedOut = false;
  lastPinged: Date = null;
  idleStart = false;
  accessKey: string;
  secretKey: string;
  sessionToken: string;
  token_to_s3: { access_token: any; id_token: any; };
  filename: string;
  tokenstrs: string;

  constructor(public router: Router, private readonly SpinnerService: NgxSpinnerService,
    public globalService: GlobalService, public globalrole: GlobalService, private readonly idle: Idle, private readonly keepalive: Keepalive,) {

    router.events.subscribe(async event => {

      if (event instanceof NavigationStart) {
        this.reset(1);
        console.log('(window.location.href', window.location.href)
        if (window.location.href.indexOf('code') != -1) {
          const url = window.location.href.split('?')[1]
          if (url.indexOf('error') != -1) {
            this.invalidUser = true
            this.loginUser = false
            console.log("inside second if confn", this.codeResponse);
            window.location.hash = '#/unauthorized'
            this.router.navigateByUrl('/unauthorized')
          } else {
            console.log('else if auth')
            this.codeResponse = window.location.href.split('?')[1].split('code=')[1].split('&')[0];
            localStorage.setItem('codeResponse', this.codeResponse)
            var details = {
              'grant_type': environment.grantType,
              'client_id': environment.clientId,
              'code': this.codeResponse,
              'redirect_uri': environment.redirectURI,
              'code_verifier': environment.codeVerifier
            };
            console.log("bodydet", details)
            var formBody = [];
            for (var property in details) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
            }
            let body = formBody.join("&");
            console.log("bodycheck", body)
            let response = await this.globalService.getTokens(body).then((res: any) => {
              if (res != null) {
                this.id_token = res.id_token;
                this.access_token = res.access_token;
                this.refresh_token = res.refresh_token;
                localStorage.setItem('Refresh_token', this.refresh_token)
                setInterval(() => {
                  this.refreshToken();
                }, 3300000);
                if (this.id_token != null || this.access_token != null) {
                  localStorage.setItem('Ebom_loginSuccess', 'true');
                  localStorage.setItem('login_time', '1');
                  this.loginUser = true;
                  console.log('Logged In Successfully and Current User details not in storage');
                  var decodedToken = jwt_decode(this.access_token);
                  localStorage.setItem('Ebom_accessToken', this.access_token);
                  let newDate = new Date();
                  let date = '_' + newDate.getDate() +'-'+ (newDate.getMonth() + 1) +'-'+ newDate.getFullYear() + '_' + newDate.getHours() +':'+ newDate.getMinutes() +':'+ newDate.getSeconds() +':'+ newDate.getMilliseconds();
                  this.filename="access_token"+date;
                  console.log(this.filename,"filenamechange");                  
                  localStorage.setItem("Filename",this.filename)
                  this.token_to_s3={"access_token":this.access_token,"id_token":this.id_token}
                  this.tokenstrs="{access_token : "
                  this.tokenstrs+=this.access_token+","+"id_token : "+this.id_token+"}"
                  this.creatingFile(JSON.stringify(this.token_to_s3),this.filename+".json","access-auth")
                  localStorage.setItem('Ebom_currentUser', JSON.stringify(decodedToken));
                  var decodedIdToken = jwt_decode(this.id_token);
                  localStorage.setItem('Ebom_currentUserrole', JSON.stringify(decodedIdToken));
                  this.currentUser = JSON.parse(localStorage.getItem('Ebom_currentUser'));
                  console.log(this.currentUser);
                  this.currentUserRole = JSON.parse(localStorage.getItem('Ebom_currentUserrole'));
                  console.log(this.currentUserRole);
                  this.workDayId = this.currentUserRole['extensionAttribute3']
                  this.emailId = this.currentUser['upn'];
                  this.role = this.currentUserRole['roles']
                  console.log(this.role)
                  this.roleeligible = this.role.filter((res) => {
                    console.log(res)
                    if (res == 'EBOM_ISSUPPORT') {
                      this.rolecheckdet = true;
                    }
                    if(res == 'EBOM_ISSUPPORT'||res == 'EBOM_POWER'){
                      this.manJobcheck=true
                    }
                  })
                  localStorage.setItem('roleshown', this.rolecheckdet)
                  localStorage.setItem('manJobShow', this.manJobcheck)
                  this.user_name = this.currentUser['given_name']
                  this.last_name = this.currentUser['family_name']
                  console.log("this.emailId in constructor", this.emailId);
                  localStorage.setItem('emailId', 'shanmugam.ramasamy@toyota.com')
                  localStorage.setItem('workDayId', this.workDayId)
                  localStorage.setItem('role', this.role)
                  localStorage.setItem('username', this.user_name)
                  localStorage.setItem('lastname', this.last_name)                  
                  this.pathname();
                }
              }
            }, err => {
              console.log(err)
             
              setTimeout(() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = 'https://login.microsoftonline.com/common/oauth2/logout';
              }, 4000)
            })
           
          }
        }
      }
    });
    this.idle.setIdle(1800);
    this.idle.setTimeout(60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => {
      this.reset(2);
    });
    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.idleState = `Timed Out`;
      sessionStorage.clear();
      localStorage.clear();
      setTimeout(() => {
        window.location.href = 'https://login.microsoftonline.com/common/oauth2/logout';
      }, 500)
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = `You have gone idle`;
      $('#idleModal').modal('show',{backdrop: 'static', keyboard: false});
      this.idleStart = true;
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleStates = `You are idle. Your session will get logged off in ${countdown} seconds . Please click 'OK' to continue the session `
    })
    this.keepalive.interval(5);
    //
    setInterval(() => {
      this.now = new Date();
    }, 1);
    this.user_name = localStorage.getItem("username");
    this.last_name = localStorage.getItem("lastname")
    console.log(this.user_name)
    this.pathname();
    this.pathchanges();
  }
  ngOnInit(): void {
    console.log("sprrner");    
   
  }
  masterScreens(data) {
    this.hidden = false;
    this.screenName = this.masterMap.get(data);
    if (data === "verQualifiers" || data === "versionTypes" ||
      data === "majorPartLst" || data === "majorPartTyp" ||
      data == "partSource" || data == "partSourceRtg") {
      this.hidden = true;
    }
    else {
      this.hidden = false;
    }
  }
  businessScreens(data) {
    this.screenName = this.businessMap.get(data);
    if (data == "prodWithinVersion" || data == "prodProcStatus" ||
      data === "glReconPeriod" || data === "glReconPeriodProcStat" || data == "versions") {
      this.hidden = true;
      console.log(data, this.hidden)
    }
    else {
      this.hidden = false;
    }
  }
  exceptionScreen(data) {
    this.screenName = "BoM Structure Details Processing"
  }
  manual(data){
    this.screenName='Manual Job Trigger'
  }
  openRelatedLink() {
    window.open("https://mfgbi.dev.toyota.com/analytics/saw.dll?BIEEHome", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }
  ebomIsDashboard() {
    this.screenName = "";
  }
  reload(data) {
    console.log("reload", data)
    this.currentUrl = this.router.url;
    console.log(data, this.currentUrl)
    if (data == this.currentUrl) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([data]);
      });
    } else {
      this.router.navigate([data]);
    }
  }
  userpreviliage(roleName) {
    let arrayvalue: any;
    arrayvalue = roleName;
    console.log(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str == '') {
        str = "'" + arrayvalue[i] + "'"
      } else {
        str += ',' + "'" + arrayvalue[i] + "'";
      }
      this.roleList = str;
    }
    let body = {
      security_grp_nm: this.roleList
    }
    this.globalService.userprevilageapi(body).subscribe(data => {
      console.log(data);
      this.globalrole.changeMessage(data.payload)
      let userpreviliagedata=JSON.stringify(data.payload)
      localStorage.setItem("userpreviliagedata",userpreviliagedata)
      this.SpinnerService.hide();
    },err=>{
      console.log(err);
      this.SpinnerService.hide();
    });
    this.pathnamecheck()
  }
  pathnamecheck() {
    this.roleshown = localStorage.getItem('roleshown');
    console.log("this.roleshown", this.roleshown)
    this.manJobShow = localStorage.getItem('manJobShow');
    console.log("this.manJobShow", this.manJobShow)
  }
  pathname() {

    this.router.events.subscribe((event) => {



      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        console.log(event.url, this.currentUrl)
        if(this.currentUrl == '/home'){
            this.screenName = ''
        }
        if (this.currentUrl == '/') {  
          this.show = true;
          this.screenName = ''
          console.log(this.show);
        }
        else {
          this.show = true;
          console.log(this.show);
          this.user_name = localStorage.getItem("username");
          this.last_name = localStorage.getItem("lastname");
          console.log(this.user_name)
        }
        let screenurl = this.currentUrl.split('/')[1]
        
        
        if ((screenurl == 'versions') || (screenurl == 'prodWithinVersion')
        || (screenurl == 'prodProcStatus') || (screenurl == 'manAdjRule')
        || (screenurl == 'partSrcAdj') ) {
           this.businessScreens(screenurl);
        }
        else if(screenurl == 'qualityExceptRpt'){
          this.exceptionScreen(screenurl)
        }
        else if(screenurl == 'manJobTrigger'){
          this.manual(screenurl)
        }
        else {
          this.masterScreens(screenurl);
        }

        console.log(screenurl);
        this.roleshown = localStorage.getItem('roleshown');
        console.log("this.roleshown", this.roleshown)
        this.manJobShow = localStorage.getItem('manJobShow');
        console.log("this.manJobShow", this.manJobShow)
      }

    });

  }

  pathchanges() {
    if (this.user_name == null) {
      this.router.navigateByUrl('/home');
    } else {
      console.log("success");
    }
  }

  async logout() {
    let fileName= await localStorage.getItem('Filename')
    fileName=fileName+".json"
    console.log(fileName);
    
    let a = await this.getS3Info();
    if (a) {
      const s3 = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
    
      await s3.deleteObject({
      Bucket: "access-auth",
      Key: fileName,
    }, (err, data) => {
      if (err) {
        console.log('Not deleted package.' + err);
      } else {
        console.log('Successfully deleted package in ' + fileName);
      }
    })
  }
    localStorage.clear();
    window.location.href = 'https://login.microsoftonline.com/common/oauth2/logout';
  }

  async refreshToken() {
    console.log('inside refresh token')
    let data = {
      'grant_type': "refresh_token",
      'client_id': environment.clientId,
      'refresh_token': localStorage.getItem('Refresh_token')
    };
    let refreshBody = [];
    for (let property in data) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(data[property]);
      refreshBody.push(encodedKey + "=" + encodedValue);
    }
    let body = refreshBody.join("&");
    console.log("body in refersh time", body);
    let response = await this.globalService.getTokens(body).then((res: any) => {
      console.log("response in refresh token", res);
      if (res != null) {
        this.id_token = res.id_token
        this.access_token = res.access_token
        this.refresh_token = res.refresh_token
        localStorage.setItem('token', this.id_token)
        localStorage.setItem('Refresh_token', this.refresh_token)
      }
    })
  }

  reset(dataa) {
    console.log(dataa)
    this.idle.watch();
    this.idleState = 'Started';
    this.timedOut = false;
    this.idleStart = false;

  }
  submit() {
    $('#idleModal').modal('hide');
  }
  async creatingFile(body, fileName, bucket) {
    this.SpinnerService.show()
    let a = await this.getS3Info();
    if (a) {
      const s3 = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
    
      await s3.upload({
      Bucket: bucket,
      Key: fileName,
      Body: body
    }, (err, data) => {
      if (err) {
        this.SpinnerService.hide();
        console.log('Not uploaded package.' + err);
      } else {
        console.log('Successfully uploaded package in ' + fileName);
        //this.UsersLoginDetails()
         this.userpreviliage(this.role);
      }
    })
  }
 
  }
  
  async getFilefFromS3() {
    let fileName=localStorage.getItem('Filename')
    console.log("nandhu file name",fileName);
    

    let a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'access-auth',
        Key: fileName,
      };
      console.log(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.log('download err:::::::::', err)
        } else {
          console.log("nandhu test",data.Body.toString())
          let token=data.Body.toString()
          console.log(token);
          
        }
      })
    }

  }
  getS3Info() {
    return new Promise(resolve => {
      this.globalService.tokenUplddata().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {

          resolve(false)
        }
      }, err => {
        this.SpinnerService.hide();
        
      });
    })
  }
  async UsersLoginDetails(){
    this.globalService.UsersLoginDetails().subscribe(data=>{
      if(data){
        console.log(data);        
        this.userpreviliage(this.role);
      }
    })
  }
}
