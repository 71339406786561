<span *ngIf="accessEnable">
  <div class="alert alert-danger alert-dismissible " *ngIf="errorMessage!=''" role="alert">
    {{errorMessage}}<mat-icon class="close-icon" (click)="closeAlert()">close</mat-icon>
  </div>
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Please select the fields to generate the reports.</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select [typeaheadDebounceInterval]= "500" [(ngModel)]="status" name="status">
        <mat-option value="">ALL</mat-option>
        <mat-option value="Close">Closed</mat-option>
        <mat-option value="Ignore">Ignored</mat-option>
        <mat-option value="Open">Open</mat-option>
      </mat-select>
    </mat-form-field>

      <mat-form-field>
        <mat-label>Source System</mat-label>
        <mat-select [typeaheadDebounceInterval]="500" [(ngModel)]="sourceSystem" name="sourceSystem">
          <mat-option value="">ALL</mat-option>
          <mat-option *ngFor="let srcsys of srcsyslst"
            [value]="srcsys.inp_parm_val">{{srcsys.inp_parm_val}}</mat-option>
        </mat-select>
      </mat-form-field>


    <mat-form-field>
      <mat-label>Input File Name</mat-label>
      <input matInput placeholder="" [(ngModel)]="inputFileName">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Sequence Number</mat-label>
      <input matInput placeholder="" [(ngModel)]="sequenceNumber">
    </mat-form-field>


    <mat-form-field>
      <mat-label>Reasons</mat-label>
      <input matInput placeholder="" [(ngModel)]="reasons">
    </mat-form-field>


    <mat-form-field class="picker">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="inPicker" [(ngModel)]="startDate" >
      <mat-datepicker-toggle matSuffix [for]="inPicker"></mat-datepicker-toggle>
      <mat-datepicker #inPicker ></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'startDate')">clear</mat-icon>
    </mat-form-field>

    <mat-form-field class="picker">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="outPicker" [(ngModel)]="endDate" >
      <mat-datepicker-toggle matSuffix [for]="outPicker"></mat-datepicker-toggle>
      <mat-datepicker #outPicker ></mat-datepicker>
      <mat-icon matDatepickerToggleIcon class="dateclear" (click)="clearDate($event,'endDate')">clear</mat-icon>
    </mat-form-field>
    <button mat-button style="float: right;" (click)="generateReport()">Generate Report</button>

  </mat-expansion-panel>
</mat-accordion>
<br>

<mat-panel-title *ngIf="title">
  <strong>Source System:ALL</strong>
</mat-panel-title>

<div class="d-flex" *ngIf="showButtons">
  <button mat-raised-button (click)="openStatus('Open')">Open </button>
  <button mat-raised-button (click)="openStatus('Close')">Close </button>
  <button mat-raised-button (click)="openStatus('Ignore')">Ignore</button>
  <span class="ms-auto">
    <button mat-raised-button (click)="exportFile()">Export to Excel </button>
  </span>
</div>


  <!-- <mat-card> -->
  <ngx-datatable #myTable *ngIf="showGrid" class="material striped" [rows]="rows" [columnMode]="ColumnMode.force"
    [headerHeight]="45" [footerHeight]="30" rowHeight="30" [limit]="limitSelected" [selected]="selected"
    [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false" [displayCheck]="displayCheck"
    (activate)="onActivate($event)" (select)="onSelect($event)" [scrollbarH]="true"
    [ngClass]="{'hideerror': showdata, 'showerror': !showdata }">
    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
        let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
        <div class="page-count">
          {{selectedCount}} selected / {{rowCount}} total
        </div>
        <datatable-pager class="text-end" [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
          (change)="table.onFooterPage($event)">
        </datatable-pager>
        <div class="test">

        <select [(ngModel)]="limitSelected">
          <option>50</option>
          <option>100</option>
          <option>500</option>
        </select>
        <div class="float-right records">View {{(offset * pageSize)+1}} - {{(rowCount>
          (curPage*pageSize))?(curPage*pageSize):(rowCount)}} of
          {{(rowCount)}}

          </div>
        </div>
      </ng-template>
    </ngx-datatable-footer>
    <ngx-datatable-column [width]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">

  </ngx-datatable-column>
  <ngx-datatable-column name="Status" prop="err_stat_cd"></ngx-datatable-column>
  <ngx-datatable-column name="Source System" prop="data_src_nm"></ngx-datatable-column>
  <ngx-datatable-column name="Standard Name for the Input File" prop="std_name"></ngx-datatable-column>
  <ngx-datatable-column name="Sequence Number" prop="seq_nbr"></ngx-datatable-column>
  <ngx-datatable-column name="File Record Count" prop="file_cnt"></ngx-datatable-column>
  <ngx-datatable-column name="Source Count" prop="src_cnt"></ngx-datatable-column>
  <ngx-datatable-column name="Stage Count" prop="stg_cnt"></ngx-datatable-column>
  <ngx-datatable-column name="eBoM Count" prop="ebom_cnt"></ngx-datatable-column>
  <ngx-datatable-column name="(%) Suspended Records " prop="suspended_rcrd"></ngx-datatable-column>
  <ngx-datatable-column name="Succesfully Processed Record Count" prop="success_cnt" [width]="200">
  </ngx-datatable-column>
  <ngx-datatable-column name="Error Date" prop="excp_dt"></ngx-datatable-column>
  <ngx-datatable-column name="Reasons" prop="reasons"></ngx-datatable-column>

  </ngx-datatable>
  </span>
  <div *ngIf="!accessEnable">
    <p class="text-center" style="margin: 25px;color: red;">{{errorMessage}}</p>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">

  <p style="color: white">{{spinnerMessage}} </p>

</ngx-spinner>